import * as fromStatistics from './statistics.reducer';
import { ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { environment } from '../../../../environments/environment';

export const featureKey = 'statistics';

export interface StatisticState {
  Statistics: fromStatistics.StatisticsState;
}

export const reducers: ActionReducerMap<StatisticState> = {
  Statistics: fromStatistics.StatisticsReducer,
};

export const getLandingState = createFeatureSelector<StatisticState>(featureKey);

export const metaReducers: MetaReducer<StatisticState>[] = !environment.production ? [] : [];
