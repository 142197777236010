import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { FromDictionaryPipe, LanguageService } from '@teamfoster/sdk/dictionary-ngrx';
import { DynamicFormField } from '@teamfoster/sdk/dynamic-form';
import { ContentBlock } from '../../models';
import { CookiePreferences } from '@teamfoster/sdk/cookie-ngrx';
import { Router } from '@angular/router';

@Component({
  selector: 'app-content-block-register',
  templateUrl: './content-block-register.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockRegisterComponent {
  @Input({ required: true }) block!: ContentBlock;
  @Input() cookiePreferences!: CookiePreferences;
  @Input() index!: number;

  lang = inject(LanguageService);
  router = inject(Router);
  private dict = new FromDictionaryPipe(this.lang);

  prefix = 'registreren';
  formFields: DynamicFormField[] = [
    {
      inputType: 'text',
      label: this.dict.transform(`${this.prefix}-voornaam-label`),
      name: 'firstName',
      validators: [Validators.required],
      autocomplete: 'given-name',
      placeholder: this.dict.transform(`${this.prefix}-voornaam-placeholder`, false),
      fieldType: 'input',
      order: 1,
      cssClass: 'col-12 col-md-6',
    },
    {
      inputType: 'text',
      label: this.dict.transform(`${this.prefix}-achternaam-label`),
      name: 'lastName',
      validators: [Validators.required],
      autocomplete: 'family-name',
      placeholder: this.dict.transform(`${this.prefix}-achternaam-placeholder`, false),
      fieldType: 'input',
      order: 1,
      cssClass: 'col-12 col-md-6',
    },
    {
      inputType: 'email',
      label: this.dict.transform(`${this.prefix}-email-label`),
      name: 'email',
      validators: [Validators.email, Validators.required],
      autocomplete: 'email',
      placeholder: this.dict.transform(`${this.prefix}-email-placeholder`, false),
      fieldType: 'input',
      order: 0,
    },
  ];

  handleSubmit(e: any) {
    this.router.navigate(['/account/registreren'], { queryParams: e });
  }
}
