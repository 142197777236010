<div class="app">
  <div class="app__inner" [class.anim--in]="loading$()">
    <ng-container *ngIf="edition$() as edition">
      <app-topbar
        (toggleOverlay)="toggleMenu()"
        (closeOverlay)="closeMenu()"
        (openOverlay)="openMenu()"
        [menuActive]="menuOpen$()"
        [topMenu]="('topbar-top-nav' | fromMenu)?.error ? [] : ('topbar-top-nav' | fromMenu)"
        [edition]="edition"
        [isSignedIn]="isSignedIn$()"
        [menu]="getMenu$() | fromMenu"
        [usr]="usr$()"
        *ngIf="loaded$()"
      ></app-topbar>
    </ng-container>

    <div class="app__router" [@routerFade]="prepareRoute(outlet)">
      <router-outlet #outlet="outlet"></router-outlet>
    </div>
  </div>
  <app-footer
    [domain]="domainName"
    [contacts]="contacts$()"
    [menu]="('footer-menu' | fromMenu)?.error ? [] : ('footer-menu' | fromMenu)"
    *ngIf="loaded$()"
  ></app-footer>
  <app-loader-indicator [loading]="loading$()"></app-loader-indicator>
  <fstr-cookie-notice
    [title]="'cookie-titel' | fromDictionary"
    *ngIf="loaded$()"
    [text]="'cookie-tekst' | fromDictionary"
    [buttonAccept]="'cookie-accepteer-knop-tekst' | fromDictionary"
    [buttonReject]="'cookie-afwijzen-knop-tekst' | fromDictionary"
  ></fstr-cookie-notice>
  <app-loader-indicator [loading]="loading$()"></app-loader-indicator>
</div>

<app-grid-overlay *ngIf="testGridEnabled"></app-grid-overlay>
