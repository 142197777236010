import { createReducer, on } from '@ngrx/store';
import { Assignment } from '../../models';
import * as pageActions from '../actions/assignments.action';
import { ToEntities } from '@teamfoster/sdk/array-utility';

export interface AssignmentsState {
  entities: { [id: string]: Assignment };
  loaded: number[];
  loading: number[];
  loadedCurrent: number;
  loadingCurrent: boolean;
  overviewLoaded: boolean;
  overviewLoading: boolean;
  error?: any;
}

export const initialState: AssignmentsState = {
  entities: {},
  loaded: [],
  loading: [],
  loadedCurrent: 0,
  loadingCurrent: false,
  overviewLoading: false,
  overviewLoaded: false,
};

export const AssignmentsReducer = createReducer(
  initialState,
  on(pageActions.LoadAssignments, (state: AssignmentsState) => {
    return {
      ...state,
      overviewLoading: true,
    };
  }),
  on(pageActions.LoadAssignmentsSuccess, (state: AssignmentsState, { assignments }) => {
    const entities = ToEntities(assignments, 'id', state.entities);
    return {
      ...state,
      entities,
      overviewLoading: false,
      overviewLoaded: true,
    };
  }),
  on(pageActions.LoadAssignmentsFail, (state: AssignmentsState, { error }) => {
    return {
      ...state,
      overviewLoading: false,
      overviewLoaded: false,
      error,
    };
  }),
  on(pageActions.LoadCurrentAssignment, (state: AssignmentsState) => {
    return {
      ...state,
      loadingCurrent: true,
    };
  }),
  on(pageActions.LoadCurrentAssignmentSuccess, (state: AssignmentsState, { assignment }) => {
    const entities = { ...state.entities, [assignment.id]: assignment };
    return {
      ...state,
      entities,
      loadingCurrent: false,
      loadedCurrent: assignment.id,
      loading: state.loading.filter(id => id !== id),
      loaded: [...state.loaded, assignment.id],
    };
  }),
  on(pageActions.LoadAssignmentById, (state: AssignmentsState, { id }) => {
    return {
      ...state,
      loading: [...state.loading, id],
    };
  }),
  on(pageActions.LoadAssignmentByIdSuccess, (state: AssignmentsState, { assignment }) => {
    const entities = { ...state.entities, [assignment.id]: assignment };
    return {
      ...state,
      entities,
      loading: state.loading.filter(id => id !== assignment.id),
      loaded: [...state.loaded, assignment.id],
    };
  }),
  on(pageActions.LoadAssignmentByIdFail, (state: AssignmentsState, { error, id }) => {
    return {
      ...state,
      loading: state.loading.filter(id => id !== id),
      loaded: state.loaded.filter(id => id !== id),
      error,
    };
  }),
  on(pageActions.ClearAssignments, (state: AssignmentsState) => {
    return {
      ...initialState,
    };
  })
);

export const getAssignmentsEntities = (state: AssignmentsState) => state.entities;

export const getAssignmentsLoading = (state: AssignmentsState) => state.loading;
export const getAssignmentsLoaded = (state: AssignmentsState) => state.loaded;

export const getAssignmentsOverviewLoading = (state: AssignmentsState) => state.overviewLoading;
export const getAssignmentsOverviewLoaded = (state: AssignmentsState) => state.overviewLoaded;

export const getCurrentAssignmentLoaded = (state: AssignmentsState) => state.loadedCurrent;
export const getCurrentAssignmentLoading = (state: AssignmentsState) => state.loadingCurrent;
// export const getCurrentAssignmentId = (state: AssignmentsState) => state.currentId;
