import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromPosts from '../reducers/posts.reducer';

export const getPostsState = createSelector(fromFeature.getLandingState, (state: fromFeature.AssignmentState) => state.posts);

export const getPostsEntities = createSelector(getPostsState, fromPosts.getPostsAssignmentsEntities);

export const getPostsPage = createSelector(getPostsState, fromPosts.getPostsAssignmentsPage);

export const getAllPosts = createSelector(getPostsEntities, entities => {
  return Object.keys(entities).map(slug => entities[slug]);
});

export const getOrderedPosts = createSelector(getAllPosts, assignments => {
  return assignments.sort((a, b) => (new Date(b.postedOn) >= new Date(a.postedOn) ? 1 : -1));
});

export const getPostsLoaded = createSelector(getPostsState, fromPosts.getPostsAssignmentsLoaded);
export const getPostsLoading = createSelector(getPostsState, fromPosts.getPostsAssignmentsLoading);

export const getPostsSaved = createSelector(getPostsState, fromPosts.getPostsAssignmentsSaved);
export const getPostsSaving = createSelector(getPostsState, fromPosts.getPostsAssignmentsSaving);
