import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AssignmentPost, AssignmentPostComment } from 'src/app/assignments/models';
import { loremposts } from '../loremposts';
import { ContentBlock } from '../../models';

@Component({
  selector: 'app-content-block-image-grid',
  templateUrl: './content-block-image-grid.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockImageGridComponent {
  @Input() block!: ContentBlock;

  posts: AssignmentPost[] = [...loremposts];
}
