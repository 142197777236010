<article class="section-outset-y">
  <div class="container">
    <div class="row rounded-4 bg-primary-100 section-inset-y px-md-5 align-items-center">
      <header class="col-md-5 mb-4 mb-md-0">
        <h2 class="h2 text-primary title-squiggle">
          <fstr-icon anchor="icon-squiggle"></fstr-icon>
          {{ block.title }}
        </h2>
      </header>
      <main class="col-md-6 offset-md-1">
        <h3 class="h5 text-primary" *ngIf="block.subtitle">{{ block.subtitle }}</h3>
        <div
          class="markdown-content mb-3 mb-md-4"
          *ngIf="block.text"
          [innerHTML]="block.text || '' | resizeHtmlImages | stripEmbed : cookiePreferences | safe"
        ></div>

        <fstr-dynamic-form [config]="formFields" [appearance]="'fill'" (formSubmit)="handleSubmit($event)" [showSubmit]="false" #form>
          <div slot="form-actions">
            <button type="submit" class="button button--primary button--md">
              {{ prefix + '-knoptekst' | fromDictionary }}
            </button>
          </div>
        </fstr-dynamic-form>
      </main>
    </div>
  </div>
</article>
