import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InViewModule } from '@teamfoster/sdk/in-view';
import { IconModule } from '@teamfoster/sdk/icon';
import { Image, ImageModule } from '@teamfoster/sdk/image';
import { TextUtilityModule } from '@teamfoster/sdk/text-utility';
import { Button, ButtonModule } from '@teamfoster/sdk/button';
import { YoutubePlayerModule } from '@teamfoster/sdk/youtube-player';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';

@Component({
  selector: 'app-page-header',
  standalone: true,
  imports: [CommonModule, ImageModule, IconModule, TextUtilityModule, ButtonModule, YoutubePlayerModule, DictionaryNgrxModule],
  templateUrl: './page-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageHeaderComponent {
  @Input() title: string = '';
  @Input() intro!: string;
  @Input() summary!: string;
  @Input() image!: Image;
  @Input() gallery!: Image[];
  @Input() video!: string; // yt embed url
  @Input() subtitle!: string | null;
  @Input() button!: Button;

  mockGallery = [0, 1, 2];

  get lengthGallery() {
    if (this.video) {
      return 3;
    }
    return 4;
  }
}
