import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Assignment } from '../models';

@Injectable({
  providedIn: 'root',
})
export class AssignmentsService {
  constructor(
    private http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string
  ) {}

  getAssignments() {
    return this.http.get<Assignment[]>(`${this.BASE_URL}api/Assignments`);
  }

  getCurrentAssignment() {
    return this.http.get<Assignment>(`${this.BASE_URL}api/Assignments/current`);
  }

  getAssignmentById(id: number) {
    return this.http.get<Assignment>(`${this.BASE_URL}api/Assignments/${id}`);
  }
}
