import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromComments from '../reducers/comments.reducer';

export const getCommentsState = createSelector(fromFeature.getLandingState, (state: fromFeature.AssignmentState) => state.comments);

export const getCommentsEntities = createSelector(getCommentsState, fromComments.getCommentsPostEntities);

// export const getCommentsPage = createSelector(getCommentsState, fromComments.getCommentsPostPage);

export const getAllComments = createSelector(getCommentsEntities, entities => {
  return Object.keys(entities).map(slug => entities[slug]);
});

export const getOrderedComments = createSelector(getAllComments, comments => {
  return comments.sort((a, b) => (new Date(b.postedOn) >= new Date(a.postedOn) ? 1 : -1));
});

// export const getCommentsByPostId = createSelector(getOrderedComments, postId (comments) => {
//   return comments?.filter(a => a.id === postId);
// });

export const selectCommentsByPostId = (postId: number) =>
  createSelector(getOrderedComments, comments => comments.filter(comment => comment.postId === postId));

export const getCommentsLoaded = createSelector(getCommentsState, fromComments.getCommentsPostLoaded);
export const getCommentsLoading = createSelector(getCommentsState, fromComments.getCommentsPostLoading);

export const GetCommentsLoadedFor = createSelector(getCommentsState, fromComments.getCommentsLoadedFor);
export const GetCommentsLoadingFor = createSelector(getCommentsState, fromComments.getCommentsLoadingFor);

export const selectCommentsPagingByPostId = (postId: number) =>
  createSelector(GetCommentsLoadedFor, pages => {
    const foundPage = pages?.find(p => p.id === postId);
    return foundPage ? foundPage.page : null;
  });

export const getCommentsSaved = createSelector(getCommentsState, fromComments.getCommentsPostSaved);
export const getCommentsSaving = createSelector(getCommentsState, fromComments.getCommentsPostSaving);
