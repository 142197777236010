import { createAction, props } from '@ngrx/store';
import { CreateStatisticPostData, Statistic } from '../../models';

export const LoadStatistics = createAction('[Statistics] Load Statistics');
export const LoadStatisticsSuccess = createAction('[Statistics] Load Statistics Success', props<{ Statistics: Statistic[] }>());
export const LoadStatisticsFail = createAction('[Statistics] Load Statistics Fail', props<{ error: any }>());

export const CreateStatistic = createAction('[Create Statistic] Create Statistic', props<{ statistic: CreateStatisticPostData }>());
export const CreateStatisticSuccess = createAction('[Statistic] Create Statistic Success', props<{ statistic: Statistic }>());
export const CreateStatisticFail = createAction('[Statistic] Create Statistic Fail', props<{ error: any }>());

export const ClearStatistics = createAction('[Statistics] Clear Statistics');
