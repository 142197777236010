import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Input,
  PLATFORM_ID,
  ViewChild,
  inject,
} from '@angular/core';
import { ListItem } from '../../models/ListItem.model';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-content-block-list-item',
  templateUrl: './content-block-list-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockListItemComponent implements AfterViewInit {
  private platformId = inject(PLATFORM_ID);

  @Input() listItem!: ListItem;
  @Input() index!: number;
  @ViewChild('componentRef', { static: true }) componentRef!: ElementRef;

  inview = false;
  componentVisible = false;
  percentageInView: number = 0;

  setInView($e: boolean) {
    this.componentVisible = $e;
  }

  @HostListener('window:scroll', [])
  onScroll() {
    if (!this.componentVisible) {
      return;
    }
    this.calculatePercentageInView();
  }

  ngAfterViewInit(): void {
    this.calculatePercentageInView();
  }

  calculatePercentageInView() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    const component = this.componentRef.nativeElement;
    const rect = component.getBoundingClientRect();
    const viewportHeight = window.innerHeight;

    // Check if the component's top is visible in the viewport
    if (rect.top >= 0 && rect.top <= viewportHeight) {
      const componentHeightInView = Math.min(rect.bottom, viewportHeight / 1.3) - rect.top;
      const componentHeight = rect.height;
      const compFullHeightInView = Math.min(rect.bottom, viewportHeight) - rect.top;
      const percFullView = Math.round((compFullHeightInView / componentHeight) * 100);

      this.percentageInView = Math.round((componentHeightInView / componentHeight) * 100);

      if (this.percentageInView > 10) {
        this.inview = true;
      } else {
        this.inview = false;
      }
    }
  }
}
