<header class="page-header pt-5 pb-7" [class.bg-primary-100]="gallery">
  <div class="container">
    <div class="row align-items-start">
      <div
        class="col-lg-6 pe-md-4 page-header__left mb-4 mb-md-0"
        [ngClass]="(gallery && gallery.length) || image?.url ? 'col-lg-5' : 'col-lg-12 text-center d-flex align-items-center flex-column'"
      >
        <h6
          class="mb-3 text-tertiary page-header__subtitle d-flex flex-wrap align-items-center flex-gap-2"
          *ngIf="subtitle"
          [innerHTML]="subtitle"
        ></h6>
        <h1 class="h1 text-primary page-header__title">{{ title }}</h1>
        <div class="page-header__text lead text-primary" *ngIf="intro">{{ intro | stripHtml }}</div>
        <div class="lead text-tertiary mt-3" *ngIf="summary">
          <strong>{{ 'weekopdracht-resultaat-label-tekst' | fromDictionary }}</strong>
          <p [innerHTML]="summary" class="fw-normal"></p>
        </div>
        <ng-content></ng-content>
      </div>

      <aside class="col-lg-6" *ngIf="(gallery && gallery.length) || image?.url">
        <div class="page-header__gallery">
          <figure class="page-header__image mt-4 mt-lg-0" [class.set--gallery]="gallery">
            <fstr-image
              [sizes]="{ lg: 4, xl: 3 }"
              [imageSizes]="[600, 900, 1200]"
              [image]="{ url: image.url, alt: title }"
              [width]="610"
              [height]="520"
              *ngIf="!gallery"
            ></fstr-image>

            <ul class="page-header__gallery" *ngIf="gallery" [class.has--video]="video">
              <li class="page-header__gallery__item" *ngIf="video"><fstr-youtube-player [src]="video"></fstr-youtube-player></li>
              <li class="page-header__gallery__item" *ngFor="let image of gallery | slice : 0 : lengthGallery; let i = index">
                <fstr-image
                  [sizes]="{ lg: 4, xl: 3 }"
                  [imageSizes]="i === 0 ? [600, 900, 1200] : [400, 600, 800]"
                  [image]="{ url: image.url, alt: title }"
                  [width]="610"
                  [height]="520"
                ></fstr-image>
              </li>
              <ng-container *ngIf="gallery.length > 0 && gallery.length < lengthGallery">
                <li
                  class="page-header__gallery__item"
                  [class.has--bg-graphic]="!image"
                  *ngFor="let item of mockGallery | slice : 0 : lengthGallery - gallery.length"
                ></li>
              </ng-container>
            </ul>
          </figure>
        </div>
      </aside>
    </div>
  </div>
</header>
