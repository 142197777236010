<article class="post-card card" [class.pb-0]="showComments" [ngClass]="'type--' + (typePost.type | urlRewrite)">
  <header class="post-card__header mb-4 d-flex flex-gap-2 justify-content-between align-items-center">
    <div class="d-flex flex-gap-2 align-items-center">
      <app-avatar [size]="'sm'" [imageUrl]="post.author.avatar" [name]="post.author.fullName"></app-avatar>
      <div>
        <h2 class="mb-0 post-card__title fs-body-md">
          <span class="sr-only">Bericht van</span>
          {{ post.author.firstName }}
        </h2>
        <em class="text-muted fs-body-xs">{{ relativeDate }}</em>
      </div>
    </div>
    <div class="d-flex align-items-center flex-gap-2 post-card__type">
      <strong class="d-none d-md-block">{{ typePost.type }}</strong>
      <fstr-icon class="chip chip--sm" [anchor]="typePost.icon"></fstr-icon>
      <button (click)="this.remove.emit(post)" class="button button-link ms-2" *ngIf="usr?.id === post.author.id">
        <fstr-icon anchor="icon-trash"></fstr-icon>
      </button>
    </div>
  </header>
  <main>
    <figure class="post-card__figure mb-3" *ngIf="post.image?.url">
      <fstr-image [image]="post.image" [width]="500" [height]="300"></fstr-image>
    </figure>
    <div class="markdown-content fs-body-md" [innerHTML]="post.text"></div>
  </main>
  <footer class="mt-4 py-4 px-5 post-card__footer" *ngIf="showComments">
    <app-comments-list [post]="post"></app-comments-list>
  </footer>
</article>
