import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input, OnInit, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ButtonModule } from '@teamfoster/sdk/button';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { IconModule } from '@teamfoster/sdk/icon';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  standalone: true,
  imports: [CommonModule, IconModule, DictionaryNgrxModule, ButtonModule, MatDialogModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmComponent implements OnInit {
  private dialogRef = inject(MatDialogRef<ConfirmComponent>);
  private data = inject(MAT_DIALOG_DATA);

  @Input() message!: string;
  @Input() title!: string;
  @Input() cssClass!: string;

  constructor() {}

  ngOnInit(): void {
    this.message = this.data.message;
    this.title = this.data.title;
    this.cssClass = this.data.cssClass;

    this.dialogRef.addPanelClass('confirm-dialog');
  }

  confirmHandler(e: any) {
    if (e) {
      this.dialogRef.close(true);
    } else {
      this.dialogRef.close(false);
    }
  }
}
