import { Injectable, inject } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as actions from '../actions/assignments.action';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { AssignmentsService } from '../../services';
import { Go } from 'src/app/store';
import { ActivatedRoute, Router } from '@angular/router';

export const LoadAssignments$ = createEffect(
  (actions$ = inject(Actions), service = inject(AssignmentsService)) => {
    return actions$.pipe(
      ofType(actions.LoadAssignments),
      switchMap(() =>
        service.getAssignments().pipe(
          map(assignments => actions.LoadAssignmentsSuccess({ assignments })),
          catchError(error => of(actions.LoadAssignmentsFail({ error })))
        )
      )
    );
  },
  { functional: true }
);

export const LoadCurrentAssignment$ = createEffect(
  (actions$ = inject(Actions), service = inject(AssignmentsService)) => {
    return actions$.pipe(
      ofType(actions.LoadCurrentAssignment),
      switchMap(() =>
        service.getCurrentAssignment().pipe(
          map(assignment => actions.LoadCurrentAssignmentSuccess({ assignment })),
          catchError(error => of(actions.LoadAssignmentByIdFail({ error, id: 0 })))
        )
      )
    );
  },
  { functional: true }
);

// export const LoadCurrentAssignmentSucces$ = createEffect(
//   (actions$ = inject(Actions), router = inject(ActivatedRoute)) => {
//     return actions$.pipe(
//       ofType(actions.LoadCurrentAssignmentSuccess),
//       tap(({ assignment }) => {
//         console.log(assignment, router.data);

//         // this.sb.open(`Wachtwoord geüpdatet!`, '', { duration: 5000, panelClass: 'bg-success' });
//       })
//     );
//   },
//   { functional: true, dispatch: false }
// );

export const LoadAssignmentById$ = createEffect(
  (actions$ = inject(Actions), service = inject(AssignmentsService)) => {
    return actions$.pipe(
      ofType(actions.LoadAssignmentById),
      switchMap(({ id }) =>
        service.getAssignmentById(id).pipe(
          map(assignment => actions.LoadAssignmentByIdSuccess({ assignment })),
          catchError(error => of(actions.LoadAssignmentByIdFail({ error, id })))
        )
      )
    );
  },
  { functional: true }
);

export const LoadByIdFail$ = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(actions.LoadAssignmentByIdFail),
      map(({ error }) => {
        return Go({ path: ['/' + error?.status ? error.status : '404'], extras: { skipLocationChange: true } });
      })
    );
  },
  { functional: true }
);
