<section class="container container-size-sm content-block-image-grid section-inset-y">
  <header class="text-center text-max-width mx-auto title-circle-background mb-5">
    <h2 class="fs-display-2 text-primary mb-2">{{ block.title }}</h2>
    <p class="h5 text-tertiary fw-bold" *ngIf="block.subtitle">{{ block.subtitle }}</p>
  </header>

  <div class="position-relative z-2">
    <ul class="post-image-card-grid">
      <li class="post-image-card-grid__item" *ngFor="let post of block.posts">
        <app-post-image-card [post]="post"></app-post-image-card>
      </li>
      <li class="post-image-card-grid__item is--placeholder">
        <fstr-icon anchor="icon-squiggle"></fstr-icon>
      </li>
    </ul>
  </div>
</section>
