import { createAction, props } from '@ngrx/store';
import { AuthData, LoginData, Profile, RegisterData, UpdatePasswordData, ChangeData } from '../../models';

export const CheckAuth = createAction('[Auth] Check Auth');
export const CheckAuthSuccess = createAction('[Auth] Check Auth Success', props<{ auth: AuthData }>());
export const CheckAuthFail = createAction('[Auth] Check Auth Fail', props<{ error: any }>());

export const SignIn = createAction('[Auth] Sign In', props<{ loginData: LoginData }>());
export const SignInSuccess = createAction('[Auth] Sign In Success', props<{ auth: AuthData }>());
export const SignInFail = createAction('[Auth] Sign In Fail', props<{ error: any }>());

export const SignOut = createAction('[Auth] Sign Out');
export const SignOutSuccess = createAction('[Auth] Sign Out Success', props<{ result: { success: boolean } }>());
export const SignOutFail = createAction('[Auth] Sign Out Fail', props<{ error: any }>());

export const Register = createAction('[Auth] Register', props<{ registerData: RegisterData }>());
// TODO:Switch these success calls out
// export const RegisterSuccess = createAction('[Auth] Register Success', props<{ result: any }>());
export const RegisterSuccess = createAction('[Auth] Register Success', props<{ result: AuthData }>());
export const RegisterFail = createAction('[Auth] Register Fail', props<{ error: any }>());

export const Change = createAction('[Auth] Change', props<{ changeData: ChangeData }>());
// TODO:Switch these success calls out
export const ChangeSuccess = createAction('[Auth] Change Success', props<{ auth: AuthData }>());
//export const ChangeSuccess = createAction('[Auth] Change Success', props<{ result: AuthData }>());
export const ChangeFail = createAction('[Auth] Change Fail', props<{ error: any }>());

export const ActivateAccount = createAction('[Auth] Confirm Account', props<{ token: string; uid: string }>());
export const ActivateAccountSuccess = createAction('[Auth] Confirm Account Success', props<{ result: any }>());
export const ActivateAccountFail = createAction('[Auth] Confirm Account Fail', props<{ error: any }>());

export const UpdateAccount = createAction('[Auth] Update Account', props<{ accountData: any }>());
export const UpdateAccountSuccess = createAction('[Auth] Update Account Success', props<{ profile: Profile }>());
export const UpdateAccountFail = createAction('[Auth] Update Account Fail', props<{ error: any }>());

export const UpdatePassword = createAction('[Auth] Update Password', props<{ passwordData: UpdatePasswordData }>());
export const UpdatePasswordSuccess = createAction('[Auth] Update Password Success', props<{ result: any }>());
export const UpdatePasswordFail = createAction('[Auth] Update Password Fail', props<{ error: any }>());

export const RemoveErrors = createAction('[Auth] Remove errors');
