import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromContacts from '../reducers/contacts.reducer';
import { Contact } from '../../models';
import * as fromRoot from '../../../store';

export const getContactsState = createSelector(fromFeature.getContactState, (state: fromFeature.ContactsState) => state.contacts);

export const getContactEntities = createSelector(getContactsState, fromContacts.getContactsEntities);

export const getAllContacts = createSelector(getContactEntities, entities => {
  return Object.keys(entities).map(slug => entities[slug]);
});

export const getContactsLoaded = createSelector(getContactsState, fromContacts.getContactsLoaded);
export const getContactsLoading = createSelector(getContactsState, fromContacts.getContactsLoading);
