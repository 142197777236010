<section class="section-outset-y content-block-faq">
  <div class="container">
    <header class="text-primary text-center pb-md-5">
      <h1 class="h2 text-max-width content-block-faq__title mx-auto title-squiggle squiggle--center">
        {{ block.title }}
        <fstr-icon anchor="icon-squiggle"></fstr-icon>
      </h1>
    </header>
    <main class="content-block-faq__main mt-4">
      <ng-container *ngIf="block.faqs">
        <ul class="content-block-faq__list">
          <li class="content-block-faq__list__item" *ngFor="let q of block.faqs | slice : 0 : block.faqs.length / 2">
            <app-faq-card
              (click)="activeQuestionId = q.id"
              [openCard]="activeQuestionId === q.id"
              [question]="q.question"
              [answer]="q.answer"
            ></app-faq-card>
          </li>
        </ul>
        <ul class="content-block-faq__list">
          <li class="content-block-faq__list__item" *ngFor="let q of block.faqs | slice : block.faqs.length / 2 : block.faqs.length">
            <app-faq-card
              (click)="activeQuestionId = q.id"
              [openCard]="activeQuestionId === q.id"
              [question]="q.question"
              [answer]="q.answer"
            ></app-faq-card>
          </li>
        </ul>
      </ng-container>
    </main>
  </div>
</section>
