import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild, effect } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InViewModule } from '@teamfoster/sdk/in-view';
import { IconModule } from '@teamfoster/sdk/icon';
import { ApexAxisChartSeries, ApexChart, ApexFill, ApexResponsive, ApexStroke, ChartComponent, NgApexchartsModule } from 'ng-apexcharts';
import { StatisticAverageWeek } from 'src/app/statistics/models';

export type ChartOptions = {
  series: any;
  chart: ApexChart;
  stroke?: ApexStroke;
  dataLabels?: any;
  fill: ApexFill;
};

@Component({
  selector: 'app-donut-chart',
  standalone: true,
  imports: [CommonModule, IconModule, NgApexchartsModule],
  templateUrl: './donut-chart.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DonutChartComponent implements OnChanges {
  @Input() title!: string;
  @Input() subtitle!: string;
  @Input() data: StatisticAverageWeek[] = [];
  @Input() total: number = 70;
  @Input() colors: string[] = ['#000000'];
  @Input() sizeDonut = [100, 110];

  average!: number;

  public chartOptions!: Partial<ChartOptions>;
  series!: ApexAxisChartSeries;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'].currentValue) {
      const data: StatisticAverageWeek[] = changes['data'].currentValue;
      const convertData = [...data.map(item => +item.averageValue)];
      const totalValue = convertData.reduce((acc, item) => acc + item, 0);
      this.average = totalValue ? totalValue / convertData.length : 0;
      const percentage = (this.average / this.total) * 100;

      this.chartOptions = {
        series: [percentage, 100 - percentage],

        chart: {
          height: this.sizeDonut[1],
          width: this.sizeDonut[0],
          type: 'donut',
          toolbar: {
            show: false,
          },
        },
        fill: {
          colors: [...this.colors],
          opacity: [1, 0.2],
        },
      };
    }
  }
}
