import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AssignmentPost, CreateAssignmentPostData } from '../models';
import { PagedOverview } from 'src/app/core/models/paged-overview.model';
import { AssignmentPostType } from '../enums';

@Injectable({
  providedIn: 'root',
})
export class AssignmentPostsService {
  constructor(
    private http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string
  ) {}

  getAssignmentPostsForAssignment(assignmentId: number, skip: number = 0, take: number = 20, type: AssignmentPostType | null = null) {
    //getAssignmentPostsForAssignment(assignmentId: number, skip: number = 0, take: number = 20) {
    return this.http.get<PagedOverview<AssignmentPost>>(`${this.BASE_URL}api/assignments/${assignmentId}/posts?take=${take}&skip=${skip}`);
  }

  deleteAssignmentPosts(id: number) {
    return this.http.delete<AssignmentPost>(`${this.BASE_URL}api/posts/${id}`);
  }

  createAssignmentPost(data: CreateAssignmentPostData) {
    const formData: FormData = new FormData();
    if (data.image instanceof Blob) {
      formData.append('image', data.image);
    } else {
      formData.append('image', <Blob>(null as unknown));
    }

    formData.append('text', data.text);
    formData.append('postType', data.postType.toString());

    return this.http.post<AssignmentPost>(`${this.BASE_URL}api/assignments/${data.assignmentId}/posts`, formData);
  }
}
