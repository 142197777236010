import { Injectable, inject } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as actions from '../actions/contacts.action';
import { switchMap, map, catchError } from 'rxjs/operators';
import { ContactsService } from '../../services';
import { Go } from 'src/app/store';

export const LoadContacts$ = createEffect(
  (actions$ = inject(Actions), service = inject(ContactsService)) => {
    return actions$.pipe(
      ofType(actions.LoadContacts),
      switchMap(() =>
        service.getContacts().pipe(
          map(contacts => actions.LoadContactsSuccess({ contacts })),
          catchError(error => of(actions.LoadContactsFail({ error })))
        )
      )
    );
  },
  { functional: true }
);
