import { Component, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Store } from '@ngrx/store';
import { tap, filter, take, switchMap, catchError, withLatestFrom, map } from 'rxjs/operators';

import * as fromStore from '../store';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class StatisticOverviewDeactiveGuard implements CanDeactivate<Component> {
  constructor(private store: Store<fromStore.StatisticState>) {}

  canDeactivate(
    component: Component,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot | undefined
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.checkDeactivate().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  checkDeactivate(): Observable<boolean> {
    return this.store.select(fromStore.getStatisticsLoaded).pipe(
      tap(loaded => {
        if (loaded) {
          this.store.dispatch(fromStore.ClearStatistics());
        }
      }),
      filter(loaded => !loaded),
      map(loaded => !loaded),
      take(1)
    );
  }
}
