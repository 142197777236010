<section class="section-outer-y section-inset-y content-block-countdown text-primary">
  <div class="container">
    <header class="text-max-width text-center mx-auto content-block-countdown__title" *ngIf="!startCampain$()">
      <h2>{{ block.title }}</h2>
    </header>
    <main class="text-center" *ngIf="block.date">
      <ng-container *ngIf="!startCampain$()">
        <ol class="content-block-countdown__list py-4" *ngIf="countdown$() as countdown">
          <ng-container *ngFor="let property of countdownOrder">
            <li
              class="content-block-countdown__list__item counter"
              [class.hide--days]="property === 'days' && +countdown['days'] < 1"
              [class.hide--months]="property === 'months' && +countdown['months'] < 1"
              [class.hide--years]="property === 'years' && +countdown['years'] < 1"
              [class.hide--seconds]="property === 'seconds' && +countdown['months'] > 0"
            >
              <div class="counter__digit display-1" [ngClass]="property">
                <div class="counter__digit__top" [attr.data-count]="countdown[property]">{{ countdown[property] }}</div>
                <div class="counter__digit__bottom" [attr.data-count]="countdown[property]">{{ countdown[property] }}</div>

                <!-- <div class="counter__digit__back">
                {{ +countdown[property] < 11 ? '0' + (+countdown[property] - 1) : +countdown[property] - 1 }}
              </div> -->
                <span class="counter__digit__divider"></span>
              </div>
              <em class="counter__label">
                <ng-container *ngIf="property === 'months'">{{ countdown[property] === '01' ? 'Maand' : 'Maanden' }}</ng-container>
                <ng-container *ngIf="property === 'days'">{{ countdown[property] === '01' ? 'Dag' : 'Dagen' }}</ng-container>
                <ng-container *ngIf="property === 'hours'">{{ countdown[property] === '01' ? 'Uur' : 'Uren' }}</ng-container>
                <ng-container *ngIf="property === 'minutes'">{{ countdown[property] === '01' ? 'Minuut' : 'Minuten' }}</ng-container>
                <ng-container *ngIf="property === 'seconds'">{{ countdown[property] === '01' ? 'Seconde' : 'Seconden' }}</ng-container>
              </em>
            </li>
          </ng-container>
        </ol>

        <a [routerLink]="['/account/registreren']" class="button button--primary mx-auto mt-4">Meld je aan!</a>
      </ng-container>
      <section class="content-block-countdown__end text-max-width mx-auto" *ngIf="startCampain$()">
        <h3 class="h2 content-block-countdown__end__title mb-0">{{ 'countdown-einde-tekst' | fromDictionary }}</h3>
        <span class="counter__digit__divider"></span>
      </section>
    </main>
  </div>
</section>
