<article class="comment-card card">
  <header class="comment-card__header mb-0 d-flex flex-gap-2 justify-content-between align-items-center">
    <div class="d-flex flex-gap-2 align-items-center">
      <app-avatar
        [size]="'xs'"
        [imageUrl]="comment.author.avatar"
        class="comment-card__avatar"
        [name]="comment.author.fullName"
      ></app-avatar>
      <div class="d-flex flex-column">
        <h2 class="mb-0 comment-card__title fs-body-sm">
          {{ comment.author.firstName }}
        </h2>
        <em class="text-muted fs-body-xs">{{ relativeDate }}</em>
      </div>
    </div>
    <div class="d-flex flex-gap-2 comment-card__type">
      <button (click)="this.remove.emit(comment)" class="button button-link button--xs ms-2" *ngIf="usr?.id === comment.author.id">
        <fstr-icon anchor="icon-trash"></fstr-icon>
      </button>
    </div>
  </header>
  <main class="comment-card__main">
    <div class="markdown-content fs-body-sm" [innerHTML]="comment.text"></div>
  </main>
</article>
