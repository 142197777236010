import { createReducer, on } from '@ngrx/store';
import * as ThemeActions from '../actions/theme.action';

export interface ThemeState {
  theme: string;
}

export const initialState: ThemeState = {
  theme: 'light',
};

export const ThemeReducer = createReducer(
  initialState,
  on(ThemeActions.SetTheme, (state, { theme }) => {
    return {
      theme,
    };
  })
);

export const getTheme = (state: ThemeState) => state.theme;
