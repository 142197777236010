<section class="row block-payoff">
  <ul class="payoff-gallery is--left col-md-3">
    <li *ngFor="let image of sixImages | slice : 0 : 3" class="payoff-gallery__item">
      <fstr-image [imageSizes]="[400, 800, 1200]" [image]="image" *ngIf="image.url" class="d-block"></fstr-image>
    </li>
  </ul>
  <article class="text-center col-md-6 d-flex justify-content-center align-items-center">
    <main class="block-payoff__main flex-gap-3 p-4">
      <fstr-icon anchor="logo-100-100-100" class="text-light w-100 block-payoff__logo"></fstr-icon>
      <div
        class="text-light"
        fstrDynamicContent
        [innerHTML]="block.text || '' | resizeHtmlImages | stripEmbed : cookiePreferences | safe"
        *ngIf="block.text"
      ></div>
      <div class="block-payoff__socials" *ngIf="socialChannels">
        <strong class="d-block">{{ 'praat-mee-socials-tekst' | fromDictionary }}</strong>
        <ul class="d-flex align-items-center justify-content-center flex-gap-3 mt-2">
          <ng-container *ngFor="let item of socialChannels; let i = index">
            <li *ngIf="item.icon">
              <a [href]="item.url" target="_blank">
                <fstr-icon [anchor]="item.icon" class="icon-md"></fstr-icon>
              </a>
            </li>
          </ng-container>
        </ul>
      </div>
    </main>
  </article>
  <ul class="payoff-gallery is--right col-md-3">
    <li *ngFor="let image of sixImages | slice : 3 : 6" class="payoff-gallery__item">
      <fstr-image [imageSizes]="[400, 800, 1200]" [image]="image" *ngIf="image.url" class="d-block"></fstr-image>
    </li>
  </ul>
</section>
