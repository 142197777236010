import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { FromDictionaryPipe, LanguageService } from '@teamfoster/sdk/dictionary-ngrx';
import { Observable, of } from 'rxjs';
import { SetTitle } from '../../../store';
import { ActivateAccount, getAccountActivated, getAccountActivating, getAuthError, RemoveErrors } from '../../store';

@Component({
  selector: 'app-activate-account',
  templateUrl: './activate-account.component.html',
})
export class ActivateAccountComponent implements OnInit {
  store = inject(Store);
  route = inject(ActivatedRoute);
  lang = inject(LanguageService);

  prefix = 'account-activatie';
  activating$ = this.store.selectSignal(getAccountActivating);
  activated$ = this.store.selectSignal(getAccountActivated);
  // activating$: Observable<boolean> = of(false);
  // activated$: Observable<boolean> = of(false);
  error$ = this.store.selectSignal(getAuthError);

  private dict = new FromDictionaryPipe(this.lang);

  ngOnInit(): void {
    this.store.dispatch(RemoveErrors());
    // this.activated$ = this.store.select(getAccountActivated);
    // this.activating$ = this.store.select(getAccountActivating);
    // this.error$ = this.store.select(getAuthError);
    this.store.dispatch(
      SetTitle({ title: `${this.dict.transform('account-activatie-titel')} - ${this.dict.transform('meta-titel-suffix')}` })
    );
    this.activateAccount(this.route.snapshot.queryParams['token'], this.route.snapshot.queryParams['uid']);
  }

  activateAccount(token: string, uid: string) {
    this.store.dispatch(ActivateAccount({ token, uid }));
  }
}
