<article class="faq-card" [class.open--card]="openCard">
  <h2 class="faq-card__title fs-body-lg text-primary">
    <button class="" (click)="openCard = !openCard">
      {{ question }}
      <fstr-icon class="icon-sm" anchor="icon-chevron-right"></fstr-icon>
    </button>
  </h2>
  <main class="faq-card__main">
    <div class="pb-4 markdown-content" [innerHTML]="answer"></div>
  </main>
</article>
