import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AtuhRoutingModule } from './auth-routing.module';
import { RegisterComponent } from './containers/register/register.component';
import { CoreModule } from '../core/core.module';
import { TextUtilityModule } from '@teamfoster/sdk/text-utility';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import * as fromAuth from './store';
import { RouterModule } from '@angular/router';
import { DynamicFormModule } from '@teamfoster/sdk/dynamic-form';
import { AuthLayoutComponent } from './components/auth-layout/auth-layout.component';
import { RequiredRolePipe } from './pipes/role.pipe';
import { IconModule } from '@teamfoster/sdk/icon';
import { ActivateAccountComponent } from './containers/activate-account/activate-account.component';
import { SignInComponent } from './containers/sign-in/sign-in.component';
import { ForgotPasswordComponent } from './containers/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './containers/reset-password/reset-password.component';
import { ProfileComponent } from './containers/profile/profile.component';

@NgModule({
  declarations: [
    RegisterComponent,
    SignInComponent,
    ActivateAccountComponent,
    ForgotPasswordComponent,
    AuthLayoutComponent,
    RequiredRolePipe,
    ActivateAccountComponent,
    ResetPasswordComponent,
    ProfileComponent,
  ],
  imports: [
    AtuhRoutingModule,
    CommonModule,
    DynamicFormModule,
    CoreModule,
    TextUtilityModule,
    DictionaryNgrxModule,
    RouterModule,
    MatSnackBarModule,
    IconModule,
    StoreModule.forFeature(fromAuth.featureKey, fromAuth.reducers, {
      metaReducers: fromAuth.metaReducers,
    }),
    EffectsModule.forFeature(fromAuth.effects),
  ],
  exports: [RequiredRolePipe],
})
export class AuthModule {}
