<ng-container [formGroup]="group" *ngIf="group.get(config.name) as field">
  <mat-form-field appearance="fill" *ngIf="date" [formGroup]="date">
    <mat-label *ngIf="config.label">{{ config.label }}</mat-label>
    <input
      matInput
      readonly=""
      formControlName="date"
      [matDatepicker]="picker"
      [max]="config['maxDate']"
      [min]="config['minDate']"
      [placeholder]="config.placeholder ? config.placeholder : ''"
      (click)="picker.open()"
    />
    <mat-hint *ngIf="config.hint">{{ config.hint }}</mat-hint>
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>

    <mat-error *ngIf="showError && date?.get('date')">
      {{ date.get('date')! | formError : config.customValidationMessages }}
    </mat-error>
  </mat-form-field>
</ng-container>
