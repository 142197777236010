<section class="section-inset-t bg-primary-100 px-2" (fstrInViewport)="inviewPort = true">
  <header class="text-center text-max-width mx-auto pb-4 container" *ngIf="block.title || block.intro">
    <h2 class="fs-display-2 text-primary mb-2">{{ block.title }}</h2>
    <p class="h5 text-tertiary fw-bold">{{ block.subtitle }}</p>
  </header>

  <div class="infograpic-section bg-light container pt-4 pt-md-6 pb-5">
    <article class="row trash-infographic" *ngFor="let item of block.listStatistics; trackBy: trackByFn">
      <figure class="col-6 trash-infographic__figure">
        <img
          [style.--size-percentage]="getPercentageOfTotal(item.quantity)"
          class="scaled-trashbag"
          [src]="'statistieken-infographic-afbeelding-url' | fromDictionary"
          [alt]="'statistieken-infographic-afbeelding-alt' | fromDictionary"
        />
      </figure>

      <main class="col-6 text-primary trash-infographic__main">
        <h2 class="fs-display-1 mb-3">
          <span [fstrCount]="item.quantity" [from]="0" [decimals]="1" [countStart]="inviewPort"></span>
          <span class="fs-display-3">{{ 'statistieken-unit-label' | fromDictionary }}</span>
        </h2>

        <strong
          class="fs-display-4 lh-1 text-max-width-xxxs text-balance d-inline-block"
          [innerHtml]="item.text! | highlight : 'text-tertiary'"
        ></strong>
      </main>
    </article>
  </div>
</section>
