<ng-container [formGroup]="group" class="form-radio-buttons">
  <div
    class="custom-field form-radio-buttons"
    *ngIf="group.get(config.name) as field"
    [ngClass]="{ 'custom-field--invalid': showError(field) }"
    [class.has--label]="!!config.label"
    [class.has--sub-label]="!!config.subLabel"
  >
    <mat-radio-group class="custom-field__field" [formControlName]="config.name">
      <div class="mat-form-field mb-1" [ngClass]="'type--' + option.id" *ngFor="let option of config.selectOptions">
        <mat-radio-button [value]="option.id">
          <fstr-icon [anchor]="option.icon ? option.icon : 'icon-arrow-left'"></fstr-icon>
          <span class="label">{{ option.title }}</span>
        </mat-radio-button>
      </div>
    </mat-radio-group>

    <div class="custom-field__error-wrapper">
      <mat-error *ngIf="showError(field)">
        {{ field | formError : config.customValidationMessages }}
      </mat-error>
    </div>
  </div>
</ng-container>
