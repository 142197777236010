<section class="container section-inset-y" (fstrInViewport)="inviewPort = true">
  <header class="text-center text-max-width mx-auto pb-4" *ngIf="block.title || block.intro">
    <h2 class="fs-display-2 text-primary mb-2">{{ block.title }}</h2>
    <p class="h5 text-tertiary fw-bold">{{ block.subtitle }}</p>
  </header>

  <ul class="number-list row">
    <li class="number-list__item col-6 col-md-4" *ngFor="let statistic of block.listStatistics">
      <h2 class="mb-0 d-flex flex-column text-center">
        <span class="fs-display-1" [fstrCount]="statistic.quantity" [from]="0" [decimals]="0" [countStart]="inviewPort"></span>
        <span class="fs-body-lg">{{ statistic.text }}</span>
      </h2>
    </li>
  </ul>
</section>
