import { createReducer, on } from '@ngrx/store';
import { AssignmentPost } from '../../models';
import * as pageActions from '../actions/posts.action';
import { ToEntities } from '@teamfoster/sdk/array-utility';
import { PageData } from 'src/app/core/models/page-data.model';

export interface PostsState {
  entities: { [id: string]: AssignmentPost };
  loaded: boolean;
  loading: boolean;
  error?: any;
  page: PageData;
  saving: boolean;
  saved: boolean;
}

export const initialState: PostsState = {
  entities: {},
  loaded: false,
  loading: false,
  page: { currentPage: 0, pageSize: 20, pageCount: 0, resultCount: 0, filters: [] },
  saved: false,
  saving: false,
};

export const PostsReducer = createReducer(
  initialState,
  on(pageActions.LoadPostsById, (state: PostsState, { id }) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(pageActions.LoadPostsByIdSuccess, (state: PostsState, { posts }) => {
    const entities = ToEntities(posts.items, 'id', state.entities);
    // console.log(posts);
    return {
      ...state,
      entities,
      page: posts.page,
      loading: false,
      loaded: true,
    };
  }),
  on(pageActions.LoadPostsByIdFail, (state: PostsState, { error, id }) => {
    return {
      ...state,
      loading: false,
      loaded: false,
      error,
    };
  }),
  on(pageActions.DeletePostSuccess, (state: PostsState, { post }) => {
    console.log(post);
    const { [post.id]: removed, ...entities } = state.entities;
    return {
      ...state,
      entities,
    };
  }),
  on(pageActions.CreatePost, (state: PostsState) => {
    return {
      ...state,
      saved: false,
      saving: true,
    };
  }),
  on(pageActions.CreatePostSuccess, (state: PostsState, { post }) => {
    const entities = {
      ...state.entities,
      [post.id]: post,
    };
    return {
      ...state,
      entities,
      saved: true,
      saving: false,
    };
  }),
  on(pageActions.ClearPosts, (state: PostsState) => {
    return {
      ...initialState,
    };
  })
);

export const getPostsAssignmentsEntities = (state: PostsState) => state.entities;
export const getPostsAssignmentsLoading = (state: PostsState) => state.loading;
export const getPostsAssignmentsLoaded = (state: PostsState) => state.loaded;
export const getPostsAssignmentsPage = (state: PostsState) => state.page;
export const getPostsAssignmentsSaved = (state: PostsState) => state.saved;
export const getPostsAssignmentsSaving = (state: PostsState) => state.saving;
