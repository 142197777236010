import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { DynamicFormField } from '@teamfoster/sdk/dynamic-form';

@Component({
  selector: 'app-form-radio-buttons',
  templateUrl: './form-radio-buttons.component.html',
})
export class FormRadioButtonsComponent implements OnInit {
  config!: DynamicFormField;
  group!: UntypedFormGroup;

  constructor() {}

  ngOnInit(): void {}

  isRequired(ctrl: AbstractControl) {
    if (!ctrl.validator) {
      return false;
    }
    const validator = ctrl?.validator({} as AbstractControl);
    return validator && validator['required'];
  }

  showError(field: AbstractControl) {
    return field.invalid && (field.touched || field.dirty);
  }
}
