import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MenuItem } from '@teamfoster/sdk/menu-ngrx';
import { ContentBlock } from '../../models';

@Component({
  selector: 'app-content-block-menu-list',
  templateUrl: './content-block-menu-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockMenuListComponent {
  @Input() menu: MenuItem[] = [];
  @Input() block!: ContentBlock;
}
