<figure class="avatar" [ngClass]="'avatar--' + size" [class.has--image]="imageUrl">
  <img *ngIf="imageUrl && !ifUploadObj" [src]="imageUrl | resize : 800 : 800" [alt]="name || 'Profielfoto'" />
  <img *ngIf="imageUrl && ifUploadObj" [src]="imageUrl" [alt]="name || 'Profielfoto'" />

  <div class="avatar__name" *ngIf="!imageUrl">
    <svg
      *ngIf="name"
      xmlns="http://www.w3.org/2000/svg"
      xml:space="preserve"
      style="enable-background: new 0 0 101.5 118.4"
      viewBox="0 0 101.5 118.4"
    >
      <text x="50%" y="56%">{{ name | slice : 0 : 1 }}</text>
    </svg>

    <fstr-icon *ngIf="!name" anchor="icon-squiggle"></fstr-icon>
  </div>
</figure>
