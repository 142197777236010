import { Injectable, inject } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as actions from '../actions/statistics.action';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { StatisticService } from '../../services';
import { MatSnackBar } from '@angular/material/snack-bar';

export const LoadStatistic$ = createEffect(
  (actions$ = inject(Actions), service = inject(StatisticService)) => {
    return actions$.pipe(
      ofType(actions.LoadStatistics),
      switchMap(() =>
        service.getStatistics().pipe(
          map(Statistics => actions.LoadStatisticsSuccess({ Statistics })),
          catchError(error => of(actions.LoadStatisticsFail({ error })))
        )
      )
    );
  },
  { functional: true }
);

export const CreateStatistic$ = createEffect(
  (actions$ = inject(Actions), service = inject(StatisticService)) => {
    return actions$.pipe(
      ofType(actions.CreateStatistic),
      switchMap(({ statistic }) => {
        return service.CreateStatistic(statistic).pipe(
          map(statistic => actions.CreateStatisticSuccess({ statistic })),
          catchError(error => of(actions.CreateStatisticFail({ error })))
        );
      })
    );
  },
  { functional: true }
);

export const CreateStatisticSuccess$ = createEffect(
  (actions$ = inject(Actions), sb = inject(MatSnackBar)) => {
    return actions$.pipe(
      ofType(actions.CreateStatisticSuccess),
      tap(statistic => {
        sb.open('Uw reactie is opgeslagen', '', { panelClass: 'bg-success', duration: 1500 });
      })
    );
  },
  { functional: true, dispatch: false }
);
