import { inject } from '@angular/core';

import { Actions, ofType, createEffect } from '@ngrx/effects';
import * as actions from '../actions/edition-data.action';

import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { EditionDataService } from '../../services/edition-data.service';
import { of } from 'rxjs';

export const loadEditionData$ = createEffect(
  (actions$ = inject(Actions), service = inject(EditionDataService)) => {
    return actions$.pipe(
      ofType(actions.LoadEditionData),
      switchMap(() =>
        service.getEditionData().pipe(
          map(editionData => actions.LoadEditionDataSuccess({ editionData })),
          catchError(error => of(actions.LoadEditionDataFail({ error })))
        )
      )
    );
  },
  { functional: true }
);
