<article class="statistic-overview">
  <header class="section-inset-y statistic-overview__header">
    <div class="container">
      <div class="row">
        <div class="col-md-5">
          <h1 class="h1 mb-2 text-primary">{{ 'statistieken-pagina-titel' | fromDictionary }}</h1>
          <p class="h6 text-tertiary">{{ 'statistieken-pagina-intro' | fromDictionary }}</p>
        </div>
        <aside class="col-md-7 mt-3 mt-mt-0">
          <div class="statistic-overview__header__info d-flex">
            <figure class="rounded-xl rounded-tl-0 rounded-br-0 statistic-overview__header__info__icon">
              <fstr-icon anchor="icon-bulb"></fstr-icon>
            </figure>
            <div class="statistic-overview__header__info__text">
              <h5 class="fs-body-lg text-primary">{{ 'statistieken-pagina-info-titel' | fromDictionary }}</h5>
              <p class="fs-body-md text-primary">{{ 'statistieken-pagina-info' | fromDictionary }}</p>
            </div>
          </div>
        </aside>
      </div>
    </div>
  </header>

  <main class="statistic-overview__main section-inset-b" [class.platform--data]="this.platform$()?.countryAverage && editionStarted$()">
    <div class="container">
      <div class="row">
        <ng-container *ngIf="this.platform$()?.countryAverage && editionStarted$(); else noData">
          <section class="col-md-8 order-1">
            <h2 class="h5 text-primary">{{ 'statistieken-pagina-grafiek-titel' | fromDictionary }}</h2>

            <div class="card ps-1">
              <apx-chart
                *ngIf="
                  chartOptions.chart &&
                  chartOptions.series &&
                  chartOptions.xaxis &&
                  chartOptions.title &&
                  chartOptions.stroke &&
                  chartOptions.yaxis &&
                  chartOptions.markers
                "
                [series]="chartOptions.series"
                [chart]="chartOptions.chart"
                [xaxis]="chartOptions.xaxis"
                [yaxis]="chartOptions.yaxis"
                [stroke]="chartOptions.stroke"
                [markers]="chartOptions.markers"
                [legend]="{ show: true, offsetY: 1, position: 'bottom', horizontalAlign: 'right', markers: { width: 7, height: 7 } }"
                [fill]="{
                  opacity: [1, 0.2, 0.2]
                }"
                [title]="chartOptions.title"
              ></apx-chart>

              <section class="ps-3" *ngIf="usr$() as usr">
                <header class="mb-3">
                  <h3 class="h6 mb-0 text-primary">{{ 'statistieken-pagina-donut-titel' | fromDictionary }}</h3>
                  <em>{{ 'statistieken-pagina-donut-subtitel' | fromDictionary }}</em>
                </header>
                <div
                  class="d-flex flex-gap-3 justify-content-stretch justify-content-md-between flex-wrap flex-lg-nowrap"
                  *ngIf="platform$() as platform"
                >
                  <app-donut-chart
                    *ngIf="getDataUser$()"
                    [title]="usr.firstName"
                    [subtitle]="'statistiek-donut-suffix' | fromDictionary"
                    [colors]="['statistiek-kleur-gebruiker' | fromDictionary]"
                    [data]="getDataUser$()"
                    [total]="platform.countryAverage"
                  ></app-donut-chart>
                  <app-donut-chart
                    *ngIf="statsPlatform$() as data"
                    [title]="'platform-name' | fromDictionary"
                    [subtitle]="'statistiek-donut-suffix-m' | fromDictionary"
                    [colors]="['statistiek-kleur-platform' | fromDictionary]"
                    [data]="data"
                    [total]="platform.countryAverage"
                  ></app-donut-chart>
                  <app-donut-chart
                    [title]="'landelijk-gemiddelde-label' | fromDictionary"
                    [subtitle]="'statistiek-donut-suffix-m' | fromDictionary"
                    [colors]="['statistiek-kleur-landelijk' | fromDictionary]"
                    [data]="[
                      {
                        week: 1,
                        startDate: '',
                        endDate: '',
                        averageValue: '' + platform.countryAverage
                      }
                    ]"
                    [total]="platform.countryAverage"
                  ></app-donut-chart>
                </div>
              </section>
            </div>
          </section>
          <aside class="col-md-4 mb-4 md-mb-0 order-0 order-md-2">
            <h2 class="h5 text-primary">{{ 'statistieken-pagina-formulier-titel' | fromDictionary }}</h2>

            <div [@FadeInAnimation]="openSucces" *ngIf="edition$()?.startDate">
              <app-statistic-form
                *ngIf="!openSucces"
                [edition]="edition$()"
                [usr]="usr$()"
                (formValue)="formSubmit($event)"
                #form
              ></app-statistic-form>
              <article class="card d-block bg-success text-white" *ngIf="openSucces">
                <h2 class="h6 font-secondary fw-bold mb-3">{{ 'statistieken-formulier-succes-geslaagd-titel' | fromDictionary }}</h2>
                <p class="fs-body-md">{{ 'statistieken-formulier-succes-geslaagd-tekst' | fromDictionary }}</p>
              </article>
            </div>
          </aside>
        </ng-container>

        <ng-template #noData>
          <div class="col-md-7">
            <section class="card">
              <h2 class="h5 text-primary">{{ 'statistieken-pagina-geen-data-titel' | fromDictionary }}</h2>
              <div class="markdown-content" [innerHTML]="'statistieken-pagina-geen-data-tekst' | fromDictionary"></div>
            </section>
          </div>
        </ng-template>
      </div>
    </div>
  </main>
</article>
