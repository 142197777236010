import { ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { environment } from '../../../../environments/environment';
import * as fromAuth from './auth.reducer';

export const featureKey = 'auth';

export interface AuthState {
  auth: fromAuth.AuthState;
}

export const reducers: ActionReducerMap<AuthState> = {
  auth: fromAuth.authReducer,
};

export const getContentState = createFeatureSelector<AuthState>(featureKey);

export const metaReducers: MetaReducer<AuthState>[] = !environment.production ? [] : [];
