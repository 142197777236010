import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { DynamicFormField } from '@teamfoster/sdk/dynamic-form';

@Component({
  selector: 'form-file-input',
  templateUrl: './form-file-input.component.html',
})
export class FormFileInputComponent implements OnInit {
  config!: DynamicFormField;
  group!: UntypedFormGroup;

  filePreview?: string | ArrayBuffer;
  file?: File;
  fileName?: string;
  fileType?: string;

  dragOver: boolean = false;
  isPreview = false;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.filePreview = this.config.value;
  }

  showError(field: AbstractControl) {
    return field.invalid && (field.touched || field.dirty);
  }

  isRequired(field: AbstractControl) {
    if (!field.validator) {
      return false;
    }
    const validator = field?.validator({} as AbstractControl);
    return validator && validator['required'];
  }

  onFileSelected(event: any) {
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }

    this.file = event.target.files[0];
    this.setFileDetails();
  }

  setFileDetails() {
    this.fileName = this.file?.name;
    this.fileType = this.file?.type;
    this.group.get(this.config.name)?.markAsDirty();
    this.group.get(this.config.name)?.setValue(this.file);
    this.setFilePreview();
  }

  setFilePreview() {
    if (!this.fileType?.startsWith('image/') && !this.fileType?.startsWith('image/svg')) {
      return;
    }

    if (this.file) {
      const reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onload = () => {
        this.dragOver = false;
        this.filePreview = reader.result ?? undefined;
        this.isPreview = true;
        this.cd.detectChanges();
      };
    }
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    const files = event.dataTransfer?.files ?? [];

    if (files.length > 0) {
      this.file = files[0];
      this.setFileDetails();
      return;
    }

    console.error('no files found on drop event');
  }
  onDragOver(event: any) {
    event.stopPropagation();
    event.preventDefault();
    this.dragOver = true;
  }
  onDragLeave(event: any) {
    event.stopPropagation();
    event.preventDefault();
    this.dragOver = false;
  }

  clearFile() {
    this.file = undefined;
    this.filePreview = undefined;
    this.fileName = undefined;
    this.group.get(this.config.name)?.setValue(null);
  }

  error(ctrl: AbstractControl) {
    if (ctrl.hasError('required')) {
      return 'Dit veld is verplicht.';
    }
    return null;
  }
}
