import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as socialActions from '../actions/social-channels.action';
import { switchMap, map, catchError } from 'rxjs/operators';
import { SocialChannelsService } from '../../services';

@Injectable()
export class SocialChannelsEffects {
  constructor(private actions$: Actions, private services: SocialChannelsService) {}

  LoadSocialChannels$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(socialActions.LoadSocialChannels),
      switchMap(() =>
        this.services.getSocialChannels().pipe(
          map(channels => socialActions.LoadSocialChannelsSuccess({ channels })),
          catchError(error => of(socialActions.LoadSocialChannelsFail({ error })))
        )
      )
    );
  });
}
