import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InViewModule } from '@teamfoster/sdk/in-view';
import { IconModule } from '@teamfoster/sdk/icon';

@Component({
  selector: 'app-faq-card',
  standalone: true,
  imports: [CommonModule, InViewModule, IconModule],
  templateUrl: './faq-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FaqCardComponent {
  @Input() question: string = '';
  @Input() answer: string = '';
  @Input() openCard = false;
}
