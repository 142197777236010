import { inject } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as actions from '../actions/comment.action';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PostCommentsService } from '../../services/posts-comments.service';
import { Store } from '@ngrx/store';

export const LoadCommentsById$ = createEffect(
  (actions$ = inject(Actions), service = inject(PostCommentsService)) => {
    return actions$.pipe(
      ofType(actions.LoadCommentsById),
      switchMap(({ id }) =>
        service.getCommentsByPostId(id).pipe(
          map(comments => actions.LoadCommentsByIdSuccess({ comments, id })),
          catchError(error => of(actions.LoadCommentsByIdFail({ error, id })))
        )
      )
    );
  },
  { functional: true }
);

export const LoadMoreComments$ = createEffect(
  (actions$ = inject(Actions), service = inject(PostCommentsService)) => {
    return actions$.pipe(
      ofType(actions.LoadMoreComments),
      switchMap(({ id, page }) =>
        service.getCommentsByPostId(id, (page.currentPage + 1) * page.pageSize).pipe(
          map(comments => actions.LoadCommentsByIdSuccess({ comments, id })),
          catchError(error => of(actions.LoadCommentsByIdFail({ error, id })))
        )
      )
    );
  },
  { functional: true }
);

export const CreateComment$ = createEffect(
  (actions$ = inject(Actions), service = inject(PostCommentsService)) => {
    return actions$.pipe(
      ofType(actions.CreateComment),
      switchMap(({ comment }) => {
        return service.createComments(comment).pipe(
          map(comment => actions.CreateCommentSuccess({ comment })),
          catchError(error => of(actions.CreateCommentFail({ error })))
        );
      })
    );
  },
  { functional: true }
);

export const CreateCommentSuccess$ = createEffect(
  (actions$ = inject(Actions), sb = inject(MatSnackBar), store = inject(Store)) => {
    return actions$.pipe(
      ofType(actions.CreateCommentSuccess),
      tap(comment => {
        sb.open('Uw reactie is opgeslagen', '', { panelClass: 'bg-success', duration: 1500 });
        if (comment.comment.postId) {
          store.dispatch(actions.LoadCommentsById({ id: comment.comment.postId }));
        }
      })
    );
  },
  { functional: true, dispatch: false }
);

export const DeleteComment$ = createEffect(
  (actions$ = inject(Actions), service = inject(PostCommentsService)) => {
    return actions$.pipe(
      ofType(actions.DeleteComment),
      switchMap(({ id }) => {
        return service.deleteComments(id).pipe(
          map(comment => actions.DeleteCommentSuccess({ comment })),
          catchError(error => of(actions.DeleteCommentFail({ error })))
        );
      })
    );
  },
  { functional: true }
);

export const DeleteCommentSuccess$ = createEffect(
  (actions$ = inject(Actions), sb = inject(MatSnackBar), store = inject(Store)) => {
    return actions$.pipe(
      ofType(actions.DeleteCommentSuccess),
      tap(comment => {
        sb.open('Uw reactie is verwijderd', '', { panelClass: 'bg-success', duration: 1500 });

        if (comment.comment.postId) {
          store.dispatch(actions.LoadCommentsById({ id: comment.comment.postId }));
        }
      })
    );
  },
  { functional: true, dispatch: false }
);
