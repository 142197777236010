<!-- <section class="comments-list" (fstrInViewport)="getComments()" [once]="true"> -->
<section class="comments-list">
  <header>
    <div class="mb-3">
      <fstr-dynamic-form
        [config]="formFields | sortBy : 'order'"
        (formSubmit)="submitForm($event)"
        [appearance]="'fill'"
        [showSubmit]="false"
        #form
      >
        <div slot="form-actions" class="d-flex flex-gap-2 justify-content-between">
          <button type="submit" class="button button--tertiary button--sm" [disabled]="!form.form.valid || saving$()">
            {{ 'plaats-reactie-label' | fromDictionary }}
          </button>
          <button
            type="button"
            class="button button-link button--sm fw-bold button--tertiary"
            *ngIf="post?.comments?.length && !openComments && !pageByPost$()"
            (click)="openComments = true; getComments()"
          >
            <span>
              {{ 'reacties-open-button-label' | fromDictionary }}
              <span class="fs-body-xs">({{ post.totalComments }})</span>
            </span>
            <fstr-icon anchor="icon-chevron-down"></fstr-icon>
          </button>

          <button
            type="button"
            class="button button-link button--sm fw-bold button--tertiary"
            *ngIf="!openComments && pageByPost$()"
            (click)="openComments = true"
          >
            <span>
              {{ 'reacties-open-button-label' | fromDictionary }}
              <span class="fs-body-xs">({{ pageByPost$().resultCount }})</span>
            </span>
            <fstr-icon anchor="icon-chevron-down"></fstr-icon>
          </button>
          <button
            type="button"
            class="button button-link button--sm fw-bold button--tertiary"
            *ngIf="comments$().length && openComments"
            (click)="openComments = false"
          >
            <span>{{ 'reacties-sluiten-button-label' | fromDictionary }}</span>
            <fstr-icon anchor="icon-chevron-up"></fstr-icon>
          </button>
        </div>
      </fstr-dynamic-form>
    </div>
  </header>

  <main class="comments-list__main" [class.open--comments]="openComments && comments$().length">
    <ul class="comments-list__list mt-3" *ngIf="usr$() as usr" [@CardGridAnimation]="comments$().length">
      <li class="comments-list__list__item" *ngFor="let comment of comments$()" [ngClass]="usr.id === comment.author.id ? 'set--user' : ''">
        <app-comment-card (remove)="removePost($event)" [usr]="usr$()" [comment]="comment"></app-comment-card>
      </li>
    </ul>
    <ng-container *ngIf="pageByPost$() as page">
      <footer class="d-flex align-items-center justify-content-center flex-gap-2 py-5" *ngIf="page.resultCount > comments$().length">
        <button class="button button--tertiary button--sm" (click)="moreComments()">
          <span>{{ 'reacties-meer-laden-button-label' | fromDictionary }}</span>
        </button>
      </footer>
    </ng-container>
  </main>
</section>
