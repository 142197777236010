import { AssignmentPost } from 'src/app/assignments/models';

const loremTitles = [
  'Lorem ipsum dolor sit amet',
  'Debitis voluptatem temporibus tenetur',
  'Mollitia temporibus aspernatur qui!',
  'Consectetur adipisicing elie',
  'Pariatur numquam incidunt',
  'Provident sapiente accusantium quia',
  'Officiis quis aliquam quisquam veritatis odit tenetur?',
];
const loremText = [
  'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Corrupti eveniet odit tempora commodi quod ipsa consequuntur assumenda aperiamipsum dolor sit amet consectetur adipisicing elit. Corrupti eveniet odit tempora commodi quod ipsa consequuntur assumenda aperiam',
  'nesciunt itaque perspiciatis, dolore eum atque minus. Quod, temporibus ipsa. Beatae, vero.',
  'Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni, quo et. Doloribus, fugiat sunt soluta ullam incidunt eaque accusamus',
  'Lorem ipsum dolor sit amet consectetur adipisicing elitipsum dolor sit amet consectetur adipisicing elit. Corrupti eveniet odit tempora commodi quod ipsa consequuntur assumenda aperiam',
];
const loremImages = [
  'https://100100100.fstr.io/media/uploads/precious-plastic-melbourne-n5qirFAe6rQ-unsplash.jpg',
  'https://100100100.fstr.io/media/uploads/daiga-ellaby-uooMllXe6gE-unsplash.jpg',
  'https://100100100.fstr.io/media/uploads/claudio-schwarz-mLrT9SZJRMM-unsplash(1).jpg',
  'https://100100100.fstr.io/media/uploads/volodymyr-hryshchenko-LHE9c_va1f8-unsplash.jpg',
  'https://100100100.fstr.io/media/uploads/markus-spiske-mSFPGBX7gUI-unsplash.jpg',
  'https://100100100.fstr.io/media/uploads/sahin-sezer-dincer-wN8e50mgxm0-unsplash.jpg',
  'https://100100100.fstr.io/media/uploads/sven-brandsma-fismRbfxvCk-unsplash.jpg',
];

export const loremposts: AssignmentPost[] = Array.from({ length: 5 }, (_, i) => {
  return {
    id: 87,
    text: `<p>${loremText[i % loremText.length]}</p>`,
    image: {
      url: loremImages[i % loremImages.length],
      anchor: null,
      caption: null,
    },
    postType: 3,
    postedOn: '2023-09-28T09:08:05.2672565Z',
    totalComments: 0,
    author: {
      id: 'b14b7592-7bd1-4910-99a7-87dc1ec34e2f',
      firstName: 'Diek1',
      lastName: 'Test',
      fullName: 'Diek1 Test',
      avatar: '',
    },
    comments: [],
  };
});
