import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoginData, AuthData, RegisterData, UpdatePasswordData, Profile, ChangeData } from '../models';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string
  ) {}

  signIn(data: LoginData) {
    return this.http.post<AuthData>(`${this.BASE_URL}api/auth/login`, data);
  }

  checkAuth() {
    return this.http.get<AuthData>(`${this.BASE_URL}api/auth`, { withCredentials: true });
  }

  SignOut() {
    return this.http.post<any>(`${this.BASE_URL}api/auth/logout`, null);
  }

  forgotPassword(email: string) {
    return this.http.post<any>(`${this.BASE_URL}api/auth/forgot-password?email=${email}`, null);
  }

  resetPassword(token: string, uid: string, passwords: any) {
    return this.http.post<any>(`${this.BASE_URL}api/auth/reset-password?uid=${uid}&token=${encodeURIComponent(token)}`, passwords);
  }

  register(regData: RegisterData) {
    const formData: FormData = new FormData();

    Object.keys(regData).forEach(key => {
      formData.append(key, `${(<any>regData)[key]}`);
    });

    if (regData.avatar instanceof Blob) {
      formData.set('avatar', regData.avatar);
    } else {
      formData.set('avatar', <Blob>(null as unknown));
    }

    return this.http.post<AuthData>(`${this.BASE_URL}api/auth/register`, formData);
  }

  change(changeData: ChangeData) {
    const formData: FormData = new FormData();

    Object.keys(changeData).forEach(key => {
      formData.append(key, `${(<any>changeData)[key] || ''}`);
    });

    if (changeData.avatar instanceof Blob) {
      formData.set('avatar', changeData.avatar);
    } else {
      formData.set('avatar', <Blob>(null as unknown));
    }

    return this.http.post<AuthData>(`${this.BASE_URL}api/auth/change`, formData);
  }

  confirmAccount(token: string, uid: string) {
    return this.http.post<any>(`${this.BASE_URL}api/auth/confirm-account?token=${token}&uid=${uid}`, null);
  }

  updatePassword(data: UpdatePasswordData) {
    return this.http.post<any>(`${this.BASE_URL}api/auth/update-password`, data);
  }
}
