import * as fromContacts from '../reducers/contacts.reducer';
import { ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { environment } from '../../../../environments/environment';

export const featureKey = 'contacts';

export interface ContactsState {
  contacts: fromContacts.ContactsState;
}

export const reducers: ActionReducerMap<ContactsState> = {
  contacts: fromContacts.ContactReducer,
};

export const getContactState = createFeatureSelector<ContactsState>(featureKey);

export const metaReducers: MetaReducer<ContactsState>[] = !environment.production ? [] : [];
