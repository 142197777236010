<article
  class="quote-card px-4 py-4 px-md-5 py-md-5 rounded-tl rounded-br"
  [class.mb-md-6]="quote.author.avatar"
  [class.mb-4]="quote.author.avatar"
>
  <blockquote class="h3 quote-card__text mt-4 mt-md-6">{{ quote.quote }}</blockquote>
  <footer class="quote-card__footer">
    <em class="h2 quote-card__author">{{ quote.author.firstName }}</em>
    <app-avatar *ngIf="quote.author.avatar" [size]="'md'" [imageUrl]="quote.author.avatar" [name]="quote.author.fullName"></app-avatar>
  </footer>
</article>
