import { Injectable, inject } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as actions from '../actions/posts.action';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { AssignmentPostsService } from '../../services';
import { MatSnackBar } from '@angular/material/snack-bar';

export const LoadPostsById$ = createEffect(
  (actions$ = inject(Actions), service = inject(AssignmentPostsService)) => {
    return actions$.pipe(
      ofType(actions.LoadPostsById),
      switchMap(({ id }) =>
        service.getAssignmentPostsForAssignment(id).pipe(
          map(posts => actions.LoadPostsByIdSuccess({ posts })),
          catchError(error => of(actions.LoadPostsByIdFail({ error, id })))
        )
      )
    );
  },
  { functional: true }
);

export const LoadMorePosts$ = createEffect(
  (actions$ = inject(Actions), service = inject(AssignmentPostsService)) => {
    return actions$.pipe(
      ofType(actions.LoadMorePosts),
      switchMap(({ id, page }) =>
        service.getAssignmentPostsForAssignment(id, (page.currentPage + 1) * page.pageSize).pipe(
          map(posts => actions.LoadPostsByIdSuccess({ posts })),
          catchError(error => of(actions.LoadPostsByIdFail({ error, id })))
        )
      )
    );
  },
  { functional: true }
);

export const CreatePosts$ = createEffect(
  (actions$ = inject(Actions), service = inject(AssignmentPostsService)) => {
    return actions$.pipe(
      ofType(actions.CreatePost),
      switchMap(({ post }) => {
        return service.createAssignmentPost(post).pipe(
          map(post => actions.CreatePostSuccess({ post })),
          catchError(error => of(actions.CreatePostFail({ error })))
        );
      })
    );
  },
  { functional: true }
);

export const CreatePostsSuccess$ = createEffect(
  (actions$ = inject(Actions), sb = inject(MatSnackBar)) => {
    return actions$.pipe(
      ofType(actions.CreatePostSuccess),
      tap(post => {
        sb.open('Bericht aangemaakt', 'OK', { panelClass: 'bg-success', duration: 1500 });
      })
    );
  },
  { functional: true, dispatch: false }
);

export const DeletePosts$ = createEffect(
  (actions$ = inject(Actions), service = inject(AssignmentPostsService)) => {
    return actions$.pipe(
      ofType(actions.DeletePost),
      switchMap(({ id }) => {
        return service.deleteAssignmentPosts(id).pipe(
          map(post => actions.DeletePostSuccess({ post })),
          catchError(error => of(actions.DeletePostFail({ error })))
        );
      })
    );
  },
  { functional: true }
);

export const DeletePostsSuccess$ = createEffect(
  (actions$ = inject(Actions), sb = inject(MatSnackBar)) => {
    return actions$.pipe(
      ofType(actions.DeletePostSuccess),
      tap(post => {
        sb.open('Bericht verwijderd', 'OK', { panelClass: 'bg-success', duration: 1500 });
      })
    );
  },
  { functional: true, dispatch: false }
);
