import { trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CardGridAnimation } from 'src/app/animations/card-grid.animation';
import { AssignmentPost } from 'src/app/assignments/models';
import { ContentBlock } from '../../models';
import { loremposts } from '../loremposts';

@Component({
  selector: 'app-content-block-wall-posts',
  templateUrl: './content-block-wall-posts.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  //animations: [trigger('CardGridAnimation', [CardGridAnimation])],
})
export class ContentBlockWallPostsComponent {
  @Input() block!: ContentBlock;

  posts: AssignmentPost[] = [...loremposts];
}
