import { AfterViewInit, Component, ElementRef, OnInit, PLATFORM_ID, ViewChild, effect, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Register, getRegisterError, getRegisterSuccess, getRegistering } from '../../store';
import { DynamicFormField, HTMLInputTypeAttribute } from '@teamfoster/sdk/dynamic-form';
import { FromDictionaryPipe, LanguageService } from '@teamfoster/sdk/dictionary-ngrx';
import { Validators } from '@angular/forms';
import { isPlatformBrowser } from '@angular/common';
import { getRouterState } from 'src/app/store';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
})
export class RegisterComponent implements AfterViewInit {
  store = inject(Store);
  lang = inject(LanguageService);
  private dict = new FromDictionaryPipe(this.lang);
  private platformId = inject(PLATFORM_ID);
  @ViewChild('formEl') formEl: any;

  prefix = 'registreren';

  registerSuccess$ = this.store.selectSignal(getRegisterSuccess);
  registering$ = this.store.selectSignal(getRegistering);

  registerError$ = this.store.selectSignal(getRegisterError);

  fieldsets: { name: string; title?: string; collapsed?: boolean }[] = [
    { name: 'profile', title: 'Profiel', collapsed: false },
    { name: 'other', title: 'Overige informatie', collapsed: false },
  ];

  formFields: DynamicFormField[] = [
    {
      label: this.dict.transform(`${this.prefix}-profiel-foto-label`),
      name: 'avatar',
      validators: [],
      placeholder: this.dict.transform(`${this.prefix}-profiel-foto-placeholder`, false),
      fieldType: 'avatar',
      order: 1,
      fieldset: 'profile',
      accept: 'image/*',
    },

    {
      inputType: 'text',
      label: this.dict.transform(`${this.prefix}-voornaam-label`),
      name: 'firstName',
      validators: [Validators.required],
      autocomplete: 'given-name',
      placeholder: this.dict.transform(`${this.prefix}-voornaam-placeholder`, false),
      fieldType: 'input',
      order: 1,
      cssClass: 'col-12 col-md-6',
      fieldset: 'profile',
      subscriptSizing: 'dynamic',
    },
    {
      inputType: 'text',
      label: this.dict.transform(`${this.prefix}-achternaam-label`),
      name: 'lastName',
      validators: [Validators.required],
      autocomplete: 'family-name',
      placeholder: this.dict.transform(`${this.prefix}-achternaam-placeholder`, false),
      fieldType: 'input',
      order: 1,
      cssClass: 'col-12 col-md-6',
      fieldset: 'profile',
      subscriptSizing: 'dynamic',
    },
    {
      inputType: 'text',
      label: this.dict.transform(`${this.prefix}-bio-label`),
      name: 'bio',
      validators: [],
      placeholder: this.dict.transform(`${this.prefix}-bio-placeholder`, false),
      fieldType: 'textarea',
      order: 2,
      fieldset: 'profile',
      subscriptSizing: 'dynamic',
    },
    {
      inputType: 'number',
      label: this.dict.transform(`${this.prefix}-familie-grootte-label`),
      name: 'familySize',
      validators: [],
      placeholder: this.dict.transform(`${this.prefix}-familie-grootte-placeholder`, false),
      fieldType: 'input',
      order: 4,
      cssClass: 'col-12 col-md-12',
      fieldset: 'profile',
      subscriptSizing: 'dynamic',
    },
    {
      inputType: 'email',
      label: this.dict.transform(`${this.prefix}-email-label`),
      name: 'email',
      validators: [Validators.email, Validators.required],
      autocomplete: 'email',
      placeholder: this.dict.transform(`${this.prefix}-email-placeholder`, false),
      fieldType: 'input',
      order: 0,
      fieldset: 'other',
      subscriptSizing: 'dynamic',
    },
    {
      inputType: 'password',
      label: this.dict.transform(`${this.prefix}-wachtwoord-label`),
      name: 'password',
      validators: [Validators.required],
      autocomplete: 'new-password',
      hint: this.dict.transform('registreren-wachtwoord-hint'),
      fieldType: 'input',
      placeholder: this.dict.transform(`${this.prefix}-wachtwoord-placeholder`, false),
      order: 1,
      cssClass: 'mb-3',
      fieldset: 'other',
      subscriptSizing: 'dynamic',
    },
    {
      inputType: 'password',
      label: this.dict.transform(`${this.prefix}-wachtwoord-bevestigen-label`),
      name: 'confirmPassword',
      validators: [Validators.required],
      autocomplete: 'new-password',
      placeholder: this.dict.transform(`${this.prefix}-wachtwoord-bevestigen-placeholder`, false),
      fieldType: 'input',
      order: 1,
      fieldset: 'other',
      subscriptSizing: 'dynamic',
    },
    {
      inputType: 'text',
      label: this.dict.transform(`${this.prefix}-straat-label`),
      name: 'addressStreet',
      validators: [],
      placeholder: this.dict.transform(`${this.prefix}-straat-placeholder`, false),
      fieldType: 'input',
      autocomplete: 'address-line1',
      order: 1,
      cssClass: 'col-12 col-md-12',
      fieldset: 'other',
      subscriptSizing: 'dynamic',
    },
    {
      inputType: 'text',
      label: this.dict.transform(`${this.prefix}-huisnummer-label`),
      name: 'addressNumber',
      validators: [],
      placeholder: this.dict.transform(`${this.prefix}-huisnummer-placeholder`, false),
      fieldType: 'input',
      order: 1,
      cssClass: 'col-12 col-md-6',
      fieldset: 'other',
      subscriptSizing: 'dynamic',
    },
    {
      inputType: 'text',
      label: this.dict.transform(`${this.prefix}-toevoeging-label`),
      name: 'addressAddition',
      validators: [],
      placeholder: this.dict.transform(`${this.prefix}-toevoeging-placeholder`, false),
      fieldType: 'input',
      order: 1,
      cssClass: 'col-12 col-md-6',
      fieldset: 'other',
      subscriptSizing: 'dynamic',
    },
    {
      inputType: 'text',
      label: this.dict.transform(`${this.prefix}-stad-label`),
      name: 'addressCity',
      validators: [],
      placeholder: this.dict.transform(`${this.prefix}-stad-placeholder`, false),
      fieldType: 'input',
      order: 1,
      fieldset: 'other',
      subscriptSizing: 'dynamic',
      cssClass: 'col-12 col-md-12',
      autocomplete: 'address-level2',
    },

    {
      fieldType: 'checkbox',
      label: '',
      checkboxLabel: this.dict.transform(`${this.prefix}-voorwaarden-label`),
      name: 'consent',
      validators: [Validators.requiredTrue],
      order: 1,
      fieldset: 'other',
      subscriptSizing: 'dynamic',
    },
  ];

  routeState$ = this.store.selectSignal(getRouterState);

  registerSuccessEffect$ = effect(() => {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    if (this.registerSuccess$()) {
      window.scrollTo(0, 0);
    }
  });

  ngAfterViewInit(): void {
    this.patchFormFromQueryParams();
  }

  patchFormFromQueryParams() {
    const state = this.routeState$();

    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    const { email, firstName, lastName } = state.state.queryParams;

    this.formEl?.form?.patchValue({
      email,
      firstName,
      lastName,
    });
  }

  handleSubmit(registerData: any) {
    this.store.dispatch(Register({ registerData }));
  }
}
