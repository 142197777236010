import { createReducer, on } from '@ngrx/store';

import * as editionDataActions from '../actions/edition-data.action';
import { editionData } from 'src/app/core/models';

export interface EditionDataState {
  editionData?: editionData;
  loaded: boolean;
  loading: boolean;
  error?: any;
}

export const initialState: EditionDataState = {
  editionData: undefined,
  loaded: false,
  loading: false,
};

export const editionDataReducer = createReducer(
  initialState,
  on(editionDataActions.LoadEditionData, (state: EditionDataState) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(editionDataActions.LoadEditionDataSuccess, (state: EditionDataState, { editionData }) => {
    return {
      ...state,
      editionData,
      loading: false,
      loaded: true,
    };
  }),
  on(editionDataActions.LoadEditionDataFail, (state: EditionDataState, { error }) => {
    return {
      ...state,
      loading: false,
      loaded: false,
      error,
    };
  })
);

export const getEditionDataEntities = (state: EditionDataState) => state.editionData;
export const getEditionDataLoading = (state: EditionDataState) => state.loading;
export const getEditionDataLoaded = (state: EditionDataState) => state.loaded;
