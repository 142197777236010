import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewChild, effect, inject, signal } from '@angular/core';
import { getAuthUser } from 'src/app/auth/store';
import { Store } from '@ngrx/store';
import { DictionaryNgrxModule, FromDictionaryPipe, LanguageService } from '@teamfoster/sdk/dictionary-ngrx';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmComponent } from 'src/app/standalone/confirm/confirm.component';
import { Subject, takeUntil } from 'rxjs';
import {
  CreateComment,
  DeleteComment,
  LoadCommentsById,
  LoadMoreComments,
  getCommentsSaved,
  getCommentsSaving,
  selectCommentsByPostId,
  selectCommentsPagingByPostId,
} from '../../assignments/store';
import { DynamicFormField, DynamicFormModule, FormComponent } from '@teamfoster/sdk/dynamic-form';
import { Validators } from '@angular/forms';
import { trigger } from '@angular/animations';
import { CardGridAnimation } from 'src/app/animations';
import { AssignmentPost, AssignmentPostComment } from 'src/app/assignments/models';
import { CommonModule } from '@angular/common';
import { CommentCardComponent } from '../comment-card/comment-card.component';
import { IconModule } from '@teamfoster/sdk/icon';
import { ArrayUtilityModule } from '@teamfoster/sdk/array-utility';

@Component({
  selector: 'app-comments-list',
  templateUrl: './comments-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [trigger('CardGridAnimation', [CardGridAnimation])],
  imports: [CommonModule, DynamicFormModule, DictionaryNgrxModule, CommentCardComponent, IconModule, ArrayUtilityModule],
  standalone: true,
})
export class CommentsListComponent implements OnDestroy, OnInit {
  @Input() post!: AssignmentPost;
  @ViewChild('form') form?: FormComponent;

  private dialog = inject(MatDialog);
  private lang = inject(LanguageService);
  private dict = new FromDictionaryPipe(this.lang);
  private _unsubscribe = new Subject<void>();
  private store = inject(Store);

  saving$ = this.store.selectSignal(getCommentsSaving);
  saved$ = this.store.selectSignal(getCommentsSaved);
  usr$ = this.store.selectSignal(getAuthUser);
  comments$!: any;
  // commentsPage$ = this.store.selectSignal(getCommentsPage);
  pageByPost$: any;
  openComments = false;

  formFields: DynamicFormField[] = [
    {
      inputType: 'text',
      label: '',
      name: 'text',
      validators: [Validators.required],
      placeholder: `${this.dict.transform('plaats-reactie-placeholder')}`,
      fieldType: 'wysiwyg',
      toolbar: [['bold', 'italic', 'underline', 'strike'], ['link'], [{ list: 'ordered' }, { list: 'bullet' }]],
      order: 1,
      cssClass: 'comments-field ',
    },
  ];

  updateForm = effect(() => {
    const saved = this.saved$();

    if (saved) {
      // this.openComments = true;
      this.form?.form.reset();
      this.form?.form.markAsPristine();
      this.form?.form.markAsUntouched();
      this.form?.form.updateValueAndValidity();
    }
  });

  removePost(comment: AssignmentPostComment) {
    const dialogRefForm = this.dialog.open(ConfirmComponent, {
      maxHeight: '50vh',
      width: '90vw',
      maxWidth: '500px',
      data: {
        message: `${this.dict.transform('verwijder-commentaar-confirm-text')}`,
      },
      disableClose: false,
    });

    dialogRefForm
      .afterClosed()
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(result => {
        if (result) {
          this.store.dispatch(DeleteComment({ id: comment.id }));
        }
      });
  }

  ngOnInit() {
    const postId = this.post.id; // Haal de postId op vanuit je component
    const commentsForPostId = selectCommentsByPostId(postId);
    const pagingById = selectCommentsPagingByPostId(postId);

    this.pageByPost$ = this.store.selectSignal(pagingById);
    this.comments$ = this.store.selectSignal(commentsForPostId);
    // this.store.select(commentsForPostId).subscribe(comments => {
    //   console.log('Opmerkingen voor postId', postId, ':', comments);
    // });
  }

  submitForm($e: any) {
    const formValues = {
      text: $e.text,
      postId: this.post.id,
    };

    if (formValues.postId) {
      this.store.dispatch(CreateComment({ comment: formValues }));
    }

    this.openComments = true;
  }

  getComments() {
    this.store.dispatch(LoadCommentsById({ id: this.post.id }));
  }

  moreComments() {
    // if (!this.commentsPage$()) {
    //   return;
    // }

    this.store.dispatch(LoadMoreComments({ id: this.post.id, page: this.pageByPost$() }));
  }

  ngOnDestroy(): void {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
