<app-auth-layout [title]="prefix + '-titel' | fromDictionary" icon="inloggen">
  <ng-container form>
    <div class="form">
      <!--<app-alert
        *ngIf="activated$ | async"
        class="d-block mb-3"
        [message]="prefix + '-formulier-succes-tekst' | fromDictionary"
        [status]="'success'"
        [title]="prefix + '-formulier-succes-titel' | fromDictionary"
      ></app-alert>-->

      <article *ngIf="activated$()" class="card d-block bg-success text-white reset-card-spacing">
        <h2 class="h4 font-secondary fw-bold mb-2">{{ prefix + '-formulier-succes-titel' | fromDictionary }}</h2>
        <p>{{ prefix + '-formulier-succes-tekst' | fromDictionary }}</p>

        <a class="button button--light" [routerLink]="['/']">
          {{ prefix + '-formulier-succes-knoptekst' | fromDictionary }}
        </a>
      </article>

      <app-alert
        *ngIf="error$()"
        class="d-block mt-4"
        [message]="prefix + '-formulier-error-tekst' | fromDictionary"
        [status]="'danger'"
        [title]="prefix + '-formulier-error-titel' | fromDictionary"
        class="d-block mt-3"
      ></app-alert>
    </div>
  </ng-container>
</app-auth-layout>
