import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { SocialChannel } from '../../models';

@Component({
  selector: 'app-social-channel-buttons',
  templateUrl: './social-channel-buttons.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialChannelButtonsComponent {
  @Input() title = 'Volg ons op:';
  @Input() channels: SocialChannel[] = [];
  followButtons$!: Observable<SocialChannel[]>;

  constructor() {}
}
