import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SocialChannel } from '../models';

@Injectable({
  providedIn: 'root',
})
export class SocialChannelsService {
  constructor(
    private http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string
  ) {}

  getSocialChannels() {
    return this.http.get<SocialChannel[]>(`${this.BASE_URL}api/social-channels`);
  }
}
