import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AssignmentPost, AssignmentPostComment, CreateAssignmentPostComment, CreateAssignmentPostData } from '../models';
import { PagedOverview } from 'src/app/core/models/paged-overview.model';

@Injectable({
  providedIn: 'root',
})
export class PostCommentsService {
  constructor(
    private http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string
  ) {}

  getCommentsByPostId(postId: number, skip: number = 0, take: number = 5) {
    //getAssignmentPostsForAssignment(assignmentId: number, skip: number = 0, take: number = 20) {
    return this.http.get<PagedOverview<AssignmentPostComment>>(
      `${this.BASE_URL}api/assignments/posts/${postId}/comments?take=${take}&skip=${skip}`
    );
  }

  deleteComments(id: number) {
    return this.http.delete<AssignmentPostComment>(`${this.BASE_URL}api/assignments/posts/comments/${id}`);
  }

  createComments(data: CreateAssignmentPostComment) {
    const formData: FormData = new FormData();

    formData.append('text', data.text);

    return this.http.post<AssignmentPost>(`${this.BASE_URL}api/assignments/posts/${data.postId}/comments`, formData);
  }
}
