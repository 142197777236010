import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromEditionData from '../reducers/edition-data.reducer';
import { isAfter, isBefore, isEqual, parseISO } from 'date-fns';

export const getEditionDataState = createSelector(fromFeature.getEditionState, (state: fromEditionData.EditionDataState) => state);

export const getEditionData = createSelector(getEditionDataState, (state: fromEditionData.EditionDataState) => state.editionData);

export const getEditionDataLoaded = createSelector(getEditionDataState, fromEditionData.getEditionDataLoaded);
export const getEditionDataLoading = createSelector(getEditionDataState, fromEditionData.getEditionDataLoading);

export const getEditionStarted = createSelector(getEditionData, edition => {
  if (!edition?.startDate) {
    return false;
  }
  const current = new Date();
  const startDate = parseISO(edition.startDate.toString());

  if (isAfter(current, startDate) || isEqual(startDate, current)) {
    return true;
  } else {
    return false;
  }
});

export const getEditionEnded = createSelector(getEditionData, edition => {
  if (!edition?.endDate) {
    return false;
  }
  const current = new Date();
  const endDate = parseISO(edition.endDate.toString());

  if (isAfter(endDate, current) || isEqual(endDate, current)) {
    return true;
  } else {
    return false;
  }
});
