import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InViewModule } from '@teamfoster/sdk/in-view';
import { ImageModule } from '@teamfoster/sdk/image';
import { IconModule } from '@teamfoster/sdk/icon';

@Component({
  selector: 'app-avatar',
  standalone: true,
  imports: [CommonModule, InViewModule, ImageModule, IconModule],
  templateUrl: './user-avatar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAvatarComponent {
  @Input() name?: string;
  // @Input() imageUrl?: string | undefined | ArrayBuffer | null;
  @Input() imageUrl?: any;
  @Input() size: string | 'xs' | 'sm' | 'lg' = '';
  @Input() ifUploadObj = false; // needed for upload obj, solution for resize pipe
}
