import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight',
})
export class HighlightPipe implements PipeTransform {
  transform(value: string, brandColor?: string, stripHighlight?: boolean): string {
    // To only remove []
    if (stripHighlight) {
      return (value || '').replace(/\[(.*?)\]/g, `$1`);
    }

    // wrap in span
    return (value || '').replace(/\[(.*?)\]/g, `<span class="${brandColor || 'text-primary'}">$1</span>`);
  }
}
