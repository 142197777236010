import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormFileInputComponent } from './components/form-file-input/form-file-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from '../core/core.module';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { TextUtilityModule } from '@teamfoster/sdk/text-utility';
import { DynamicFormModule } from '@teamfoster/sdk/dynamic-form';
import { HttpClientModule } from '@angular/common/http';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { IconModule } from '@teamfoster/sdk/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormAvatarUploadComponent } from './components/form-avatar-upload/form-avatar-upload.component';
import { UserAvatarComponent } from '../standalone/user-avatar/user-avatar.component';
import { FormRadioButtonsComponent } from './components/form-radio-buttons/form-radio-buttons.component';
import { MatRadioModule } from '@angular/material/radio';
import { FormDateComponent } from './components/form-date/form-date.component';
import { MatNativeDateModule } from '@angular/material/core';
import { FormDateRangeComponent } from './components/form-date-range/form-date-range.component';

// extends dynamic form fields
export const formControls = {
  image: FormFileInputComponent,
  avatar: FormAvatarUploadComponent,
  formButtons: FormRadioButtonsComponent,
  date: FormDateComponent,
  range: FormDateRangeComponent,
};

@NgModule({
  declarations: [FormFileInputComponent, FormAvatarUploadComponent, FormRadioButtonsComponent, FormDateComponent, FormDateRangeComponent],
  imports: [
    CommonModule,
    CoreModule,
    ReactiveFormsModule,
    DictionaryNgrxModule,
    MatInputModule,
    MatFormFieldModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    DynamicFormModule,
    TextUtilityModule,
    HttpClientModule,
    FormsModule,
    IconModule,
    UserAvatarComponent,
  ],
})
export class FormModule {}
