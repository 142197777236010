import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TextUtilityModule } from '@teamfoster/sdk/text-utility';
import { CoreModule } from '../core/core.module';
import { CookieNgrxModule } from '@teamfoster/sdk/cookie-ngrx';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { ContentBlockContainerComponent } from './containers/content-block-container/content-block-container.component';
import { ContentBlockDefaultComponent } from './components/content-block-default/content-block-default.component';
import { ImageModule } from '@teamfoster/sdk/image';
import { ButtonModule } from '@teamfoster/sdk/button';
import { IconModule } from '@teamfoster/sdk/icon';
import { ContentBlockIntroductionComponent } from './components/content-block-introduction/content-block-introduction.component';
import { ContentBlockListComponent } from './components/content-block-list/content-block-list.component';
import { ContentBlockParticipantsComponent } from './components/content-block-participants/content-block-participants.component';
import { InViewModule } from '@teamfoster/sdk/in-view';
import { ContentBlockListItemComponent } from './components/content-block-list-item/content-block-list-item.component';
import { ContentBlockFaqComponent } from './components/content-block-faq/content-block-faq.component';
import { FaqCardComponent } from '../standalone/faq-card/faq-card.component';
import { ContentBlockRegisterComponent } from './components/content-block-register/content-block-register.component';
import { DynamicFormModule } from '@teamfoster/sdk/dynamic-form';
import { UserAvatarComponent } from '../standalone/user-avatar/user-avatar.component';
import { ContentBlockCountdownComponent } from './components/content-block-countdown/content-block-countdown.component';
import { ContentBlockListIconComponent } from './components/content-block-list-icon/content-block-list-icon.component';
import { ContentBlockStatisticsComponent } from './components/content-block-statistics/content-block-statistics.component';

import { CountToModule } from '@teamfoster/sdk/count-to';
import { QuoteCardComponent } from '../standalone/quote-card/quote-card.component';
import { ContentBlockQuotesComponent } from './components/content-block-quotes/content-block-quotes.component';
import { ContentBlockMenuListComponent } from './components/content-block-menu-list/content-block-menu-list.component';
import { MenuNgrxModule } from '@teamfoster/sdk/menu-ngrx';
import { ContentBlockImageGridComponent } from './components/content-block-image-grid/content-block-image-grid.component';
import { ContentBlockWallPostsComponent } from './components/content-block-wall-posts/content-block-wall-posts.component';
import { PostCardComponent } from '../standalone/post-card/post-card.component';
import { ContentBlockPayoffComponent } from './components/content-block-payoff/content-block-payoff.component';
import { PostImageCardComponent } from '../standalone/post-image-card/post-image-card.component';
import { ContentBlockWasteResultInfographicComponent } from './components/content-block-waste-result-infographic/content-block-waste-result-infographic.component';
import { SocialMediaModule } from '../social-media/social-media.module';

export const moduleExports = [ContentBlockContainerComponent];

//import { register } from 'swiper/element/bundle';
//register();

@NgModule({
  declarations: [
    ...moduleExports,
    ContentBlockDefaultComponent,
    ContentBlockIntroductionComponent,
    ContentBlockListComponent,
    ContentBlockParticipantsComponent,
    ContentBlockListItemComponent,
    ContentBlockFaqComponent,
    ContentBlockCountdownComponent,
    ContentBlockRegisterComponent,
    ContentBlockListIconComponent,
    ContentBlockStatisticsComponent,
    ContentBlockQuotesComponent,
    ContentBlockMenuListComponent,
    ContentBlockImageGridComponent,
    ContentBlockPayoffComponent,
    ContentBlockWallPostsComponent,
    ContentBlockWasteResultInfographicComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    TextUtilityModule,
    CoreModule,
    CookieNgrxModule,
    DictionaryNgrxModule,
    ImageModule,
    InViewModule,
    ButtonModule,
    IconModule,
    ImageModule,
    MenuNgrxModule,
    FaqCardComponent,
    DynamicFormModule,
    UserAvatarComponent,
    CountToModule,
    QuoteCardComponent,
    PostCardComponent,
    PostImageCardComponent,
    SocialMediaModule,
  ],
  exports: [...moduleExports],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ContentBlocksModule {}
