import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CookiePreferences } from '@teamfoster/sdk/cookie-ngrx';
import { ContentBlock } from '../../models';
import { Image } from '@teamfoster/sdk/image';

@Component({
  selector: 'app-content-block-participants',
  templateUrl: './content-block-participants.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockParticipantsComponent {
  @Input({ required: true }) block!: ContentBlock;
  @Input() cookiePreferences!: CookiePreferences;
  @Input() index!: number;
}
