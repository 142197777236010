<section class="section-outset-y auth-layout">
  <main class="container container-size-xxs auth-layout__main">
    <div class="auth-layout__inner">
      <header class="mb-5 text-center">
        <fstr-icon anchor="logo-100-100-100" class="my-3 m-sm-4 mb-3 auth-layout__logo text-primary w-100"></fstr-icon>
        <h2 class="h3 text-center text-secondary">
          {{ title }}
        </h2>
        <ng-content select="[header]"></ng-content>
      </header>
      <article class="card">
        <main>
          <ng-content select="[form]"></ng-content>
        </main>

        <footer #footer [class.mt-3]="footer?.children?.length">
          <ng-content select="[footer]"></ng-content>
        </footer>
      </article>
    </div>
  </main>
</section>
