import { createReducer, on } from '@ngrx/store';
import { Statistic } from '../../models';
import * as pageActions from '../actions/statistics.action';
import { ToEntities } from '@teamfoster/sdk/array-utility';

export interface StatisticsState {
  entities: { [id: string]: Statistic };
  loaded: boolean;
  loading: boolean;
  // overviewLoaded: boolean;
  // overviewLoading: boolean;
  error?: any;
  saving: boolean;
  saved: boolean;
}

export const initialState: StatisticsState = {
  entities: {},
  loaded: false,
  loading: false,
  // overviewLoading: false,
  // overviewLoaded: false,
  saved: false,
  saving: false,
};

export const StatisticsReducer = createReducer(
  initialState,
  on(pageActions.LoadStatistics, (state: StatisticsState) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(pageActions.LoadStatisticsSuccess, (state: StatisticsState, { Statistics }) => {
    const entities = ToEntities(Statistics, 'id', state.entities);
    return {
      ...state,
      entities,
      loading: false,
      loaded: true,
    };
  }),
  on(pageActions.LoadStatisticsFail, (state: StatisticsState, { error }) => {
    return {
      ...state,
      loading: false,
      loaded: false,
      error,
    };
  }),
  on(pageActions.CreateStatistic, (state: StatisticsState) => {
    return {
      ...state,
      saved: false,
      saving: true,
    };
  }),
  on(pageActions.CreateStatisticSuccess, (state: StatisticsState, { statistic }) => {
    const entities = {
      ...state.entities,
      [statistic.id]: statistic,
    };
    return {
      ...state,
      entities,
      saved: true,
      saving: false,
    };
  }),
  on(pageActions.ClearStatistics, (state: StatisticsState) => {
    return {
      ...initialState,
    };
  })
);

export const getStatisticsEntities = (state: StatisticsState) => state.entities;

export const getStatisticsLoading = (state: StatisticsState) => state.loading;
export const getStatisticsLoaded = (state: StatisticsState) => state.loaded;

export const getStatisticsSaved = (state: StatisticsState) => state.saved;
export const getStatisticsSaving = (state: StatisticsState) => state.saving;
