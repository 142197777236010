import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ContentBlock } from '../../models';
import { ListStatistics } from '../../models/ListStatistics.model';

@Component({
  selector: 'app-content-block-waste-result-infographic',
  templateUrl: './content-block-waste-result-infographic.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockWasteResultInfographicComponent {
  @Input() block!: ContentBlock;
  inviewPort: boolean = false;

  getPercentageOfTotal(num: number) {
    const heighest = Math.max(...this.block.listStatistics!.map(o => o.quantity));
    return num / heighest;
  }

  trackByFn(index: number, stat: ListStatistics) {
    return stat.id;
  }
}
