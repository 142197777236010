<article class="flip-card post-image-card">
  <div class="flip-card__inner">
    <div class="flip-card__front">
      <div class="post-image-card__front">
        <fstr-image class="post-image-card__front__image" [image]="post.image" [imageSizes]="[600, 900, 1200]"></fstr-image>

        <div class="post-image-card__front__author">
          <app-avatar [size]="'sm'" [imageUrl]="post.author.avatar" [name]="post.author.fullName"></app-avatar>
          <div class="d-flex flex-column">
            <h2 class="mb-0 fs-body-md text-white">
              {{ post.author.firstName }}
            </h2>
          </div>
        </div>
      </div>
    </div>

    <div class="flip-card__back post-image-card__back">
      <app-avatar class="mb-2 mb-md-4 d-block" [size]="'md'" [imageUrl]="post.author.avatar" [name]="post.author.fullName"></app-avatar>
      <h2 class="h6 text-tertiary">{{ post.author.fullName }}</h2>
      <div
        class="post-image-card__back__text markdown-content simple-markdown text-max-width-xs"
        [innerHTML]="post.text | truncate : 200"
      ></div>
    </div>
  </div>
</article>
