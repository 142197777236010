<app-auth-layout [title]="prefix + '-titel' | fromDictionary">
  <fstr-dynamic-form
    form
    class="theme-light"
    [appearance]="'fill'"
    [config]="formFields"
    (formSubmit)="handleSubmit($event)"
    [showSubmit]="false"
  >
    <app-alert
      slot="form-alert"
      *ngIf="error$() as error"
      [status]="'danger'"
      [message]="error.error?.error || (getErrorMessage(error?.error) | fromDictionary)"
      class="d-block mb-3"
    ></app-alert>

    <div slot="form-actions">
      <button type="submit" class="button button--primary" [disabled]="signingIn$()">
        <span>{{ prefix + '-formulier-verzenden-knop-tekst' | fromDictionary }}</span>
      </button>
    </div>
  </fstr-dynamic-form>

  <ng-container footer>
    <div class="type-body-xs markdown-content d-flex flex-wrap flex-gap-2 align-items-center">
      <span class="d-inline-block">{{ prefix + '-registreren-prefix-knop' | fromDictionary }}</span>
      <div class="d-inline-block">
        <span class="d-inline-block">
          <a class="button button-link button--tertiary" [routerLink]="['/', 'account', 'registreren']">
            {{ prefix + '-registreren-knop-tekst' | fromDictionary }}
          </a>
        </span>
        |
        <a class="button button-link button--tertiary" [routerLink]="['/', 'account', 'wachtwoord-vergeten']">
          {{ prefix + '-wachtwoord-vergeten-knop-tekst' | fromDictionary }}
        </a>
      </div>
    </div>
  </ng-container>
</app-auth-layout>
