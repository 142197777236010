import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ContentBlock } from '../../models';

@Component({
  selector: 'app-content-block-statistics',
  templateUrl: './content-block-statistics.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockStatisticsComponent {
  @Input() block!: ContentBlock;

  inviewPort: boolean = false;
}
