<ng-container [formGroup]="group" *ngIf="group.get(config.name) as field">
  <mat-form-field appearance="fill" *ngIf="range" [formGroup]="range">
    <mat-label *ngIf="config.label">{{ config.label }}</mat-label>
    <mat-date-range-input [rangePicker]="picker" [required]="isRequired(field)">
      <input matStartDate formControlName="start" [placeholder]="config['startdatePlaceholder']" />
      <input matEndDate formControlName="end" [placeholder]="config['enddatePlaceholder']" />
    </mat-date-range-input>
    <mat-hint *ngIf="config.hint">{{ config.hint }}</mat-hint>

    <!--<mat-error *ngIf="range && range.controls['start'].hasError('matStartDateInvalid')">Invalid start date</mat-error>
    <mat-error *ngIf="range && range.controls['end'].hasError('matEndDateInvalid')">Invalid end date</mat-error>-->

    <mat-error *ngIf="showError && range?.get('start')">
      {{ range.get('start')! | formError : config.customValidationMessages }}
    </mat-error>

    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>
</ng-container>
