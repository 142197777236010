import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  PlatformRef,
  ViewChild,
  computed,
  effect,
  inject,
  signal,
} from '@angular/core';
import { Store } from '@ngrx/store';
import {
  CreateStatistic,
  getOrderedStatistics,
  getStatisticsSaved,
  getStatisticsSaving,
  getWeeklyStatisticsByUser,
  getWeeklyStatisticsPlatform,
} from '../../store';
import { getAuthUser } from 'src/app/auth/store';
import { FormComponent } from '@teamfoster/sdk/dynamic-form';
import { StatisticFormComponent } from '../../components/statistic-form/statistic-form.component';
import { trigger } from '@angular/animations';
import { FadeInAnimation } from 'src/app/animations';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexMarkers,
  ApexOptions,
  ApexResponsive,
  ApexStroke,
  ApexTitleSubtitle,
  ApexXAxis,
  ApexYAxis,
  ChartComponent,
} from 'ng-apexcharts';
import { StatisticAverageWeek } from '../../models';
import { SetTags, SetTitle, getEditionData, getEditionStarted } from 'src/app/store';
import { editionData } from 'src/app/core/models';
import { FromDictionaryPipe, LanguageService } from '@teamfoster/sdk/dictionary-ngrx';
import {
  addDays,
  addWeeks,
  differenceInCalendarWeeks,
  differenceInWeeks,
  endOfDay,
  endOfWeek,
  getISOWeek,
  startOfDay,
  startOfWeek,
} from 'date-fns';
import { asapScheduler } from 'rxjs';
import { StringUtility } from '@teamfoster/sdk/text-utility';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  title: ApexTitleSubtitle;
  stroke: ApexStroke;
  markers: ApexMarkers;
};

@Component({
  selector: 'app-statistic-overview',
  templateUrl: './statistic-overview.component.html',
  animations: [trigger('FadeInAnimation', [FadeInAnimation])],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatisticOverviewComponent {
  private store = inject(Store);
  private cd = inject(ChangeDetectorRef);
  private lang = inject(LanguageService);
  private dict = new FromDictionaryPipe(this.lang);
  public chartOptions!: Partial<ChartOptions>;

  @ViewChild('form') formEl?: StatisticFormComponent;
  @ViewChild('chart') chart!: ChartComponent;

  statistics$ = this.store.selectSignal(getOrderedStatistics);
  statsPlatform$: any = this.store.selectSignal(getWeeklyStatisticsPlatform);
  platform$ = this.store.selectSignal(getEditionData);
  edition$ = this.store.selectSignal(getEditionData);
  editionStarted$ = this.store.selectSignal(getEditionStarted);

  usr$ = this.store.selectSignal(getAuthUser);
  saving$ = this.store.selectSignal(getStatisticsSaving);
  saved$ = this.store.selectSignal(getStatisticsSaved);
  getDataUser$!: any;

  openSucces = false;

  updateForm = effect(() => {
    const saved = this.saved$();

    if (saved) {
      // this.openComments = true;
      this.formEl?.form?.form.reset();
      this.openSucces = true;
      // this.formEl?.form?.form.updateValueAndValidity();
      setTimeout(() => {
        this.openSucces = false;
        this.cd.detectChanges();
      }, 3000);
    }
  });

  getDataUsesr$ = effect(() => {
    const usr = this.usr$();

    if (!usr) {
      return;
    }

    const userAvarageData = getWeeklyStatisticsByUser(usr.id);
    this.getDataUser$ = this.store.selectSignal(userAvarageData);
  });

  metafields = effect(() => {
    const edition = this.edition$();

    asapScheduler.schedule(() =>
      this.store.dispatch(
        SetTags({
          properties: {
            'description': StringUtility.Truncate(StringUtility.StripHTML(this.dict.transform('statistieken-pagina-intro')), 157, '...'),
            'og:title': `${this.dict.transform('statistieken-pagina-titel')} ${this.dict.transform('meta-titel-suffix')}`,
            'og:description': StringUtility.Truncate(StringUtility.StripHTML(this.dict.transform('statistieken-pagina-intro')), 157, '...'),
          },
        })
      )
    );

    asapScheduler.schedule(() => {
      this.store.dispatch(
        SetTitle({ title: `${this.dict.transform('statistieken-pagina-titel')} - ${this.dict.transform('meta-titel-suffix')}` })
      );
    });
  });

  setGraph$ = effect(() => {
    const dataUsr: StatisticAverageWeek[] = this.getDataUser$();
    const usr = this.usr$();
    const dataPlatform: StatisticAverageWeek[] = this.statsPlatform$();
    const platform = this.platform$();
    const weekAvarage = platform?.countryAverage ? platform?.countryAverage : 3.3; // platform?.countryAverage

    let dataNational: { week: number; value: number; startDate: any }[] = [];

    if (platform && weekAvarage && this.editionStarted$()) {
      let currentDate = new Date();
      const startDate = new Date(platform.startDate);
      const endDate = new Date(platform.endDate);
      const weeksDifference = differenceInWeeks(endOfWeek(endDate), startDate);
      currentDate = endOfWeek(currentDate);

      dataNational = Array.from({ length: weeksDifference }, (_, weekIndex) => {
        const weekStartDate = addWeeks(startDate, weekIndex);

        // Controleer of de week eindigt voor de einddatum van het platform
        return {
          startDate: weekStartDate,
          week: getISOWeek(weekStartDate),
          value: weekAvarage,
        };
      });
    }

    if (dataNational && this.editionStarted$()) {
      this.chartOptions = {
        series: [
          {
            name: `${this.dict.transform('landelijk-gemiddelde-label')}`,
            data: [...dataNational.map(i => [new Date(i.startDate).getTime(), +i.value])],
            type: 'line',
            color: this.dict.transform('statistiek-kleur-landelijk'),
          },
          {
            name: `${this.dict.transform('platform-name')}`,
            type: 'area',
            data: [...dataPlatform.map(item => [new Date(item.startDate).getTime(), +item.averageValue])],
            color: this.dict.transform('statistiek-kleur-platform'),
          },
        ],
        chart: {
          height: 400,
          type: 'line',
          stacked: false,
          toolbar: {
            show: false,
          },
          selection: {
            enabled: false,
          },
          zoom: {
            enabled: false,
          },
        },
        stroke: {
          curve: 'smooth',
          width: 3,
        },
        markers: {
          size: 5,
          hover: {
            size: 7,
          },
        },
        title: {
          text: '',
        },
        xaxis: {
          type: 'datetime',
          //min: new Date(dataNational[0].startDate).getTime(), // Werkt niet lekker, maar weet ook ff geen vervanging
          tickAmount: dataNational.length - 1,
          labels: {
            show: true,
            formatter: value => {
              return `${this.dict.transform('statistieken-xas-label')} ${
                differenceInCalendarWeeks(new Date(value), new Date(dataNational[0].startDate)) + 1
              }`;
            },
          },
        },
        yaxis: {
          min: 0,
          max:
            Math.ceil(
              Math.max(
                weekAvarage,
                Math.max(...dataUsr.map(o => +(o.totalValue || 0))),
                Math.max(...dataPlatform.map(o => +o.averageValue))
              ) / 5
            ) * 5,
          labels: {
            show: true,
            formatter: value => {
              return `${value.toFixed(1)} ${this.dict.transform('statistieken-unit-label')}`;
            },
          },
        },
      };

      if (dataUsr) {
        // console.log([...dataUsr.map(item => [new Date(item.startDate).getTime(), +(item.totalValue || 0)])]);
        this.chartOptions.series?.push({
          name: usr?.firstName,
          type: 'area',
          data: [...dataUsr.map(item => [new Date(item.startDate).getTime(), +(item.totalValue || 0)])],
          color: this.dict.transform('statistiek-kleur-gebruiker'),
        });
      }
    }
  });

  getLabel(value: any) {
    return value + ' kg';
  }

  formSubmit($e: any) {
    if ($e) {
      const data = {
        value: $e.value,
        observedOn: $e.observedOn.date.toISOString(),
      };
      this.store.dispatch(CreateStatistic({ statistic: data }));
      //
    }
  }

  // formatDataForApexCharts(data: StatisticAverageWeek[]) {
  //   const formattedData = data.map(week => {
  //     return {
  //       name: `Week ${week.week}`,
  //       data: [
  //         {
  //           x: week.startDate,
  //           y: parseFloat(week.averageValue),
  //         },
  //       ],
  //     };
  //   });

  //   return formattedData;
  // }
}
