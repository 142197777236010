import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconModule } from '@teamfoster/sdk/icon';
import { ImageModule } from '@teamfoster/sdk/image';
import { TextUtilityModule } from '@teamfoster/sdk/text-utility';
import { AssignmentPost } from 'src/app/assignments/models';
import { CommentsListComponent } from '../comments-list/comments-list.component';
import { UserAvatarComponent } from '../user-avatar/user-avatar.component';

@Component({
  selector: 'app-post-image-card',
  templateUrl: './post-image-card.component.html',
  standalone: true,
  imports: [CommonModule, ImageModule, UserAvatarComponent, IconModule, CommentsListComponent, TextUtilityModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostImageCardComponent {
  @Input({ required: true }) post!: AssignmentPost;
}
