import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as authActions from '../actions/auth.action';
import { switchMap, map, catchError, tap, take } from 'rxjs/operators';
import { AuthService } from '../../services';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../store/index';
import { getRouterState, Go } from '../../../store/index';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private store: Store,
    private router: Router,
    private sb: MatSnackBar
  ) {}

  checkAuth$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.CheckAuth),
      switchMap(() =>
        this.authService.checkAuth().pipe(
          map(auth => authActions.CheckAuthSuccess({ auth })),
          catchError(error => of(authActions.CheckAuthFail({ error })))
        )
      )
    );
  });

  signIn$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.SignIn),
      switchMap(({ loginData }) =>
        this.authService.signIn(loginData).pipe(
          map(auth => authActions.SignInSuccess({ auth })),
          catchError(error => of(authActions.SignInFail({ error })))
        )
      )
    );
  });

  signInSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.SignInSuccess),
      tap(({ auth }) => {
        this.sb.open(`Ingelogd als ${auth?.user?.fullName}`, '', { duration: 5000 });
      }),
      switchMap(() =>
        this.store
          .select(getRouterState)
          .pipe(take(1))
          .pipe(
            map(({ state }) => {
              return Go({ path: [state.queryParams['returnUrl'] || '/'] });
            })
          )
      )
    );
  });

  signOut$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.SignOut),
      switchMap(() =>
        this.authService.SignOut().pipe(
          map(auth => authActions.SignOutSuccess({ result: auth })),
          catchError(error => of(authActions.SignOutFail({ error })))
        )
      )
    );
  });

  signOutSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(authActions.SignOutSuccess),
        tap(() => (window.location.href = '/account/inloggen'))
      );
    },
    { dispatch: false }
  );

  register$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.Register),
      switchMap(({ registerData }) =>
        this.authService.register(registerData).pipe(
          map(result => authActions.RegisterSuccess({ result: result })),
          catchError(error => of(authActions.RegisterFail({ error })))
        )
      )
    );
  });

  change$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.Change),
      switchMap(({ changeData }) =>
        this.authService.change(changeData).pipe(
          map(auth => authActions.ChangeSuccess({ auth: auth })),
          catchError(error => of(authActions.ChangeFail({ error })))
        )
      )
    );
  });

  updateChangeSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(authActions.ChangeSuccess),
        tap(({ auth }) => {
          this.sb.open(`Uw profiel is geupdated`, '', { duration: 5000, panelClass: 'bg-success' });
        })
      );
    },
    { dispatch: false }
  );

  confirmAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.ActivateAccount),
      switchMap(({ token, uid }) =>
        this.authService.confirmAccount(token, uid).pipe(
          map(auth => authActions.ActivateAccountSuccess({ result: auth })),
          catchError(error => of(authActions.ActivateAccountFail({ error })))
        )
      )
    );
  });

  updatePassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.UpdatePassword),
      switchMap(({ passwordData }) =>
        this.authService.updatePassword(passwordData).pipe(
          map(result => authActions.UpdatePasswordSuccess({ result })),
          catchError(error => of(authActions.UpdatePasswordFail({ error })))
        )
      )
    );
  });

  updatePasswordSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(authActions.UpdatePasswordSuccess),
        tap(({ result }) => {
          this.sb.open(`Wachtwoord geüpdatet!`, '', { duration: 5000, panelClass: 'bg-success' });
        })
      );
    },
    { dispatch: false }
  );
}
