import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  computed,
  effect,
  inject,
  signal,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { FromDictionaryPipe, LanguageService } from '@teamfoster/sdk/dictionary-ngrx';
import { DynamicFormField, FormComponent } from '@teamfoster/sdk/dynamic-form';
import { Profile } from 'src/app/auth/models';
import { getStatisticsSaved, getStatisticsSaving } from '../../store';
import { editionData } from 'src/app/core/models';

@Component({
  selector: 'app-statistic-form',
  templateUrl: './statistic-form.component.html',

  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatisticFormComponent implements OnInit {
  private store = inject(Store);
  private lang = inject(LanguageService);
  private dict = new FromDictionaryPipe(this.lang);

  @Input() usr!: Profile | undefined;
  @Input() edition?: editionData;
  @Output() formValue = new EventEmitter<FormData>();
  @ViewChild('form') form?: FormComponent;

  saving$ = this.store.selectSignal(getStatisticsSaving);
  saved$ = this.store.selectSignal(getStatisticsSaved);

  prefix = 'bericht-formulier';

  formFieldsData!: DynamicFormField[];

  ngOnInit(): void {
    this.formFieldsData = [
      {
        inputType: 'number',
        label: this.dict.transform('statistiek-formulier-aantal-label'),
        name: 'value',
        hint: this.dict.transform('statistiek-formulier-aantal-hint'),
        validators: [Validators.required],
        fieldType: 'input',
        order: 1,
        cssClass: '',
      },
      {
        inputType: 'date',
        hint: this.dict.transform('statistiek-formulier-datum-hint'),
        label: this.dict.transform('statistiek-formulier-datum-label'),
        name: 'observedOn',
        validators: [Validators.required],
        placeholder: 'dd-m-yyyy',
        fieldType: 'date',
        order: 2,
        cssClass: '',
        maxDate: new Date(),
        minDate: this.edition?.startDate,
      },
    ];
  }

  // formAdd = effect(() => {
  //   if (this.usr) {
  //     this.formFieldsData.push({
  //       inputType: 'number',
  //       label: '',
  //       name: 'UserId',
  //       validators: [Validators.required],
  //       fieldType: 'hidden',
  //       value: this.usr.id,
  //       subscriptSizing: 'dynamic',
  //       cssClass: 'px-3 my-3',
  //     });
  //   }
  // });
}
