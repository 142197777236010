<section>
  <ul class="content-block-list" *ngIf="cookiePreferences$() as cookies">
    <li
      class="content-block-list__item"
      *ngFor="let block of blocks; trackBy: trackByFn; let i = index"
      [ngSwitch]="block.blockType"
      [ngClass]="'type--' + block.blockType"
      [id]="'blok-' + i"
    >
      <!-- <app-content-block-default [index]="i" [block]="block" *ngSwitchCase="'block-image'"></app-content-block-default> -->
      <!--<app-content-block-waste-result-infographic [index]="i" [block]="block"></app-content-block-waste-result-infographic>-->

      <app-content-block-register
        [index]="i"
        [block]="block"
        [cookiePreferences]="cookies"
        *ngSwitchCase="'block-cta-register'"
      ></app-content-block-register>

      <app-content-block-introduction
        [index]="i"
        [block]="block"
        [cookiePreferences]="cookies"
        *ngSwitchCase="'block-introduction'"
      ></app-content-block-introduction>

      <app-content-block-faq [index]="i" [block]="block" [cookiePreferences]="cookies" *ngSwitchCase="'block-faq'"></app-content-block-faq>

      <app-content-block-participants
        [index]="i"
        [block]="block"
        [cookiePreferences]="cookies"
        *ngSwitchCase="'block-participants'"
      ></app-content-block-participants>

      <app-content-block-list [index]="i" [block]="block" *ngSwitchCase="'block-list'"></app-content-block-list>

      <app-content-block-list-icon [index]="i" [block]="block" *ngSwitchCase="'block-list-icon'"></app-content-block-list-icon>

      <app-content-block-countdown [index]="i" [block]="block" *ngSwitchCase="'block-countdown'"></app-content-block-countdown>

      <app-content-block-statistics [index]="i" [block]="block" *ngSwitchCase="'block-stats'"></app-content-block-statistics>

      <app-content-block-waste-result-infographic
        [index]="i"
        [block]="block"
        *ngSwitchCase="'block-result-infographic'"
      ></app-content-block-waste-result-infographic>

      <app-content-block-quotes [block]="block" *ngSwitchCase="'block-quotes'"></app-content-block-quotes>

      <app-content-block-image-grid [block]="block" *ngSwitchCase="'block-image-grid'"></app-content-block-image-grid>

      <app-content-block-menu-list
        [menu]="('inspiration-list' | fromMenu)?.error ? [] : ('inspiration-list' | fromMenu)"
        [block]="block"
        *ngSwitchCase="'block-menu-list'"
      ></app-content-block-menu-list>

      <app-content-block-wall-posts [block]="block" *ngSwitchCase="'block-wall-posts'"></app-content-block-wall-posts>

      <app-content-block-payoff
        [index]="i"
        [block]="block"
        [cookiePreferences]="cookies"
        [socialChannels]="socialChannels$()"
        *ngSwitchCase="'block-payoff'"
      ></app-content-block-payoff>

      <app-content-block-default
        [index]="i"
        [block]="block"
        [cookiePreferences]="cookies"
        *ngSwitchCase="'block-image'"
      ></app-content-block-default>
    </li>
  </ul>
</section>
