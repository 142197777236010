import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InViewModule } from '@teamfoster/sdk/in-view';
import { IconModule } from '@teamfoster/sdk/icon';

import { UserAvatarComponent } from '../user-avatar/user-avatar.component';
import { Quote } from 'src/app/content-blocks/models/quote.model';

@Component({
  selector: 'app-quote-card',
  standalone: true,
  imports: [CommonModule, InViewModule, IconModule, UserAvatarComponent],
  templateUrl: './quote-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuoteCardComponent {
  @Input() quote!: Quote;
}
