<section class="content-block-list-icon section-outset-y">
  <div class="container">
    <header class="mb-md-5">
      <h2 class="h4 content-block-list__title title-squiggle text-primary">
        {{ block.title }}
      </h2>
    </header>
    <main>
      <ul *ngIf="block.assignmentRules as list" class="content-block-list-icon__list d-md-flex flex-gap-3">
        <li class="block-list__item mb-4 md-mb-0" *ngFor="let item of list; let i = index">
          <article class="content-block-list-icon__card d-flex flex-gap-3">
            <fstr-icon class="chip chip--primary-100 chip--lg" [anchor]="item.icon || 'icon-check'"></fstr-icon>
            <div class="d-inline-block markdown-content" [innerHTML]="item.text">
              {{ item.text }}
            </div>
          </article>
        </li>
      </ul>
    </main>
  </div>
</section>
