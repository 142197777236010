import { createAction, props } from '@ngrx/store';
import { Assignment } from '../../models';

export const LoadAssignments = createAction('[Assignments] Load Assignments');
export const LoadAssignmentsSuccess = createAction('[Assignments] Load Assignments Success', props<{ assignments: Assignment[] }>());
export const LoadAssignmentsFail = createAction('[Assignments] Load Assignments Fail', props<{ error: any }>());

export const LoadCurrentAssignment = createAction('[Assignments] Load Current Assignment');
export const LoadCurrentAssignmentFail = createAction('[Assignments] Load Current Assignments Fail', props<{ error: any }>());
export const LoadCurrentAssignmentSuccess = createAction(
  '[Assignments] Load Current Assignment Success',
  props<{ assignment: Assignment }>()
);

export const LoadAssignmentById = createAction('[Assignments] Load Assignment by Id', props<{ id: number }>());
export const LoadAssignmentByIdSuccess = createAction('[Assignments] Load Assignment by Id Success', props<{ assignment: Assignment }>());
export const LoadAssignmentByIdFail = createAction('[Assignments] Load Assignment by Id Fail', props<{ error: any; id: number }>());

export const ClearAssignments = createAction('[Assignments] Clear Assignments');
