import { createReducer, on } from '@ngrx/store';
import { AssignmentPostComment } from '../../models';
import * as pageActions from '../actions/comment.action';
import { ToEntities } from '@teamfoster/sdk/array-utility';
import { PageData } from 'src/app/core/models/page-data.model';

export interface CommentsState {
  entities: { [id: string]: AssignmentPostComment };
  loaded: boolean;
  loading: boolean;
  error?: any;
  // page: PageData;
  saving: boolean;
  saved: boolean;
  loadingFor?: number;
  loadedFor: { id: number; page: PageData }[];
}

export const initialState: CommentsState = {
  entities: {},
  loaded: false,
  loading: false,
  // page: { currentPage: 0, pageSize: 5, pageCount: 0, resultCount: 0, filters: [] },
  saved: false,
  saving: false,
  loadedFor: [],
  loadingFor: undefined,
};

export const CommentsReducer = createReducer(
  initialState,
  on(pageActions.LoadCommentsById, (state: CommentsState, { id }) => {
    return {
      ...state,
      // page: initialState.page,
      loading: true,
      loadingFor: +id,
    };
  }),
  on(pageActions.LoadCommentsByIdSuccess, (state: CommentsState, { comments, id }) => {
    const entities = ToEntities(comments.items, 'id', state.entities);
    const loadedFor = state.loadedFor ? [...state.loadedFor] : [];

    // if (id) {
    const getPageIndex = loadedFor.findIndex(p => p.id == id);

    if (getPageIndex !== -1) {
      const updatedPage = { ...loadedFor[getPageIndex].page, ...comments.page };
      const updatedItem = { ...loadedFor[getPageIndex], page: updatedPage };
      loadedFor[getPageIndex] = updatedItem;
      // console.log(updatedPage);
    } else {
      loadedFor.push({ id, page: { ...comments.page } });
    }
    //}

    return {
      ...state,
      entities,
      // page: comments.page,
      loading: false,
      loaded: true,
      loadingFor: undefined,
      loadedFor,
    };
  }),
  on(pageActions.LoadCommentsByIdFail, (state: CommentsState, { error, id }) => {
    return {
      ...state,
      loading: false,
      loaded: false,
      loadingFor: undefined,
      loadedFor: [],
      error,
    };
  }),
  on(pageActions.DeleteCommentSuccess, (state: CommentsState, { comment }) => {
    //const { [comment.id]: removed, ...entities } = state.entities;

    const entities = state.entities;
    const filteredEntities: { [key: string]: AssignmentPostComment } = {};

    for (const key in entities) {
      if (entities[key].postId !== comment.postId) {
        filteredEntities[key] = entities[key];
      }
    }

    // const updatedLoadedFor = state.loadedFor.map(item => {
    //   if (item.id === comment.postId) {
    //     // Bepaal de nieuwe resultCount

    //     const updatedResultCount =
    //       state.loadedFor.filter(p => p.id == comment.postId).reduce((count, p) => count + p.page.resultCount, 0) - 1;

    //     // Bepaal de nieuwe currentPage op basis van pageSize en updatedResultCount
    //     const pageSize = item.page.pageSize;
    //     const pageCount = Math.floor(updatedResultCount / pageSize);

    //     // Werk de PageData bij met de nieuwe resultCount en currentPage
    //     return {
    //       ...item,
    //       page: {
    //         ...item.page,
    //         resultCount: updatedResultCount,
    //         currentPage: 0,
    //         pageCount: pageCount,
    //       },
    //     };
    //   }
    //   return item;
    // });

    return {
      ...state,
      // page: updatedPage,
      // loadingFor: initialState.loadingFor,
      // entities,
      // loadedFor: updatedLoadedFor,
      entities: filteredEntities,
      loadedFor: initialState.loadedFor,
    };
  }),
  on(pageActions.CreateComment, (state: CommentsState) => {
    return {
      ...state,
      loadingFor: initialState.loadingFor,
      loadedFor: initialState.loadedFor,
      saved: false,
      saving: true,
    };
  }),
  on(pageActions.CreateCommentSuccess, (state: CommentsState, { comment }) => {
    const entities = {
      ...state.entities,
      [comment.id]: comment,
    };

    // const updatedLoadedFor = state.loadedFor.map(item => {
    //   if (item.id === comment.postId) {
    //     // Bepaal de nieuwe resultCount

    //     const updatedResultCount =
    //       state.loadedFor.filter(p => p.id == comment.postId).reduce((count, p) => count + p.page.resultCount, 0) + 1;

    //     // Bepaal de nieuwe currentPage op basis van pageSize en updatedResultCount
    //     const pageSize = item.page.pageSize;
    //     const pageCount = Math.floor(updatedResultCount / pageSize);

    //     // console.log(updatedResultCount, pageCount, pageSize, item);
    //     // Werk de PageData bij met de nieuwe resultCount en currentPage
    //     return {
    //       ...item,
    //       page: {
    //         ...item.page,
    //         resultCount: updatedResultCount,
    //         currentPage: 0,
    //         pageCount: pageCount,
    //       },
    //     };
    //   }
    //   return item;
    // });

    return {
      ...state,
      entities,
      //   loadedFor: updatedLoadedFor,
      saved: true,
      saving: false,
    };
  }),
  on(pageActions.ClearComments, (state: CommentsState) => {
    return {
      ...initialState,
    };
  })
);

export const getCommentsPostEntities = (state: CommentsState) => state.entities;
export const getCommentsPostLoading = (state: CommentsState) => state.loading;
export const getCommentsPostLoaded = (state: CommentsState) => state.loaded;
export const getCommentsLoadingFor = (state: CommentsState) => state.loadingFor;
export const getCommentsLoadedFor = (state: CommentsState) => state.loadedFor;
// export const getCommentsPostPage = (state: CommentsState) => state.page;
export const getCommentsPostSaved = (state: CommentsState) => state.saved;
export const getCommentsPostSaving = (state: CommentsState) => state.saving;
