import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IconModule } from '@teamfoster/sdk/icon';
import { formatDistance } from 'date-fns';
import { nl } from 'date-fns/locale';
import { AssignmentPostComment } from 'src/app/assignments/models';
import { Profile } from 'src/app/auth/models';
import { UserAvatarComponent } from '../user-avatar/user-avatar.component';

@Component({
  selector: 'app-comment-card',
  templateUrl: './comment-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, IconModule, UserAvatarComponent],
})
export class CommentCardComponent {
  @Input() comment!: AssignmentPostComment;
  @Input() usr!: Profile | undefined | null;
  @Input() removeOption = false;

  @Output() remove = new EventEmitter<AssignmentPostComment>();

  get relativeDate() {
    const relDate = formatDistance(new Date(this.comment.postedOn), new Date(), { addSuffix: true, locale: nl });
    return relDate;
  }
}
