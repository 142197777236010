<div
  class="topbar"
  [ngClass]="edition.name | urlRewrite"
  [class.set--fixed]="fixed$()"
  [class.is--signed-in]="isSignedIn"
  [class.open--menu]="menuActive"
>
  <nav class="topbar__upper">
    <div class="container">
      <ul class="topbar__nav-list">
        <ng-container *ngIf="!isSignedIn">
          <li class="topbar__nav-list__item" *ngFor="let m of topMenu">
            <a class="button button-link" [routerLink]="m.routerLink" [routerLinkActive]="'is--current'">
              <span>{{ m.title }}</span>
            </a>
          </li>
          <li class="topbar__nav-list__item">
            <a class="button button-link" [routerLink]="['/account/inloggen']" [routerLinkActive]="'is--current'">
              <span>Inloggen</span>
            </a>
          </li>
        </ng-container>

        <ng-container *ngIf="isSignedIn">
          <li class="topbar__nav-list__item">
            <a
              class="py-3 py-md-4 button button-link"
              [routerLink]="['/account/mijn-account']"
              *ngIf="usr"
              [routerLinkActive]="'is--current'"
            >
              <span class="me-2">{{ usr.fullName }}</span>
              <app-avatar [size]="'xs'" [imageUrl]="usr.avatar" [name]="usr.fullName"></app-avatar>
            </a>
          </li>

          <li class="topbar__nav-list__item">
            <button class="button button-link" (click)="signOut()">Uitloggen</button>
          </li>
        </ng-container>
        <!-- <li class="topbar__nav-list__item" *ngIf="isSignedIn">
          <a class="button button-link" [routerLink]="'/berichten'" [routerLinkActive]="'is--current'">
            <span>Berichten</span>
            <fstr-icon anchor="icon-chevron-down"></fstr-icon>
          </a>
        </li> -->
      </ul>
    </div>
  </nav>

  <nav class="topbar__main">
    <div class="container">
      <h2 class="mb-0 topbar__edition-logo">
        <a [routerLink]="'/'">
          <div class="topbar__logo__title sr-only">{{ edition.name }}</div>
          <img [src]="edition.logo.url" [alt]="edition.name" />
        </a>
      </h2>

      <ul class="topbar__nav-list main--nav" *ngIf="!isSignedIn">
        <li class="topbar__nav-list__item" *ngFor="let page of menu; let i = index" [style.--list-item-index]="i">
          <fstr-link
            (click)="closeOverlay.emit()"
            cssClasses="button button-link"
            [routerLinkActiveOptions]="{ exact: true }"
            [routerLink]="page.url"
            [routerLinkActive]="'is--current'"
            [button]="{ url: page.url || '', routerLink: page.routerLink, text: page.title }"
          ></fstr-link>
        </li>
      </ul>

      <ng-container *ngIf="isSignedIn">
        <div class="" [class.topbar--bottom]="!fixed$()">
          <div class="container">
            <ul class="topbar__nav-list main--nav">
              <li class="topbar__nav-list__item" *ngFor="let page of menu; let i = index" [style.--list-item-index]="i">
                <fstr-link
                  (click)="closeOverlay.emit()"
                  cssClasses="button button-link"
                  [routerLinkActiveOptions]="{ exact: true }"
                  [routerLink]="page.url"
                  [routerLinkActive]="'is--current'"
                  [button]="{ url: page.url || '', routerLink: page.routerLink, text: page.title }"
                ></fstr-link>
              </li>
            </ul>
          </div>
        </div>

        <div
          class="topbar__nav__metrics flex-gap-3 justify-content-stretch justify-content-md-between"
          *ngIf="statsLoaded$() && usr && edition.countryAverage && editionStarted$()"
        >
          <app-donut-chart
            [routerLink]="['/statistieken']"
            *ngIf="getDataUser$()"
            [title]="usr.firstName"
            [subtitle]="'statistiek-donut-suffix' | fromDictionary"
            [colors]="['statistiek-kleur-gebruiker' | fromDictionary]"
            [data]="getDataUser$()"
            [sizeDonut]="[100, 105]"
            [total]="edition.countryAverage"
          ></app-donut-chart>
          <app-donut-chart
            [routerLink]="['/statistieken']"
            *ngIf="statsPlatform$() as data"
            [title]="'platform-name' | fromDictionary"
            [subtitle]="'statistiek-donut-suffix-m' | fromDictionary"
            [colors]="['statistiek-kleur-platform' | fromDictionary]"
            [data]="data"
            [sizeDonut]="[100, 105]"
            [total]="edition.countryAverage"
          ></app-donut-chart>
        </div>
      </ng-container>

      <h1 class="topbar__logo mb-0" (click)="closeOverlay.emit()" [routerLink]="['/']">
        <strong class="sr-only">{{ 'meta-titel' | fromDictionary }}</strong>
        <fstr-icon anchor="logo-100-100-100"></fstr-icon>
      </h1>

      <button
        class="topbar__nav-toggle hamburger order-5 me-3 ms-auto d-flex d-md-none"
        [class.is--active]="menuActive"
        (click)="toggleOverlay.emit()"
      >
        <span class="hamburger__lines"></span>
      </button>
    </div>
  </nav>

  <!-- <nav class="topbar__bottom" *ngIf="isSignedIn">
    <div class="container">
      <ul class="topbar__nav-list main--nav">
        <li class="topbar__nav-list__item" *ngFor="let page of menu; let i = index" [style.--list-item-index]="i">
          <fstr-link
            (click)="closeOverlay.emit()"
            cssClasses="button button-link"
            [routerLinkActiveOptions]="{ exact: true }"
            [routerLink]="page.url"
            [routerLinkActive]="'is--current'"
            [button]="{ url: page.url || '', routerLink: page.routerLink, text: page.title }"
          ></fstr-link>
        </li>
      </ul>
    </div>
  </nav> -->
</div>
