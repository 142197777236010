<article class="donut-chart justify-content-center d-flex flex-gap-1 align-items-center">
  <figure class="donut-chart__figure">
    <apx-chart
      *ngIf="chartOptions.chart && chartOptions.series && chartOptions.fill"
      [series]="chartOptions.series"
      [chart]="chartOptions.chart"
      [legend]="{ show: false }"
      [dataLabels]="{ enabled: false }"
      [fill]="chartOptions.fill"
      [plotOptions]="{
      pie: {
        expandOnClick: false,
      }
    }"
      [tooltip]="{ enabled: false }"
    ></apx-chart>
    <figcaption class="donut-chart__figure__caption" [ngStyle]="{ color: colors[0] }">
      <strong class="fs-body-sm">{{ average.toFixed(1) }}</strong>
      <span class="fs-body-xxs">kg</span>
    </figcaption>
  </figure>
  <main>
    <h2 class="fs-body-md mb-0" *ngIf="title" [ngStyle]="{ color: colors[0] }">{{ title }}</h2>
    <p class="fs-body-sm mb-0" *ngIf="subtitle">{{ subtitle }}</p>
  </main>
</article>
