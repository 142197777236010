<section class="section-outset-y">
  <header class="text-center text-max-width mx-auto container pb-4">
    <h2 class="fs-display-2 text-primary mb-2">{{ block.title }}</h2>
    <p class="h5 text-secondary fw-bold">{{ block.subtitle }}</p>
  </header>

  <div class="py-4 pt-md-5 mt-2 bg-color-yellow-200">
    <ul class="wall-posts-grid container">
      <li class="mb-4 wall-posts-grid__item" *ngFor="let post of block.posts">
        <app-post-card [post]="post" [showComments]="false"></app-post-card>
      </li>
    </ul>
  </div>
</section>
