<section class="statistic-form card bg-tertiary text-light pb-4">
  <fstr-dynamic-form [config]="formFieldsData" (formSubmit)="formValue.emit($event)" [appearance]="'fill'" [showSubmit]="false" #form>
    <div slot="form-actions" class="mt-3">
      <button type="submit" class="button button--primary w-100" [disabled]="!form.form.valid">
        <span>{{ 'statistiek-formulier-versturen-label' | fromDictionary }}</span>
        <fstr-icon anchor="icon-arrow-right"></fstr-icon>
      </button>
    </div>
  </fstr-dynamic-form>
  <!-- <app-alert class="d-block mt-4" [status]="'danger'" [message]="'Error tekst'"></app-alert> -->
</section>
