<section class="content-block-list section-outset-y">
  <div class="container">
    <div class="row">
      <header class="col-md-5 content-block-list__header">
        <h2 class="h2 content-block-list__title title-squiggle text-primary">
          {{ block.title }}
          <fstr-icon anchor="icon-squiggle"></fstr-icon>
        </h2>
      </header>
      <main class="col-md-6">
        <ol *ngIf="block.listItems as list" class="block-list">
          <li class="block-list__item" *ngFor="let item of list; let i = index">
            <app-content-block-list-item [index]="i" [listItem]="item"></app-content-block-list-item>
          </li>
        </ol>
      </main>
    </div>
  </div>
</section>
