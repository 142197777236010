<article class="block-default section-inset-y p-md-5 container">
  <div class="row">
    <div class="block-default__content col-md-6" [ngClass]="{ 'justify-center text-center': !block.image }">
      <div class="text-max-width-xs">
        <h1 class="text-primary h2 mb-2 title-squiggle" *ngIf="block.title">
          <fstr-icon anchor="icon-squiggle"></fstr-icon>
          <span>{{ block.title }}</span>
        </h1>
        <span class="text-primary" *ngIf="block.subtitle">{{ block.subtitle }}</span>
        <div class="markdown-content" *ngIf="block.text" [innerHTML]="block.text"></div>
        <fstr-link *ngIf="block.button && block.button?.url" [cssClasses]="'button button--md button--tertiary'" [button]="block.button">
          <fstr-icon anchor="icon-arrow-right"></fstr-icon>
        </fstr-link>
      </div>
    </div>
    <figure class="block-default__figure col-md-6">
      <fstr-image *ngIf="block.image" [image]="block.image" [imageSizes]="[600, 900]"></fstr-image>
    </figure>
  </div>
</article>
