import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromAssignments from '../reducers/assignments.reducer';
import { Assignment } from '../../models';
import * as fromRoot from '../../../store';

import { isAfter, isBefore } from 'date-fns';

export const getAssignmentState = createSelector(fromFeature.getLandingState, (state: fromFeature.AssignmentState) => state.assignments);

export const getAssignmentEntities = createSelector(getAssignmentState, fromAssignments.getAssignmentsEntities);

export const getAllAssignments = createSelector(getAssignmentEntities, entities => {
  return Object.keys(entities).map(slug => entities[slug]);
});

export const getOrderedAssignments = createSelector(getAllAssignments, assignments => {
  return assignments.sort((a, b) => (new Date(b.startDate) >= new Date(a.startDate) ? 1 : -1));
});

export const getAssignmentsLoaded = createSelector(getAssignmentState, fromAssignments.getAssignmentsLoaded);
export const getAssignmentsLoading = createSelector(getAssignmentState, fromAssignments.getAssignmentsLoading);

export const GetCurrentAssignmentLoaded = createSelector(getAssignmentState, fromAssignments.getCurrentAssignmentLoaded);
export const GetCurrentAssignmentId = createSelector(getAssignmentState, fromAssignments.getCurrentAssignmentLoaded);

export const getAssignmentsOverviewLoaded = createSelector(getAssignmentState, fromAssignments.getAssignmentsOverviewLoaded);
export const getAssignmentsOverviewLoading = createSelector(getAssignmentState, fromAssignments.getAssignmentsOverviewLoading);

export const getSelectedAssignment = createSelector(
  getAssignmentEntities,
  fromRoot.getRouterState,
  GetCurrentAssignmentId,
  (entities, router, current) => {
    const id = router.state.params?.['id'] || router.state.data?.['id'] || current;

    if (id) {
      return entities[id];
    }

    return Object.keys(entities)
      .map(id => entities[id])
      .find(a => isAfter(new Date(), new Date(a.startDate)) && isBefore(new Date(), new Date(a.endDate)));
  }
);

export const getActiveAssignment = createSelector(getOrderedAssignments, assignments => {
  if (!assignments) {
    return;
  }
  const geActiveAssignment = assignments.find(i => {
    return isAfter(new Date(), new Date(i!.startDate)) && isBefore(new Date(), new Date(i!.endDate));
  });

  return geActiveAssignment;
});

export const getSelectedAssignmentLoading = createSelector(getAssignmentsLoading, getSelectedAssignment, (ids, assignment): boolean => {
  return assignment != null && ids.includes(assignment.id);
});

export const getSelectedAssignmentLoaded = createSelector(getAssignmentsLoaded, getSelectedAssignment, (ids, assignment): boolean => {
  return assignment != null && ids.includes(assignment.id);
});
