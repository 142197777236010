<article class="section-outset-y bg-subtle section-inset-y px-md-5 content-block-participants">
  <div class="container">
    <div class="row">
      <header class="col-md-5 text-tertiary">
        <h2 class="h2 content-block-participants__title title-squiggle">
          <fstr-icon anchor="icon-squiggle"></fstr-icon>
          <ng-container *ngIf="block.deelnemers">
            <span *ngIf="block.deelnemers > 1">
              {{ block.title | setValueDict : [{ key: 'aantal', value: block.deelnemers ? block.deelnemers.toString() : '' }] }}
            </span>
            <span *ngIf="block.deelnemers < 2">{{ 'content-block-deelnemers-aanmelden-titel' | fromDictionary }}</span>
          </ng-container>
          <span *ngIf="!block.deelnemers">{{ 'content-block-deelnemers-aanmelden-titel' | fromDictionary }}</span>
        </h2>
        <div class="markdown-content text-dark mb-3" [innerHTML]="block.text"></div>
        <fstr-link [cssClasses]="'button button--md button--tertiary'" [button]="block.button" *ngIf="block.button">
          <fstr-icon anchor="icon-arrow-right"></fstr-icon>
        </fstr-link>
      </header>
      <main class="col-md-6 offset-md-1 px-4 content-block-participants__images">
        <ul class="content-block-participants__list" *ngIf="block.images">
          <li *ngFor="let image of block.images | slice : 0 : 3; let i = index" class="content-block-participants__list__item">
            <fstr-image [imageSizes]="[400, 800, 1200]" [image]="image" *ngIf="image.url" class="d-block"></fstr-image>
          </li>
        </ul>
      </main>
    </div>
  </div>
</article>
