import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RegisterComponent } from './containers/register/register.component';
import { ActivateAccountComponent } from './containers/activate-account/activate-account.component';
import { SignInComponent } from './containers/sign-in/sign-in.component';
import { ForgotPasswordComponent } from './containers/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './containers/reset-password/reset-password.component';

import { AuthGuard } from './guards/auth.guard';
import { ProfileComponent } from './containers/profile/profile.component';

const signedInGuards = [AuthGuard];

const routes: Routes = [
  {
    path: 'inloggen',
    component: SignInComponent,
  },
  {
    path: 'registreren',
    component: RegisterComponent,
  },
  {
    path: 'mijn-account',
    component: ProfileComponent,
    canActivate: [...signedInGuards],
  },
  {
    path: 'wachtwoord-vergeten',
    component: ForgotPasswordComponent,
  },
  {
    path: 'bevestig-account',
    component: ActivateAccountComponent,
  },
  {
    path: 'wachtwoord-resetten',
    component: ResetPasswordComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AtuhRoutingModule {}
