import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StatisticsRoutingModule } from './statistics-routing.module';
import { StatisticOverviewComponent } from './containers/statistic-overview/statistic-overview.component';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import * as fromStore from './store';
import { TextUtilityModule } from '@teamfoster/sdk/text-utility';
import { CoreModule } from '../core/core.module';
import { PageHeaderComponent } from '../standalone/page-header/page-header.component';
import { ImageModule } from '@teamfoster/sdk/image';
import { ButtonModule } from '@teamfoster/sdk/button';
import { IconModule } from '@teamfoster/sdk/icon';
import { YoutubePlayerModule } from '@teamfoster/sdk/youtube-player';
import { ContentBlocksModule } from '../content-blocks/content-blocks.module';
import { UserAvatarComponent } from '../standalone/user-avatar/user-avatar.component';
import { ConfirmComponent } from '../standalone/confirm/confirm.component';
import { MatDialogModule } from '@angular/material/dialog';
import { InViewModule } from '@teamfoster/sdk/in-view';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { StatisticFormComponent } from './components/statistic-form/statistic-form.component';
import { DynamicFormModule } from '@teamfoster/sdk/dynamic-form';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgApexchartsModule } from 'ng-apexcharts';
import { DonutChartComponent } from '../standalone/donut-chart/donut-chart.component';

@NgModule({
  declarations: [StatisticOverviewComponent, StatisticFormComponent],
  imports: [
    CommonModule,
    ImageModule,
    ButtonModule,
    StatisticsRoutingModule,
    TextUtilityModule,
    PageHeaderComponent,
    CoreModule,
    IconModule,
    YoutubePlayerModule,
    ContentBlocksModule,
    ConfirmComponent,
    UserAvatarComponent,
    MatDialogModule,
    InViewModule,
    DictionaryNgrxModule,
    DynamicFormModule,
    MatDatepickerModule,
    NgApexchartsModule,
    DonutChartComponent,
    StoreModule.forFeature(fromStore.featureKey, fromStore.reducers, {
      metaReducers: fromStore.metaReducers,
    }),
    EffectsModule.forFeature(fromStore.effects),
  ],
})
export class StatisticsModule {}
