import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthLayoutComponent implements OnInit {
  @Input() icon = '';
  @Input() title = 'Ja, ik doe mee!';

  constructor() {}

  ngOnInit(): void {}
}
