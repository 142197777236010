import { createReducer, on } from '@ngrx/store';
import { Contact } from '../../models';
import * as actions from '../actions/contacts.action';
import { ToEntities } from '@teamfoster/sdk/array-utility';

export interface ContactsState {
  entities: { [slug: string]: Contact };
  loaded: boolean;
  loading: boolean;
  error?: any;
}

export const initialState: ContactsState = {
  entities: {},
  loaded: false,
  loading: false,
};

export const ContactReducer = createReducer(
  initialState,
  on(actions.LoadContacts, (state: ContactsState) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(actions.LoadContactsSuccess, (state: ContactsState, { contacts }) => {
    const entities = ToEntities(contacts, 'id', state.entities);
    return {
      ...state,
      entities,
      loading: false,
      loaded: true,
    };
  }),
  on(actions.LoadContactsFail, (state: ContactsState, { error }) => {
    return {
      ...state,
      loading: false,
      loaded: false,
      error,
    };
  })
);

export const getContactsEntities = (state: ContactsState) => state.entities;
export const getContactsLoading = (state: ContactsState) => state.loading;
export const getContactsLoaded = (state: ContactsState) => state.loaded;
