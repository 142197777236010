import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JsonLdComponent } from './components/json-ld/json-ld.component';

const moduleExports = [
  JsonLdComponent
];


@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [moduleExports],
  exports: [moduleExports],
})
export class SeoModule { }
