import { createAction, props } from '@ngrx/store';
import { AssignmentPost, CreateAssignmentPostData } from '../../models';
import { PagedOverview } from 'src/app/core/models/paged-overview.model';
import { PageData } from 'src/app/core/models/page-data.model';

export const LoadPostsById = createAction('[Posts] Load Posts by Assignment Id', props<{ id: number }>());
export const LoadPostsByIdSuccess = createAction(
  '[Posts]  Load Posts by Assignment Id Success',
  props<{ posts: PagedOverview<AssignmentPost> }>()
);
export const LoadMorePosts = createAction('[Posts] Load MORE Posts', props<{ id: number; page: PageData }>());
export const LoadPostsByIdFail = createAction('[Posts] Load Posts by Assignment Id Fail', props<{ error: any; id: number }>());

export const CreatePost = createAction('[Create Post] Create Post', props<{ post: CreateAssignmentPostData }>());
export const CreatePostSuccess = createAction('[Post] Create Post Success', props<{ post: AssignmentPost }>());
export const CreatePostFail = createAction('[Post] Create Post Fail', props<{ error: any }>());

export const DeletePost = createAction('[Posts] Delete Post', props<{ id: number }>());
export const DeletePostSuccess = createAction('[Posts] Delete Post Success', props<{ post: AssignmentPost }>());
export const DeletePostFail = createAction('[Posts] Delete Post Fail', props<{ error: any }>());

export const ClearPosts = createAction('[Posts] Clear Assignments');
