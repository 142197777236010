import { ChangeDetectionStrategy, Component, HostListener, Input, PLATFORM_ID, computed, inject, signal } from '@angular/core';

import { DOCUMENT } from '@angular/common';
import { trigger } from '@angular/animations';
import { CardGridAnimation } from 'src/app/animations';
import { Quote } from '../../models/quote.model';
import { ContentBlock } from '../../models';

@Component({
  selector: 'app-content-block-quotes',
  templateUrl: './content-block-quotes.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockQuotesComponent {
  private platformId = inject(PLATFORM_ID);
  private document: any = inject(DOCUMENT);

  @Input() block!: ContentBlock;
  @Input() title!: string;
  @Input() intro!: string;

  activeBp$ = signal<string>(this.getBreakpoint());
  inView$ = signal(false);

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    const value = this.getBreakpoint();

    this.activeBp$.set(value);
  }

  evenposts$ = computed(() => {
    //const posts = this.quotes;
    const bp = this.activeBp$();

    if (!this.block.quotes) {
      return [];
    }

    // only for desktop uses

    switch (bp) {
      case 'sm':
        //case 'md':
        return this.block.quotes;

      default:
        return this.filterPosts(this.block.quotes, true);
    }
  });

  // Masonry Effect
  unevenposts$ = computed(() => {
    const bp = this.activeBp$();
    if (!this.block.quotes) {
      return [];
    }

    switch (bp) {
      case 'sm':
        //case 'md':
        return [];

      default:
        return this.filterPosts(this.block.quotes, false);
    }
  });

  filterPosts(posts: Quote[], isEven: boolean) {
    if (!posts) {
      return [];
    }
    return posts.filter((num, index) => (isEven ? index % 2 === 0 : index % 2 !== 0));
  }

  getBreakpoint() {
    return window
      .getComputedStyle(this.document.querySelector('body'), 'after')
      .getPropertyValue('content')
      .replace(/^["']|["']$/g, '');
  }
}
