import * as fromAssignments from './assignments.reducer';
import * as fromPosts from './posts.reducer';
import * as fromComments from './comments.reducer';

import { ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { environment } from '../../../../environments/environment';

export const featureKey = 'assignments';

export interface AssignmentState {
  assignments: fromAssignments.AssignmentsState;
  posts: fromPosts.PostsState;
  comments: fromComments.CommentsState;
}

export const reducers: ActionReducerMap<AssignmentState> = {
  assignments: fromAssignments.AssignmentsReducer,
  posts: fromPosts.PostsReducer,
  comments: fromComments.CommentsReducer,
};

export const getLandingState = createFeatureSelector<AssignmentState>(featureKey);

export const metaReducers: MetaReducer<AssignmentState>[] = !environment.production ? [] : [];
