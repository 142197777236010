<app-auth-layout [title]="prefix + '-titel' | fromDictionary">
  <a [routerLink]="['/account/inloggen']" class="button button-link button--tertiary buttons--xs" header>
    <fstr-icon anchor="icon-arrow-left"></fstr-icon>
    Terug naar inloggen
  </a>
  <ng-container form>
    <fstr-dynamic-form
      class="register-form"
      *ngIf="registerSuccess$() === false"
      [config]="formFields"
      [fieldsets]="fieldsets"
      [appearance]="'fill'"
      (formSubmit)="handleSubmit($event)"
      [showSubmit]="false"
      #formEl
    >
      <div slot="form-actions">
        <button type="submit" class="button button--md button--primary">
          {{ prefix + '-knoptekst' | fromDictionary }}
        </button>
      </div>
    </fstr-dynamic-form>

    <app-alert
      class="d-block mt-4"
      *ngIf="registerError$()"
      [status]="'danger'"
      [title]="prefix + 'formulier-error-titel' | fromDictionary"
    >
      <ng-container>
        <ul *ngIf="registerError$()?.error?.errors?.length > 1">
          <li class="" *ngFor="let error of registerError$()?.error?.errors">
            {{ error.description }}
          </li>
        </ul>
        <div *ngIf="registerError$()?.error?.errors?.length < 2">
          <p class="mb-0" *ngFor="let error of registerError$()?.error?.errors">
            {{ error.description }}
          </p>
        </div>

        <div *ngIf="!registerError$()?.error?.errors">
          Excuus! Er is een onbekende fout opgetreden.. Neem contact met ons op als dit probleem zich blijft voordoen.
        </div>

        <!--<pre>{{ registerError$() | json }}</pre>-->
      </ng-container>
    </app-alert>

    <article class="card d-block bg-success text-white reset-card-spacing" *ngIf="registerSuccess$()">
      <h2 class="h4 font-secondary fw-bold mb-2">{{ prefix + '-formulier-succes-geslaagd-titel' | fromDictionary }}</h2>
      <p>{{ prefix + '-formulier-succes-geslaagd-tekst-tmp' | fromDictionary }}</p>
      <button type="button" [routerLink]="['/']" class="button mt-3 button--light button--md text-success">
        {{ prefix + '-terug-naar-home-knoptekst' | fromDictionary }}
      </button>
    </article>
  </ng-container>
</app-auth-layout>
