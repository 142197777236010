<section class="content-block-quotes section-outset-y">
  <div class="container container-size-sm">
    <header class="text-center">
      <h1 class="text-tertiary fs-display-2 mb-3">{{ block.title }}</h1>
      <p class="h5 mb-0 text-primary text-max-width mx-auto" *ngIf="block.subtitle">{{ block.subtitle }}</p>
    </header>
    <main *ngIf="block.quotes" class="content-block__main pt-3">
      <div class="row">
        <ul class="col-md-6 content-block-quotes__list is--even" *ngIf="evenposts$() as even">
          <li class="mt-4 pt-1 pt-md-0 mt-md-5 content-block-quotes__list__item" *ngFor="let post of even">
            <app-quote-card [quote]="post"></app-quote-card>
          </li>
        </ul>

        <ul class="col-md-6 content-block-quotes__list is--uneven" *ngIf="unevenposts$() as uneven" [class.pt-md-8]="uneven.length">
          <li class="mt-4 pt-1 pt-md-0 mt-md-5 content-block-quotes__list__item" *ngFor="let post of unevenposts$()">
            <app-quote-card [quote]="post"></app-quote-card>
          </li>
        </ul>
      </div>
    </main>
  </div>
</section>
