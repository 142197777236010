<footer class="footer">
  <section class="footer__contact text-primary section-inset-y">
    <div class="container">
      <div class="row">
        <header class="col-md-6 col-lg-5 footer__contact__header">
          <h2 class="h2">{{ 'footer-contact-titel' | fromDictionary }}</h2>
        </header>
        <main class="col-md-6 offset-lg-1">
          <ul class="footer__contact-list d-flex">
            <li class="footer__contact-list__item" *ngFor="let contact of contacts">
              <article class="footer__contact-card" [ngClass]="contacts.length === 1 ? 'd-flex' : 'd-md-block'">
                <app-avatar class="d-inline-block mb-3" [name]="contact.name" [imageUrl]="contact.image.url"></app-avatar>

                <main class="footer__contact-card__main" [class.mt-md-4]="contacts.length >= 2">
                  <h2 class="footer__contact-card__function">{{ contact.role }}</h2>
                  <ul>
                    <li class="mb-0">{{ contact.name }}</li>
                    <li class="mb-0">
                      <a class="text-primary link-unset" [href]="'mailto:' + contact.email">{{ contact.email }}</a>
                    </li>
                    <li>
                      <a class="text-primary link-unset" [href]="'tel:' + contact.phone">{{ contact.phone }}</a>
                    </li>
                  </ul>
                </main>
              </article>
            </li>
          </ul>
        </main>
      </div>
    </div>
  </section>

  <nav class="footer__end text-primary py-5">
    <div class="container">
      <div class="row align-items-center">
        <header class="col-md-4 footer__end__header">
          <h2 class="h4">
            {{ 'meta-titel-suffix' | fromDictionary }}
          </h2>
          <div>{{ 'footer-concept-tekst' | fromDictionary }}</div>
        </header>
        <main class="col-md-8 d-flex">
          <ul class="footer__nav-list d-flex me-md-0 ms-md-auto">
            <li class="footer__nav__list__item" *ngFor="let item of menu">
              <fstr-link
                [cssClasses]="'button button-link button--primary'"
                [button]="{ text: item.title, url: item.url ? item.url : '', routerLink: item.routerLink }"
              ></fstr-link>
            </li>
            <li class="footer__nav-list__item">
              <button (click)="openCookie()" class="button button-link button--primary">{{ 'cookie-settings' | fromDictionary }}</button>
            </li>
          </ul>
        </main>
      </div>
      <!-- <pre>{{ menu | json }}</pre> -->
    </div>
  </nav>
</footer>
