import * as fromRouter from '@ngrx/router-store';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Params, Data } from '@angular/router';
import { ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import * as fromMeta from '../reducers/meta.reducer';
import * as fromNav from '../reducers/nav.reducer';
import * as fromTheme from '../reducers/theme.reducer';
import * as fromEditionData from '../reducers/edition-data.reducer';

export interface State {
  router: fromRouter.RouterReducerState<RouterStateUrl>;
  meta: fromMeta.MetaState;
  nav: fromNav.NavState;
  theme: fromTheme.ThemeState;
  editionData: fromEditionData.EditionDataState;
}

export interface RouterStateUrl {
  url: string;
  queryParams: Params;
  params: Params;
  data: Data;
  fragment: string | null;
}

export const reducers: ActionReducerMap<State> = {
  router: fromRouter.routerReducer,
  meta: fromMeta.metaDataReducer,
  nav: fromNav.NavReducer,
  theme: fromTheme.ThemeReducer,
  editionData: fromEditionData.editionDataReducer,
};

export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];

export const getRouterState = createFeatureSelector<fromRouter.RouterReducerState<RouterStateUrl>>('router');
export const getNavState = createFeatureSelector<fromNav.NavState>('nav');
export const getThemeState = createFeatureSelector<fromTheme.ThemeState>('theme');
export const getEditionState = createFeatureSelector<fromEditionData.EditionDataState>('editionData');

export class CustomSerializer implements fromRouter.RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    // console.log(routerState.root);

    const { url } = routerState;
    const { queryParams } = routerState.root;
    const fragment = routerState.root.fragment || null;

    let state: ActivatedRouteSnapshot = routerState.root;
    while (state.firstChild) {
      state = state.firstChild;
    }

    const { params } = state;
    const { data } = state;

    return {
      url,
      fragment,
      queryParams,
      params,
      data,
    };
  }
}
