import { Component, PLATFORM_ID, ViewChild, effect, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Change, getChangeError, getChangeSuccess, getChange, getAuthUser } from '../../store';
import { DynamicFormField } from '@teamfoster/sdk/dynamic-form';
import { FromDictionaryPipe, LanguageService } from '@teamfoster/sdk/dictionary-ngrx';
import { Validators } from '@angular/forms';
import { isPlatformBrowser } from '@angular/common';
import { SetTags, SetTitle, getRouterState } from 'src/app/store';
import { asapScheduler } from 'rxjs';
import { StringUtility } from '@teamfoster/sdk/text-utility';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
})
export class ProfileComponent {
  store = inject(Store);
  lang = inject(LanguageService);
  private dict = new FromDictionaryPipe(this.lang);
  private platformId = inject(PLATFORM_ID);
  @ViewChild('formEl') formEl: any;

  prefix = 'profiel-wijzigen';

  usr$ = this.store.selectSignal(getAuthUser);

  change$ = this.store.selectSignal(getChange);
  changeSuccess$ = this.store.selectSignal(getChangeSuccess);
  changeError$ = this.store.selectSignal(getChangeError);

  fieldsets: { name: string; title?: string; collapsed?: boolean }[] = [
    { name: 'profile', title: 'Profiel', collapsed: false },
    { name: 'other', title: 'Overige informatie', collapsed: false },
    { name: 'password', title: 'Wachtwoord wijzigen', collapsed: false },
  ];

  metafields = effect(() => {
    const usr = this.usr$();

    asapScheduler.schedule(() =>
      this.store.dispatch(
        SetTags({
          properties: {
            'description': StringUtility.Truncate(StringUtility.StripHTML(this.dict.transform('profiel-wijzigen-titel')), 157, '...'),
            'og:title': `${this.dict.transform('profiel-wijzigen-titel')} ${this.dict.transform('meta-titel-suffix')}`,
            'og:description': StringUtility.Truncate(StringUtility.StripHTML(this.dict.transform('profiel-wijzigen-titel')), 157, '...'),
          },
        })
      )
    );

    asapScheduler.schedule(() => {
      this.store.dispatch(
        SetTitle({ title: `${this.dict.transform('profiel-wijzigen-titel')} - ${this.dict.transform('meta-titel-suffix')}` })
      );
    });
  });

  formFields: DynamicFormField[] = [
    {
      label: this.dict.transform(`registreren-profiel-foto-label`),
      name: 'avatar',
      validators: [],
      placeholder: this.dict.transform(`registreren-profiel-foto-placeholder`, false),
      fieldType: 'avatar',
      order: 1,
      fieldset: 'profile',
      accept: 'image/*',
      value: this.usr$()?.avatar || '',
    },

    {
      inputType: 'text',
      label: this.dict.transform(`registreren-voornaam-label`),
      name: 'firstName',
      validators: [Validators.required],
      autocomplete: 'given-name',
      //placeholder: this.dict.transform(`${this.prefix}-voornaam-placeholder`, false),
      fieldType: 'input',
      order: 1,
      cssClass: 'col-12 col-md-6',
      fieldset: 'profile',
      subscriptSizing: 'dynamic',
      value: this.usr$()?.firstName || '',
    },
    {
      inputType: 'text',
      label: this.dict.transform(`registreren-achternaam-label`),
      name: 'lastName',
      validators: [Validators.required],
      autocomplete: 'family-name',
      placeholder: this.dict.transform(`registreren-achternaam-placeholder`, false),
      fieldType: 'input',
      order: 1,
      cssClass: 'col-12 col-md-6',
      fieldset: 'profile',
      subscriptSizing: 'dynamic',
      value: this.usr$()?.lastName || '',
    },
    {
      inputType: 'text',
      label: this.dict.transform(`registreren-bio-label`),
      name: 'bio',
      validators: [],
      placeholder: this.dict.transform(`registreren-bio-placeholder`, false),
      fieldType: 'textarea',
      order: 2,
      fieldset: 'profile',
      subscriptSizing: 'dynamic',
      value: this.usr$()?.bio || '',
    },
    {
      inputType: 'number',
      label: this.dict.transform(`registreren-familie-grootte-label`),
      name: 'familySize',
      validators: [],
      placeholder: this.dict.transform(`registreren-familie-grootte-placeholder`, false),
      fieldType: 'input',
      order: 4,
      cssClass: 'col-12 col-md-12',
      fieldset: 'profile',
      subscriptSizing: 'dynamic',
      value: this.usr$()?.familySize || '',
    },
    {
      inputType: 'email',
      label: this.dict.transform(`registreren-email-label`),
      name: 'email',
      validators: [Validators.email, Validators.required],
      autocomplete: 'email',
      placeholder: this.dict.transform(`registreren-email-placeholder`, false),
      fieldType: 'input',
      order: 0,
      fieldset: 'other',
      subscriptSizing: 'dynamic',
      value: this.usr$()?.email || '',
      //disabled: true,
    },
    {
      label: 'Wijzig wachtwoord',
      name: 'divider',
      placeholder: '',
      fieldType: 'divider',
      order: 1,
      fieldset: 'password',
    },
    {
      inputType: 'password',
      label: this.dict.transform(`registreren-wachtwoord-label`),
      name: 'password',
      autocomplete: 'new-password',
      hint: this.dict.transform('registreren-wachtwoord-hint'),
      fieldType: 'input',
      placeholder: this.dict.transform(`registreren-wachtwoord-placeholder`, false),
      order: 1,
      cssClass: 'mb-3',
      fieldset: 'password',
      subscriptSizing: 'dynamic',
    },
    {
      inputType: 'password',
      label: this.dict.transform(`registreren-wachtwoord-bevestigen-label`),
      name: 'confirmPassword',
      autocomplete: 'new-password',
      placeholder: this.dict.transform(`registreren-wachtwoord-bevestigen-placeholder`, false),
      fieldType: 'input',
      order: 1,
      fieldset: 'password',
      subscriptSizing: 'dynamic',
    },
    {
      inputType: 'text',
      label: this.dict.transform(`registreren-straat-label`),
      name: 'addressStreet',
      validators: [],
      placeholder: this.dict.transform(`registreren-straat-placeholder`, false),
      fieldType: 'input',
      autocomplete: 'address-line1',
      order: 1,
      cssClass: 'col-12 col-md-12',
      fieldset: 'other',
      subscriptSizing: 'dynamic',
      value: this.usr$()?.addressStreet || '',
    },
    {
      inputType: 'text',
      label: this.dict.transform(`registreren-huisnummer-label`),
      name: 'addressNumber',
      validators: [],
      placeholder: this.dict.transform(`registreren-huisnummer-placeholder`, false),
      fieldType: 'input',
      order: 1,
      cssClass: 'col-12 col-md-6',
      fieldset: 'other',
      subscriptSizing: 'dynamic',
      value: this.usr$()?.addressNumber || '',
    },
    {
      inputType: 'text',
      label: this.dict.transform(`registreren-toevoeging-label`),
      name: 'addressAddition',
      validators: [],
      placeholder: this.dict.transform(`registreren-toevoeging-placeholder`, false),
      fieldType: 'input',
      order: 1,
      cssClass: 'col-12 col-md-6',
      fieldset: 'other',
      subscriptSizing: 'dynamic',
      value: this.usr$()?.addressAddition || '',
    },
    {
      inputType: 'text',
      label: this.dict.transform(`registreren-stad-label`),
      name: 'addressCity',
      validators: [],
      placeholder: this.dict.transform(`registreren-stad-placeholder`, false),
      fieldType: 'input',
      order: 1,
      fieldset: 'other',
      subscriptSizing: 'dynamic',
      cssClass: 'col-12 col-md-12',
      autocomplete: 'address-level2',
      value: this.usr$()?.addressCity || '',
    },
  ];

  routeState$ = this.store.selectSignal(getRouterState);

  changeSuccessEffect$ = effect(() => {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    if (this.changeSuccess$()) {
      window.scrollTo(0, 0);
    }
  });

  handleSubmit(changeData: any) {
    console.log(changeData);
    this.store.dispatch(Change({ changeData }));
  }
}
