import { ChangeDetectorRef, Component, Inject, OnInit, inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { FromDictionaryPipe, LanguageService } from '@teamfoster/sdk/dictionary-ngrx';
import { DynamicFormField } from '@teamfoster/sdk/dynamic-form';
import { SetTitle } from '../../../store';
import { AuthService } from '../../services';
import { RemoveErrors } from '../../store';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
})
export class ForgotPasswordComponent implements OnInit {
  auth = inject(AuthService);
  cd = inject(ChangeDetectorRef);
  store = inject(Store);
  lang = inject(LanguageService);

  prefix = 'wachtwoord-vergeten';
  loading = false;
  result?: { succeeded: boolean; error?: string };

  checkValidation = false;
  private dict = new FromDictionaryPipe(this.lang);

  formFields: DynamicFormField[] = [
    {
      inputType: 'email',
      label: this.dict.transform(`${this.prefix}-email-label`),
      name: 'email',
      validators: [Validators.email, Validators.required],
      autocomplete: 'email',
      placeholder: this.dict.transform(`${this.prefix}-email-placeholder`),
      fieldType: 'input',
      order: 0,
    },
  ];

  constructor() {}

  ngOnInit(): void {
    this.store.dispatch(RemoveErrors());
    this.store.dispatch(
      SetTitle({ title: `${this.dict.transform(this.prefix + '-titel')} - ${this.dict.transform('meta-titel-suffix')}` })
    );
  }

  async requestPasswordReset(val: any) {
    this.checkValidation = true;
    this.loading = true;

    this.result = await this.auth.forgotPassword(val.email).toPromise();
    this.loading = false;

    this.cd.detectChanges();
  }
}
