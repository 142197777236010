import { transition, animate, style, query, stagger, keyframes } from '@angular/animations';

export const CardGridAnimation = transition('* <=> *', [
  // Initially the all cards are not visible
  query(':enter', style({ opacity: 0, transform: 'translateY(100px)' }), { optional: true }),

  // Each card will appear sequentially with the delay of 300ms
  query(
    ':enter',
    stagger('35ms', [
      animate(
        '300ms cubic-bezier(.49,.44,.76,1.07)',
        keyframes([style({ opacity: 0, transform: 'translateY(100px)' }), style({ opacity: 1, transform: 'translateY(0)' })])
      ),
    ]),
    { optional: true }
  ),

  // Cards will disappear sequentially with the delay of 300ms
  //   query(
  //     ':leave',
  //     stagger('50ms', [animate('250ms cubic-bezier(.49,.44,.76,1.07)', keyframes([style({ opacity: 1 }), style({ opacity: 0 })]))]),
  //     { optional: true }
  //   ),
]);
