import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CreateStatisticPostData, Statistic } from '../models';

@Injectable({
  providedIn: 'root',
})
export class StatisticService {
  constructor(
    private http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string
  ) {}

  getStatistics() {
    return this.http.get<Statistic[]>(`${this.BASE_URL}api/statistic`);
  }

  CreateStatistic(data: CreateStatisticPostData) {
    const formData: FormData = new FormData();

    formData.append('value', data.value.toString());
    formData.append('observedOn', data.observedOn.toString());

    return this.http.post<Statistic>(`${this.BASE_URL}api/statistic/`, formData);
  }
}
