import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { InViewModule } from '@teamfoster/sdk/in-view';
import { TextUtilityModule } from '@teamfoster/sdk/text-utility';
import { Html5VideoPlayerModule } from '@teamfoster/sdk/html5-video-player';
import { IconModule } from '@teamfoster/sdk/icon';
import { GridOverlayComponent } from './components/grid-overlay/grid-overlay.component';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { ImageModule } from '@teamfoster/sdk/image';
import { SeoModule } from '../seo/seo.module';
import { AlertComponent } from './components/alert/alert.component';
import { ButtonModule } from '@teamfoster/sdk/button';
import { UserAvatarComponent } from '../standalone/user-avatar/user-avatar.component';
import { FooterComponent } from '../standalone/footer/footer.component';
import { TopbarComponent } from '../standalone/topbar/topbar.component';
import { PageHeaderComponent } from '../standalone/page-header/page-header.component';
import { HighlightPipe } from './pipes/highlight.pipe';

const moduleExports = [LoaderIndicatorComponent, GridOverlayComponent, AlertComponent, HighlightPipe];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TopbarComponent,
    FooterComponent,
    PageHeaderComponent,
    InViewModule,
    TextUtilityModule,
    Html5VideoPlayerModule,
    IconModule,
    DictionaryNgrxModule,
    ButtonModule,
    SeoModule,
    ImageModule,
    ButtonModule,
    UserAvatarComponent,
  ],
  declarations: [...moduleExports],
  exports: [...moduleExports],
  providers: [],
})
export class CoreModule {}
