import { trigger, transition, style, query as q, group, animate, AnimationMetadata } from '@angular/animations';

// tslint:disable-next-line: no-shadowed-variable
const query = (style: string, animate: AnimationMetadata | AnimationMetadata[], optional = { optional: true }) =>
  q(style, animate, optional);

export const routerFade = trigger('routerFade', [
  transition('* <=> *', [
    query(':enter, :leave', style({ position: 'fixed', minHeight: '100vh', width: '100vw', opacity: 1 })),
    // query(':enter, :leave', style({ position: 'relative' })),
    group([
      query(':enter', [style({ opacity: 0 }), animate('0.1s ease-out', style({ opacity: 1 }))]),
      query(':leave', [animate('0.1s ease-out', style({ opacity: 0 }))]),
    ]),
  ]),
]);
