<nav class="follow-buttons">
  <h1 class="follow-buttons__title">{{ title }}</h1>
  <ul class="follow-buttons__list">
    <li class="follow-buttons__list__item" *ngFor="let item of followButtons$ | async">
      <a [href]="item.url" target="_blank" class="button button-icon">
        <span class="sr-only">{{ item.title }}</span>
        <img [src]="item.imageUrl" [alt]="item.title" *ngIf="item.imageUrl" />
        <fstr-icon *ngIf="!item.imageUrl && item.icon" [anchor]="item.icon"></fstr-icon>
      </a>
    </li>
  </ul>
</nav>
