import { createSelector } from '@ngrx/store';
import { eachWeekOfInterval, format, addWeeks, getISOWeek } from 'date-fns';

import * as fromFeature from '../reducers';
import * as fromStatistics from '../reducers/statistics.reducer';
import { Statistic } from '../../models';

export const getStatisticState = createSelector(fromFeature.getLandingState, (state: fromFeature.StatisticState) => state.Statistics);

export const getStatisticEntities = createSelector(getStatisticState, fromStatistics.getStatisticsEntities);

export const getAllStatistics = createSelector(getStatisticEntities, entities => {
  return Object.keys(entities).map(slug => entities[slug]);
});

//check again
export const getOrderedStatistics = createSelector(getAllStatistics, Statistics => {
  return Statistics.sort((a, b) => (new Date(b.observedOn) >= new Date(a.observedOn) ? -1 : 1));
});

export const getWeeklyStatisticsPlatform = createSelector(getOrderedStatistics, statistics => {
  if (statistics.length) {
    return weeklyAverages(statistics);
  }
  return [
    {
      week: 0,
      startDate: format(Date.now(), 'yyyy-MM-dd'),
      endDate: format(Date.now(), 'yyyy-MM-dd'),
      averageValue: 0, // Rond het gemiddelde af op 2 decimalen
    },
  ];
});

export const getWeeklyStatisticsByUser = (userId: string) =>
  createSelector(getOrderedStatistics, statistics => {
    const data = statistics.filter(statistic => statistic.userId === userId);
    if (data.length) {
      return weeklyAverages(data);
    }

    return [
      {
        week: 0,
        startDate: format(Date.now(), 'yyyy-MM-dd'),
        endDate: format(Date.now(), 'yyyy-MM-dd'),
        averageValue: 0, // Rond het gemiddelde af op 2 decimalen
        totalValue: 0,
      },
    ];
  });

export const getStatisticsLoaded = createSelector(getStatisticState, fromStatistics.getStatisticsLoaded);
export const getStatisticsLoading = createSelector(getStatisticState, fromStatistics.getStatisticsLoading);

export const getStatisticsSaved = createSelector(getStatisticState, fromStatistics.getStatisticsSaved);
export const getStatisticsSaving = createSelector(getStatisticState, fromStatistics.getStatisticsSaving);

function weeklyAverages(statistics: Statistic[]) {
  const startDate = new Date(statistics[0].observedOn);
  const endDate = new Date(statistics[statistics.length - 1].observedOn);

  const weeks = eachWeekOfInterval({
    start: startDate,
    end: endDate,
  });

  const weeklyAverages = weeks.map((weekStartDate, index) => {
    const weekEndDate = addWeeks(weekStartDate, 1);
    const weekData = statistics.filter(item => {
      const itemDate = new Date(item.observedOn);
      return itemDate >= weekStartDate && itemDate < weekEndDate;
    });

    const totalValue = weekData.reduce((acc, item) => acc + item.value, 0);
    let averageValue = totalValue ? totalValue / weekData.length : 0;

    return {
      // week: index + 1,
      week: getISOWeek(weekStartDate),
      startDate: format(weekStartDate, 'yyyy-MM-dd'),
      endDate: format(weekEndDate, 'yyyy-MM-dd'),
      averageValue: averageValue.toFixed(1), // Rond het gemiddelde af op 2 decimalen
      totalValue: totalValue,
    };
  });

  return weeklyAverages;
}
