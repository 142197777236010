<section class="container container-size-xs section-outset-y">
  <header class="text-start text-md-center mb-4 mb-md-6">
    <h2 class="h2 text-primary mb-1">{{ block.title }}</h2>
    <p class="h5 text-tertiary fw-bold" *ngIf="block.subtitle">{{ block.subtitle }}</p>
  </header>

  <article class="row justify-content-center row-gap-5">
    <ul *ngFor="let menuItem of menu" class="col-md-4 mb-4 mb-md-0">
      <h6 class="text-tertiary">{{ menuItem.title }}</h6>
      <ul>
        <li *ngFor="let submenuItem of menuItem.menuItems">
          <fstr-link
            [cssClasses]="'button button-link button--primary'"
            [button]="{ text: submenuItem.title, url: submenuItem.url ? submenuItem.url : '', routerLink: submenuItem.routerLink }"
          ></fstr-link>
        </li>
      </ul>
    </ul>
  </article>
</section>
