import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ImageModule } from '@teamfoster/sdk/image';

import { formatDistance } from 'date-fns';
import { nl } from 'date-fns/locale';
import { AssignmentPost } from 'src/app/assignments/models';
import { Profile } from 'src/app/auth/models';
import { UserAvatarComponent } from '../user-avatar/user-avatar.component';
import { IconModule } from '@teamfoster/sdk/icon';
import { CommentsListComponent } from '../comments-list/comments-list.component';
import { TextUtilityModule } from '@teamfoster/sdk/text-utility';
@Component({
  selector: 'app-post-card',
  templateUrl: './post-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ImageModule, UserAvatarComponent, IconModule, CommentsListComponent, TextUtilityModule],
})
export class PostCardComponent {
  @Input() post!: AssignmentPost;
  @Input() usr!: Profile | undefined | null;
  @Input() showComments: boolean = true;
  @Output() remove = new EventEmitter<AssignmentPost>();

  get relativeDate() {
    const relDate = formatDistance(new Date(this.post.postedOn), new Date(), { addSuffix: true, locale: nl });
    return relDate;
  }

  get typePost() {
    switch (this.post.postType) {
      case 1:
        return { icon: 'icon-question', type: 'Vraag' };

      case 2:
        return { icon: 'icon-line-bulb', type: 'Tip' };

      case 3:
        return { icon: 'icon-calendar', type: 'Weekopdracht' };

      default:
        return { icon: 'icon-pen', type: 'Bericht' };
    }
  }
}
