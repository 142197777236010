import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ContentBlock } from '../../models';

@Component({
  selector: 'app-content-block-list-icon',
  templateUrl: './content-block-list-icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockListIconComponent {
  @Input() block!: ContentBlock;
  @Input() index!: number;
}
