import { createAction, props } from '@ngrx/store';
import { AssignmentPostComment, CreateAssignmentPostComment } from '../../models';
import { PagedOverview } from 'src/app/core/models/paged-overview.model';
import { PageData } from 'src/app/core/models/page-data.model';

export const LoadCommentsById = createAction('[Comments] Load Comments by Post Id', props<{ id: number }>());
export const LoadCommentsByIdSuccess = createAction(
  '[Comments]  Load Comments by Post Id Success',
  props<{ comments: PagedOverview<AssignmentPostComment>; id: number }>()
);
export const LoadCommentsByIdFail = createAction('[Comments] Load Comments by Post Id Fail', props<{ error: any; id: number }>());

export const LoadMoreComments = createAction('[Comments] Load MORE Comments', props<{ id: number; page: PageData }>());

export const CreateComment = createAction('[Create Comment] Create Comment', props<{ comment: CreateAssignmentPostComment }>());
export const CreateCommentSuccess = createAction('[Comment] Create Comment Success', props<{ comment: AssignmentPostComment }>());
export const CreateCommentFail = createAction('[Comment] Create Comment Fail', props<{ error: any }>());

export const DeleteComment = createAction('[Comment] Delete Comment', props<{ id: number }>());
export const DeleteCommentSuccess = createAction('[Comment] Delete Comment Success', props<{ comment: AssignmentPostComment }>());
export const DeleteCommentFail = createAction('[Comment] Delete PostComment Fail', props<{ error: any }>());

export const ClearComments = createAction('[Posts] Clear Comments');
