import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormFileInputComponent } from '../form-file-input/form-file-input.component';

@Component({
  selector: 'app-form-avatar-upload',
  templateUrl: './form-avatar-upload.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormAvatarUploadComponent extends FormFileInputComponent {
  get imageUrl() {
    return this.isPreview ? this.filePreview : this.config.value ? this.config.value + '?width=500' : undefined;
  }
}
