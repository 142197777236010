import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  computed,
  effect,
  inject,
  signal,
} from '@angular/core';
import { ContentBlock } from '../../models';
import { differenceInSeconds, formatDuration, intervalToDuration, isBefore, parseISO, setMonth, subMonths } from 'date-fns';
import { KeyValue, isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-content-block-countdown',
  templateUrl: './content-block-countdown.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockCountdownComponent implements AfterViewInit, OnDestroy {
  private platformId = inject(PLATFORM_ID);

  @Input() block!: ContentBlock;
  @Input() index!: number;

  intervalId: any;
  countdown$ = signal<any>({});
  countdownOrder = ['months', 'days', 'hours', 'minutes', 'seconds'];
  //startCampain$ = signal<boolean>(false);

  startCampain$ = computed(() => {
    const countdown = this.countdown$();
    const currentDate = new Date();

    if (this.block.date) {
      //const endDate = parseISO(this.block.date);
      const endDate = new Date('12-12-2024'); // Testdatum in de toekomst

      if (isBefore(endDate, currentDate)) {
        return true;
      }
    }

    if (+countdown.years < 1 && +countdown.months < 1 && +countdown.days < 1 && +countdown.minutes < 1 && +countdown.seconds < 1) {
      return true;
    }

    return false;
  });

  ngAfterViewInit(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    this.initCountdown();
  }

  initCountdown() {
    if (!this.block.date) {
      return;
    }

    if (!this.startCampain$()) {
      this.startCountdown(parseISO(this.block.date));
    }
    // }
  }

  startCountdown(endDate: any): void {
    this.intervalId = setInterval(() => {
      const currentDate = new Date();
      //const timeDiffInSeconds = differenceInSeconds(endDate, currentDate);
      // const duration = {
      //   days: Math.floor(timeDiffInSeconds / (24 * 60 * 60)),
      //   hours: Math.floor((timeDiffInSeconds % (24 * 60 * 60)) / (60 * 60)),
      //   minutes: Math.floor((timeDiffInSeconds % (60 * 60)) / 60),
      //   seconds: Math.floor(timeDiffInSeconds % 60),
      // };

      // this.countdown$.set({
      //   days: this.formatDate(duration.days),
      //   hours: this.formatDate(duration.hours),
      //   minutes: this.formatDate(duration.minutes),
      //   seconds: this.formatDate(duration.seconds),
      // });

      const duration = intervalToDuration({
        start: currentDate,
        end: endDate,
      });

      this.countdown$.set({
        years: duration.years,
        months: this.formatDate(duration.months),
        days: this.formatDate(duration.days),
        hours: this.formatDate(duration.hours),
        minutes: this.formatDate(duration.minutes),
        seconds: this.formatDate(duration.seconds),
      });
      this.handleFlips(duration);
    }, 1000); // Update elke seconde
  }

  formatDate(value: any): string {
    return value < 10 ? `0${value}` : value.toString();
  }

  stopCountdown() {
    clearInterval(this.intervalId);
  }

  handleFlips(duration: any): void {
    this.toggleFlipAnimation('seconds', true);
    this.toggleFlipAnimation('minutes', duration.seconds === 0);
    this.toggleFlipAnimation('hours', duration.minutes === 0 && duration.seconds === 0);
    this.toggleFlipAnimation('days', duration.hours === 0 && duration.minutes === 0 && duration.seconds === 0);
    this.toggleFlipAnimation('months', duration.days === 0 && duration.hours === 0 && duration.minutes === 0 && duration.seconds === 0);
    //this.toggleFlipAnimation('days', days === 0 && hours === 0 && minutes === 0 && seconds === 0);
  }

  toggleFlipAnimation(unit: string, shouldFlip: boolean): void {
    const digitElement = document.querySelector(`.counter__digit.${unit}`);

    if (digitElement && shouldFlip) {
      digitElement.classList.add('flipping');
      setTimeout(() => {
        digitElement.classList.remove('flipping');
      }, 500); // Remove the class after the animation duration
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalId);
  }
}
