import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  Inject,
  PLATFORM_ID,
  InjectionToken,
  ChangeDetectionStrategy,
  inject,
  effect,
  signal,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { NavigationStart, Router, RouterModule } from '@angular/router';
import { fromEvent } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import * as fromMenu from '@teamfoster/sdk/menu-ngrx';
import { ButtonModule } from '@teamfoster/sdk/button';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { IconModule } from '@teamfoster/sdk/icon';
import { UserAvatarComponent } from '../user-avatar/user-avatar.component';
import { toSignal } from '@angular/core/rxjs-interop';
import { TextUtilityModule } from '@teamfoster/sdk/text-utility';
import { editionData } from 'src/app/core/models';
import { Store } from '@ngrx/store';
import * as fromAuth from '../../auth/store';
import { Profile } from 'src/app/auth/models';
import { LoadStatistics, getStatisticsLoaded, getWeeklyStatisticsByUser, getWeeklyStatisticsPlatform } from 'src/app/statistics/store';
import { DonutChartComponent } from '../donut-chart/donut-chart.component';
import { getEditionStarted } from 'src/app/store';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  standalone: true,
  imports: [
    CommonModule,
    IconModule,
    UserAvatarComponent,
    DictionaryNgrxModule,
    ButtonModule,
    RouterModule,
    TextUtilityModule,
    DonutChartComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopbarComponent implements OnChanges {
  private router = inject(Router);
  private store = inject(Store);

  @Input() loading: boolean = false;
  @Input() menuActive: boolean = false;
  @Input() logoClass: string = '';
  @Input() menu: fromMenu.MenuItem[] = [];
  @Input() topMenu: fromMenu.MenuItem[] = [];
  @Input() isSignedIn: boolean = false;
  @Input() edition!: editionData;
  @Input() usr!: Profile | undefined | null;

  @Output() toggleOverlay = new EventEmitter<void>();
  @Output() closeOverlay = new EventEmitter<void>();
  @Output() openOverlay = new EventEmitter<void>();

  fixed$ = signal<boolean>(false);
  statsLoaded$ = this.store.selectSignal(getStatisticsLoaded);
  editionStarted$ = this.store.selectSignal(getEditionStarted);
  statsPlatform$: any = this.store.selectSignal(getWeeklyStatisticsPlatform);
  getDataUser$!: any;

  scrollEffect = effect(() => {
    fromEvent(window, 'scroll')
      .pipe(debounceTime(20))
      .subscribe(a => {
        if (window.scrollY > 104) {
          this.fixed$.set(true);
        } else {
          this.fixed$.set(false);
        }
      });
  });

  private routerEvents$ = toSignal(this.router.events.pipe(filter(e => e instanceof NavigationStart)));

  routerEffect$ = effect(() => {
    if (this.routerEvents$() instanceof NavigationStart && this.menuActive) {
      this.closeOverlay.emit();
    }
  });

  signOut() {
    this.store.dispatch(fromAuth.SignOut());
  }

  ngOnChanges(changes: SimpleChanges): void {
    // if(changes..isSignedIn)

    if (changes['isSignedIn'] && !this.statsLoaded$() && this.usr) {
      console.log('signedin');
      this.store.dispatch(LoadStatistics());

      const commentsForPostId = getWeeklyStatisticsByUser(this.usr.id);
      this.getDataUser$ = this.store.selectSignal(commentsForPostId);
    }
  }
}
