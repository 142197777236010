<ng-container *ngIf="group.get(config.name) as field">
  <div
    class="custom-field custom-field--has-label"
    [class.has--label]="!!config.label"
    [formGroup]="group"
    [ngClass]="{
      'custom-field--invalid': showError(field)
    }"
  >
    <!-- Titles -->
    <!--<label class="label">
      <span [innerHTML]="config.label"></span>
      <span *ngIf="isRequired(field)">*</span>
    </label>-->
    <div class="custom-field-subLabel" *ngIf="config.subLabel" [innerHTML]="config.subLabel"></div>

    <input type="text" [formControlName]="config.name" hidden />

    <!-- File field  -->
    <input type="file" (change)="onFileSelected($event)" #fileUpload hidden [accept]="config['accept'] ? config['accept'] : '*'" />

    <div
      class="file-upload custom-field__field file-upload--avatar"
      (drop)="onDrop($event)"
      (dragover)="onDragOver($event)"
      (dragleave)="onDragLeave($event)"
      [class.is--drag-over]="dragOver"
      [class.is--not-empty]="!!file"
      #fileUpload
    >
      <app-avatar class="d-block me-md-3" size="lg" [ifUploadObj]="true" [name]="" [imageUrl]="imageUrl"></app-avatar>

      <div class="file-upload__empty-state" *ngIf="!filePreview">
        <strong>
          <span class="d-inline-block w-100 mb-2">Kies profielfoto</span>
          <a class="button button--secondary button--md" (click)="fileUpload.click()">
            <fstr-icon anchor="icon-image"></fstr-icon>
            <span>Kies profielfoto</span>
          </a>
        </strong>
        <br />
        <span class="type-body-xs text-muted" *ngIf="config['acceptHint']">{{ config['acceptHint'] }}</span>
      </div>

      <div class="file-upload__file" *ngIf="filePreview">
        <div class="file-upload__file__meta">
          <span class="text-tertiary">{{ 'bestand-uploaden-geselecteerde-profielfoto-label' | fromDictionary }}</span>
          <br />

          <div class="d-flex">
            <strong class="text-truncate type-body-xs d-block file-name-preview-label">{{ fileName || filePreview }}</strong>
          </div>

          <div class="d-flex flex-wrap gap-2 mt-2 file-upload__file__actions">
            <a class="button button-link button--primary" (click)="fileUpload.click()">
              <fstr-icon anchor="icon-replace"></fstr-icon>
              <span>Vervangen</span>
            </a>
            <a class="button button-link button--primary" (click)="clearFile()">
              <fstr-icon anchor="icon-trash"></fstr-icon>
              <span>Verwijderen</span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="custom-field__error-wrapper">
      <div class="mat-error" *ngIf="showError(field)">
        {{ error(field) }}
      </div>
    </div>
  </div>
</ng-container>
