/***
 * Example:
 * user$ | async | requiredRole:'HR'
 * ***/
import { Pipe, PipeTransform } from '@angular/core';
import { Profile } from '../models';

@Pipe({
  name: 'requiredRole',
})
export class RequiredRolePipe implements PipeTransform {
  transform(user: Profile | null | undefined, roles: string[] = []): boolean {
    if (!user) {
      return false;
    }
    let show = false;
    const userRoles = user?.roles?.map(name => name.toUpperCase()) || [];
    const typeRoles = roles.map(name => name.toUpperCase());

    typeRoles.forEach(role => {
      const check = userRoles.includes(role);
      if (check) {
        show = true;
        return;
      }
    });

    return show;
  }
}
