import { inject } from '@angular/core';

import { Actions, ofType, createEffect } from '@ngrx/effects';
import * as metaActions from '../actions/meta.action';

import { tap } from 'rxjs/operators';
import { Meta, Title, MetaDefinition } from '@angular/platform-browser';

export const setTitle$ = createEffect(
  (actions$ = inject(Actions), metaTitle = inject(Title)) => {
    return actions$.pipe(
      ofType(metaActions.SetTitle),
      tap(({ title }) => {
        metaTitle.setTitle(title);
      })
    );
  },
  { functional: true, dispatch: false }
);

export const setMeta$ = createEffect(
  (actions$ = inject(Actions), meta = inject(Meta)) => {
    return actions$.pipe(
      ofType(metaActions.SetTags),
      tap(({ properties }) => {
        const keys = Object.keys(properties);
        const data: MetaDefinition[] = keys.map(a => {
          const property = a;
          const content = properties[a];

          if (property === 'description') {
            return { name: property, content } as MetaDefinition;
          }

          return { property, content };
        });
        meta.addTags(data);
      })
    );
  },
  { functional: true, dispatch: false }
);
