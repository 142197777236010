import { Component, PLATFORM_ID, Renderer2, inject, computed, effect, Injector } from '@angular/core';

import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { Router, NavigationEnd, RouterOutlet, NavigationStart } from '@angular/router';
import { Store } from '@ngrx/store';
import { getDictionaryLoaded } from '@teamfoster/sdk/dictionary-ngrx';

import * as navActions from './store/actions/nav.action';
import * as navSelectors from './store/selectors/nav.selector';
import { filter } from 'rxjs/operators';
import { routerFade } from './animations';
import { ChangeDetectionStrategy } from '@angular/core';
import { CookieConfigService, getCookiePreferences } from '@teamfoster/sdk/cookie-ngrx';
import { getEditionData, getEditionStarted, getRouterState } from './store';
import { getMenuLoaded } from '@teamfoster/sdk/menu-ngrx';
import { getAllContacts } from './contacts/store';
import { toSignal } from '@angular/core/rxjs-interop';
import { fromEvent } from 'rxjs';
import { getAuthSignedIn, getAuthUser } from './auth/store';
// import {  } from './statistics/store';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  animations: [routerFade],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  private injector = inject(Injector);
  private store = inject(Store);
  private router = inject(Router);
  private renderer = inject(Renderer2);
  private platformId = inject(PLATFORM_ID);
  private document: any = inject(DOCUMENT);
  private cookieConfig = inject(CookieConfigService);

  routeState$ = this.store.selectSignal(getRouterState);
  menuOpen$ = this.store.selectSignal(navSelectors.getMenuOpen);
  cookiePrefs$ = this.store.selectSignal(getCookiePreferences);
  dictionaryLoaded$ = this.store.selectSignal(getDictionaryLoaded);
  editionStarted$ = this.store.selectSignal(getEditionStarted);
  menuLoaded$ = this.store.selectSignal(getMenuLoaded);
  private routerEvents$ = toSignal(this.router.events.pipe(filter(e => [NavigationStart, NavigationEnd].some(b => e instanceof b))));
  loading$ = computed(() => !(this.routerEvents$() instanceof NavigationEnd));
  loaded$ = computed(() => this.dictionaryLoaded$() && this.menuLoaded$());
  edition$ = this.store.selectSignal(getEditionData);

  isSignedIn$ = this.store.selectSignal(getAuthSignedIn);
  usr$ = this.store.selectSignal(getAuthUser);
  contacts$ = this.store.selectSignal(getAllContacts);
  // loadStats$ = this.store.selectSignal(getStatisticsLoaded);

  domainName = this.document.location.hostname;
  testGridEnabled = false;
  routerAnimationState = '';

  menuOpenEffect$ = effect(() => this.updateRoot(this.menuOpen$()));
  routerEffect$ = effect(() => {
    const event = this.routerEvents$();
    const state = this.routeState$();

    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    if (
      event instanceof NavigationStart &&
      event.navigationTrigger === 'imperative' &&
      !state?.state?.queryParams &&
      !state?.state?.fragment
    ) {
      window.scrollTo(0, 0);
    }
    if (event instanceof NavigationEnd && this.cookiePrefs$()?.analytical) {
      if ((<any>window).gtag) {
        (<any>window).gtag('config', this.cookieConfig.analyticsCode, { page_path: event.urlAfterRedirects, anonymize_ip: true });
      }
    }

    this.document.querySelector('body').classList.add('set--in');
    this.document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
    this.listenToShortcuts();
  });

  getMenu$ = computed(() => {
    const signedIn = this.isSignedIn$();
    const started = this.editionStarted$();

    if (!signedIn) {
      return 'topbar-main-nav';
    }

    if (signedIn && started) {
      return 'topbar-menu-loggedIn';
    } else {
      return 'topbar-menu-loggedIn-notStarted';
    }
  });

  toggleMenu() {
    this.store.dispatch(navActions.ToggleMenu());
  }

  closeMenu() {
    this.store.dispatch(navActions.CloseMenu());
  }

  openMenu() {
    this.store.dispatch(navActions.OpenMenu());
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData;
  }

  listenToShortcuts() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    // Shortcuts
    const shortcutKeys = ['Escape'];
    fromEvent<KeyboardEvent>(document, 'keydown')
      .pipe(filter((event: KeyboardEvent) => shortcutKeys.includes(event.code)))
      .subscribe((a: any) => {
        if (a.code === 'Escape') {
          this.store.dispatch(navActions.CloseMenu());
        }
      });
  }

  private updateRoot(menuActive: boolean) {
    menuActive
      ? this.renderer.addClass(this.document.body, 'main-nav--active')
      : this.renderer.removeClass(this.document.body, 'main-nav--active');
  }

  // private authEffect$ = effect(() => {
  //   const auth = this.isSignedIn$();
  //   const statsLoaded = this.loadStats$();
  //   if (auth && !statsLoaded) {
  //     this.store.dispatch(LoadStatistics());
  //   }

  //   if (statsLoaded) {
  //     console.log(statsLoaded);
  //   }
  // });
}
