<article class="block-list-item" (fstrInViewport)="setInView($event)" [class.set--inview]="inview" [once]="false" #componentRef>
  <div class="block-list-item__count">
    <strong class="fs-4 block-list-item__count__text">{{ index + 1 }}</strong>
  </div>
  <main class="ms-3 ms-md-5 block-list-item__main">
    <h3 class="h6 text-primary">{{ listItem.title }}</h3>
    <div class="markdown-content" [innerHTML]="listItem.text"></div>

    <fstr-link [cssClasses]="'button button--tertiary button--sm mt-3'" [button]="listItem.button" *ngIf="listItem.button">
      <fstr-icon anchor="icon-arrow-right"></fstr-icon>
    </fstr-link>
  </main>
  <div class="block-list-item__dashed" [style.--list-height]="percentageInView + '%'"></div>
</article>
