import { Component, OnInit, PLATFORM_ID, inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, asapScheduler, of } from 'rxjs';
import { LoginData } from '../../models';
import { getAuthError, getAuthSignedIn, getAuthSigningIn, RemoveErrors, SignIn } from '../../store';
import { FromDictionaryPipe, LanguageService } from '@teamfoster/sdk/dictionary-ngrx';
import { SetTitle } from '../../../store';
import { DynamicFormField } from '@teamfoster/sdk/dynamic-form';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
})
export class SignInComponent implements OnInit {
  store = inject(Store);
  lang = inject(LanguageService);

  private dict = new FromDictionaryPipe(this.lang);

  signingIn$ = this.store.selectSignal(getAuthSigningIn);
  signedIn$ = this.store.selectSignal(getAuthSignedIn);
  error$ = this.store.selectSignal(getAuthError);

  prefix = 'inloggen';

  formFields: DynamicFormField[] = [
    {
      inputType: 'email',
      label: this.dict.transform(`${this.prefix}-formulier-veld-label-email`),
      name: 'email',
      validators: [Validators.email, Validators.required],
      autocomplete: 'email',
      placeholder: this.dict.transform(`${this.prefix}-formulier-veld-placeholder-email`),
      fieldType: 'input',
      order: 0,
    },
    {
      inputType: 'password',
      label: this.dict.transform(`${this.prefix}-formulier-veld-label-wachtwoord`),
      name: 'password',
      validators: [Validators.required],
      autocomplete: 'password',
      placeholder: this.dict.transform(`${this.prefix}-formulier-veld-placeholder-wachtwoord`),
      fieldType: 'input',
      order: 1,
    },
  ];

  ngOnInit(): void {
    this.store.dispatch(RemoveErrors());

    this.store.dispatch(
      SetTitle({ title: `${this.dict.transform(`${this.prefix}-titel`)} - ${this.dict.transform('meta-titel-suffix')}` })
    );
  }

  handleSubmit(e: any) {
    const loginData: LoginData = { email: e.email, password: e.password, isPersistent: true };

    asapScheduler.schedule(() => this.store.dispatch(SignIn({ loginData })));
  }

  getErrorMessage(error: { succeeded: boolean; isLockedOut: boolean; isNotAllowed: boolean; requiresTwoFactor: boolean }) {
    if (error?.isNotAllowed) {
      return `${this.prefix}-formulier-error-tekst-activatie`;
    }

    if (error?.isLockedOut) {
      return `${this.prefix}-formulier-error-tekst-disabled`;
    }

    return `${this.prefix}-formulier-error-default`;
  }
}
